import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import CustomerCareKeys from '../../../../locales/keys';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import moment from 'moment';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {subscriberProductExpirationRenewOptInOut} from '../../../customer/dashboard/dbssDashboard/dbss.dashboard.actions';
import {
    AUTO_RENEW_ACTION_TYPES,
    AUTO_RENEW_ENTITY_TYPES
} from '../../../../customercare.constants';
class AutoRenewLinkComponent {
    constructor($ngRedux, $timeout, $state, uiNotificationService) {
        Object.assign(this, {
            $filter: getFilterService(),
            $ngRedux,
            $state,
            $timeout,
            autoRenewAction: null,
            autoRenewEntity: null,
            autoRenewKeys: CustomerCareKeys.AUTO_RENEW,
            currencyCode: null,
            CustomerCareKeys,
            formatVariables: this.formatVariables.bind(this),
            hideLabel: false,
            isDisableAutoRenewModalOpen: false,
            isModalOpen: false,
            isOneTimeOffer: false,
            isProduct: false,
            modalApi: null,
            modalConfiguration: null,
            onCloseAutoRenew: this.onCloseAutoRenew.bind(this),
            onCloseDisableAutoRenew: this.onCloseDisableAutoRenew.bind(this),
            onSubmitAutoRenew: this.onSubmitAutoRenew.bind(this),
            onSubmitDisableAutoRenew: this.onSubmitDisableAutoRenew.bind(this),
            onModalOpen: this.onModalOpen.bind(this),
            optIn: null,
            option: null,
            uiNotificationService
        });

        this.compareAutoRenewFieldsEquality = ( fieldName, current, prev ) => {
            return current && prev && current[fieldName] && prev[fieldName] && current[fieldName] !== prev[fieldName];
        };
    }

    $onInit() {

        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store)
            };
        };

        const controllerActions = {
            subscriberProductExpirationRenewOptInOut
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.modalConfiguration = {
            onRegisterApi: (event) => {
                this.modalApi = event.api;
            }
        };
    }

    onModalOpen(optIn) {
        this.optIn = optIn;
        this.formatMessage();
        if (this.optIn) {
            this.isModalOpen = true;
            this.$timeout(() => {
                this.modalApi.open();
            });
        } else {
            this.autoRenewAction = AUTO_RENEW_ACTION_TYPES.DISABLE;
            this.autoRenewEntity = AUTO_RENEW_ENTITY_TYPES.PRICING_PLAN;
            this.isDisableAutoRenewModalOpen = true;
        }
    }

    onCloseAutoRenew() {
        this.isModalOpen = false;
        this.$timeout(() => {
            this.modalApi.close();
        });
    }

    onSubmitAutoRenew(values) {
        if (values) {
            const ReasonCode =  values && values.AutoRenewForm && values.AutoRenewForm.ReasonCode;
            this.actions.subscriberProductExpirationRenewOptInOut(this.state.currentCustomerId, this.data.productId, this.optIn, ReasonCode).then(() => {
                this.refreshOfferDetails && this.refreshOfferDetails();
            });
        }
        this.onCloseAutoRenew();
    }

    onCloseDisableAutoRenew() {
        this.isDisableAutoRenewModalOpen = false;
    }

    onSubmitDisableAutoRenew(_data, message) {
        this.uiNotificationService.transientSuccess(message);
        this.isDisableAutoRenewModalOpen = false;
        this.refreshOfferDetails && this.refreshOfferDetails();
    }

    collectInformation() {
        const {SubscriberProductExpiration, LockerItemId} = this.option;

        if (SubscriberProductExpiration) {

            const {CanOptIn, CanOptOut, ExpiryDate, NextExpiryDate, SubscriberProductId} = SubscriberProductExpiration;

            if (LockerItemId) {
                const {Product, Totals} = this.option;
                this.formatVariables(CanOptIn, CanOptOut, true, NextExpiryDate || ExpiryDate, Product.Name, SubscriberProductId, Totals.Sum );
                this.formatLabel(ExpiryDate, !!NextExpiryDate);
            } else {
                const {PlanName, TotalAmount, ProductClassification} = this.option;
                const notOneTime = ProductClassification === PRODUCT_CLASSIFICATIONS.PRODUCT ? !this.isOneTimeOffer : true;
                this.formatVariables(CanOptIn && notOneTime, CanOptOut && notOneTime, true, NextExpiryDate || ExpiryDate, PlanName, SubscriberProductId, TotalAmount );
                this.formatLabel(ExpiryDate, !!NextExpiryDate);
            }

        }
    }

    formatLabel(_dateForLabel, isRenewal) {
        const dateForLabel = moment.utc(_dateForLabel).local();
        if (this.data.showOptIn === undefined && this.data.showOptOut === undefined) {
            this.data.label = i18n.translate( this.autoRenewKeys.PRODUCT_EXPIRATION, {
                date: this.$filter('localShort')(dateForLabel)
            });
        } else {
            this.data.label = i18n.translate( isRenewal
                ? this.autoRenewKeys.PRODUCT_RENEWAL
                : this.autoRenewKeys.PRODUCT_EXPIRATION, {
                date: this.$filter('localShort')(dateForLabel)
            });
        }
    }

    formatMessage() {
        const dateForLabel = moment.utc(this.data.expirationDate).local();
        const isDynamic = this.data.renewalAmount && this.data.productName && this.data.expirationDate;
        this.data.message = i18n.translate( isDynamic
            ? this.optIn
                ? CustomerCareKeys.AUTO_RENEW.MODAL_TEXT_DYNAMIC
                : CustomerCareKeys.AUTO_RENEW.MODAL_TEXT_DYNAMIC_OUT
            : this.optIn ?
                CustomerCareKeys.AUTO_RENEW.MODAL_TEXT :
                CustomerCareKeys.AUTO_RENEW.MODAL_TEXT_OUT,
        isDynamic
            ? {
                amount: this.$filter('invCurrency')(this.data.renewalAmount, this.currencyCode),
                date: this.$filter('localShortDate')(dateForLabel),
                name: this.data.productName
            }
            : {});
    }

    formatVariables( canOptIn, canOptOut, isRenewable, expirationDate, name, id, amount ) {

        this.data = {};
        this.data.counter = 0;
        this.data.showOptIn = canOptIn && isRenewable;
        this.data.showOptOut = canOptOut && isRenewable;
        this.data.expirationDate = expirationDate;

        this.formatMessage(amount && name && id, amount );
        this.data.productId = id;
        this.data.productName = name;
        this.data.renewalAmount = amount;

        this.data.title = this.data.showOptIn
            ? CustomerCareKeys.AUTO_RENEW.ENABLE
            : CustomerCareKeys.AUTO_RENEW.DISABLE;
    }

    $onChanges( changes ) {
        const {SubscriberProductExpiration: currentSubscriberProductExpiration} = changes.option.currentValue;
        const {SubscriberProductExpiration: previousSubscriberProductExpiration} = changes.option.previousValue;
        if ( changes.option.isFirstChange() ||
            currentSubscriberProductExpiration !== previousSubscriberProductExpiration ||
            this.compareAutoRenewFieldsEquality('CanOptIn', currentSubscriberProductExpiration, previousSubscriberProductExpiration) ||
            this.compareAutoRenewFieldsEquality('CanOptOut', currentSubscriberProductExpiration, previousSubscriberProductExpiration) ||
            this.compareAutoRenewFieldsEquality('ExpiryDate', currentSubscriberProductExpiration, previousSubscriberProductExpiration) ||
            this.compareAutoRenewFieldsEquality('NextExpiryDate', currentSubscriberProductExpiration, previousSubscriberProductExpiration) ||
            this.compareAutoRenewFieldsEquality('RenewalDisabled', currentSubscriberProductExpiration, previousSubscriberProductExpiration)
        ) {
            this.collectInformation();
        }
    }
}

export default {
    bindings: {
        currencyCode: '<',
        isOneTimeOffer: '<',
        option: '<',
        refreshOfferDetails: '&?',
        subscriberProductExpiration: '<',
    },
    controller: AutoRenewLinkComponent,
    template: require('./auto.renew.link.template.html')
};
