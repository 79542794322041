import clone from 'ramda/src/clone';
import find from 'ramda/src/find';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {validateNumbers} from 'invision-core/src/utilities/input.helper';
import {
    fetchCodeTypes,
    fetchCodeTypesThunk
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CurrentBusinessUnitHasSubtenants} from '../../../reducers/selectors/subtenant.selectors';
import {CurrentStepSelector} from '../../../reducers/selectors/new.connect.wizard.selectors';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {getErrorLookupObject} from '../create.customer.constants';
import {NOTIFICATION_TIME_LENGTH} from '../../../customercare.constants';
import {
    LanguagesForCurrentBusinessUnitSelector,
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    CurrentBusinessUnitSelector,
    IsDbss
} from 'invision-core/src/components/session/businessunit.selectors';
import {
    ActiveSubtenantsSelector,
    SubtenantsLoadedSelector
} from 'invision-core/src/components/subtenants/subtenants.selectors';
import {
    CreateEditCustomerSelector,
    CreateCountryCurrencyValueOptions,
    DefaultCountryCurrencySelector,
    HasConvergentBillerIdSelector,
    IsEditCustomerHierarchyRootOrNotInHierarchySelector,
    SelectedCustomerCategorySelector,
    SelectedCustomerHomeCountrySelector,
    SelectedCustomerIsDateOfBirthRequired,
    SubtenantOptionsSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    ContactMethodOptionsSelector,
    CustomerTypeFilteredSelector,
    DefaultCustomerTypeSelector,
    HomeCountriesStatesSelector,
    SubscriberIdentificationsSelector,
    SubscriberTypeFilterByUserRole,
    TaxAssociationCodesSelector,
    TaxExemptionTypeCodesSelector,
    TaxLevelCodesSelector,
} from '../../createCustomerPopup/customer.form.dropdown.selectors';
import {
    searchSubtenants,
    setCustomerCategory,
    fetchSubtenantMetadata
} from '../../../reducers/actions/customer.actions';
import {
    ACCEPTING_FOR,
    CONSENT_TYPES,
    CUSTOMER_CATEGORY,
    NO_SUBTENANTS
} from '../../createCustomerPopup/create.customer.popup.constants';
import * as taxModelHelper from '../tax.model.helper';
import {StatesByCountrySelector} from '../../wizards/newConnectWizard/new.connect.wizard.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import {BillingConfigurationLoadedSelector} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.selectors';
import {BILLING_CONFIGURATION_TYPES} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.action.types';
import {getPreferredTaxDefaultCurrency} from '../../../reducers/helpers/customer.selectors.helpers';
const controllerActions = {
    fetchCodeTypes,
    fetchCodeTypesThunk,
    fetchSubtenants: fetchSubtenantMetadata,
    getTaxAssociationCodes: MetadataActions.billingConfiguration.getTaxAssociationCodes,
    searchSubtenants,
    setCustomerCategory
};

class CustomerInformationSectionController {

    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            AcceptingFor: ACCEPTING_FOR,
            ConsentTypes: CONSENT_TYPES,
            CoreLocaleKeys: CoreLocaleKeys,
            CustomerCareLocaleKeys: CustomerCareLocaleKeys,
            CustomerCategory: CUSTOMER_CATEGORY,
            lastCountry: null,
            chargeTypesForDetailedTaxation: taxModelHelper.getChargeTypesForDetailedTaxation(),
            numberOfRequiredIdentifications: 0,
            onTaxTypeAndLevelSelectorChanged : this.onTaxTypeAndLevelSelectorChanged.bind(this),
            selectedTaxLevelExemptions: [],
            setSubtenantOptions: this.setSubtenantOptions.bind(this),
            shouldShowSubtenant: this.shouldShowSubtenant.bind(this),
            taxLevelExemptionsList: taxModelHelper.taxLevelExemptions(),
            uiNotificationService,
            validateNumbers
        });
    }

    $onChanges(changesObj) {
        if (changesObj.taxCategoryExemptions) {
            this.updateTaxCategoryExemptions(this.taxCategoryExemptions);
        }
        if (changesObj.isGdprCompliant || changesObj.minimumAgeCountries) {
            if (this.isGdprCompliant && this.minimumAgeCountries.length > 0) {
                this.updateMinimumAgeByCountry();
            }
        }
        if (changesObj.formattedIdentities && (changesObj.formattedIdentities.currentValue !== changesObj.formattedIdentities.previousValue)) {
            this.mutatedFormattedIdentities = clone(this.formattedIdentities);
        }
        if (this.requirements.enable_preferred_tax_reporting_currency && (changesObj.customerModel || changesObj.defaultPreferredReportingTaxValues)) {
            const homeCountry = changesObj.customerModel ? changesObj.customerModel.currentValue.HomeCountry : this.customerModel.HomeCountry;

            if (this.defaultPreferredReportingTaxValues.length && !this.customerModel.Id) {
                const defaultCurrency = getPreferredTaxDefaultCurrency(this.defaultPreferredReportingTaxValues, homeCountry);
                if (homeCountry && defaultCurrency && !this.customerModel.PreferredTaxReportingCurrency) {
                    this.customerModel.PreferredTaxReportingCurrency = defaultCurrency;
                }

                if (changesObj.customerModel && changesObj.customerModel.previousValue &&
                    this.lastCountry !== homeCountry &&
                    (!defaultCurrency || defaultCurrency !== this.customerModel.PreferredTaxReportingCurrency) &&
                    this.customerModel.PreferredTaxReportingCurrency) {
                    this.customerModel.PreferredTaxReportingCurrency = defaultCurrency;
                }
                this.lastCountry = homeCountry;
            }
        }
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                addressStates: StatesByCountrySelector(store),
                allCountries: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.AllCountry, store),
                allCountriesLoaded: MetadataCodeLoadedSelector(CODES.AllCountry, store),
                allCountryCurrencies: MetadataCodeTypeSelector(CODES.CountryCurrency, store),
                contactMapping: MetadataCodeTypeSelector(CODES.ContactMapping, store),
                contactMappingLoaded: MetadataCodeLoadedSelector(CODES.ContactMapping, store),
                contactMethodOptions: ContactMethodOptionsSelector(store),
                contactMethods: MetadataCodeTypeSelector(CODES.ContactMethod, store),
                contactMethodsLoaded: MetadataCodeLoadedSelector(CODES.ContactMethod, store),
                countryCurrencies: CreateCountryCurrencyValueOptions(store),
                currenciesLoaded: MetadataCodeLoadedSelector(CODES.Currency, store),
                countryCurrenciesLoaded: MetadataCodeLoadedSelector(CODES.CountryCurrency, store),
                createCustomer: CreateEditCustomerSelector(store),
                currentBusinessUnit: CurrentBusinessUnitSelector(store),
                currentBusinessUnitHasSubtenants: CurrentBusinessUnitHasSubtenants(store),
                currentStep: CurrentStepSelector(store),
                currentSubtenants: ActiveSubtenantsSelector(store),
                customerCategory: SelectedCustomerCategorySelector(store),
                customerTypeFilteredOptions: CustomerTypeFilteredSelector(store, this.isNewCustomer || false),
                defaultCountryCurrency: DefaultCountryCurrencySelector(store),
                defaultCustomerType: DefaultCustomerTypeSelector(store),
                hasConvergentBillerId: HasConvergentBillerIdSelector(store),
                homeCountries: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.AddressCountry, store),
                homeCountriesLoaded: MetadataCodeLoadedSelector(CODES.AddressCountry, store),
                homeCountriesStates: HomeCountriesStatesSelector(store),
                homeCountriesStatesLoaded: MetadataCodeLoadedSelector(CODES.AddressStateProvinceRegion, store),
                invoiceDetailLevelOptions: MetadataCodeTypeSelector(CODES.InvoiceDetailLevel, store),
                isDateOfBirthRequired: SelectedCustomerIsDateOfBirthRequired(store),
                isDbss: IsDbss(store),
                isEditCustomerHierarchyRootOrNotInHierarchy: IsEditCustomerHierarchyRootOrNotInHierarchySelector(store),
                isSubtenantsLoaded: SubtenantsLoadedSelector(store),
                isTaxTypeAssociationCodeLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.TAX_ASSOCICATION_CODE, store),
                languages: LanguagesForCurrentBusinessUnitSelector(store),
                personGenderOptions: MetadataOptionsForCodeValuesSelector(CODES.PersonGender, store),
                personRaceOptions: MetadataOptionsForCodeValuesSelector(CODES.PersonRace, store),
                personSuffixOptions: MetadataOptionsForCodeValuesSelector(CODES.PersonSuffix, store),
                personTitleOptions: MetadataOptionsForCodeValuesSelector(CODES.PersonTitle, store),
                regularExpressionLoaded: MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                preferredTaxReportingCurrencyConfigLoaded: MetadataCodeLoadedSelector(CODES.DefaultPreferredTaxReportingCurrencyConfig, store),
                selectedCustomerHomeCountry: SelectedCustomerHomeCountrySelector(store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberIdentifications: SubscriberIdentificationsSelector(store),
                subscriberIdentificationsLoaded: MetadataCodeLoadedSelector(CODES.SubscriberIdentifications, store),
                subscriberIncomeLevelTypeOptions: MetadataOptionsForCodeValuesSelector(CODES.SubscriberIncomeLevelType, store),
                subscriberLeadSourceTypeOptions: MetadataOptionsForCodeValuesSelector(CODES.SubscriberLeadSourceType, store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberTypeCodes: SubscriberTypeFilterByUserRole(store),
                subscriberTypeSecurityRoleLoaded: MetadataCodeLoadedSelector(CODES.SubscriberTypeSecurityRole, store),
                subscriberValidationTypesLoaded: MetadataCodeLoadedSelector(CODES.SubscriberValidationTypes, store),
                subtenantOptions: SubtenantOptionsSelector(store),
                taxAssociationCodes: TaxAssociationCodesSelector(store),
                taxExemptionTypes: TaxExemptionTypeCodesSelector(store),
                taxLevelCodes: TaxLevelCodesSelector(store)
            };
        };

        this.isHierarchyParentSubscriberDisabled = this.customerModel.HierarchyParentSubscriberId;
        this.taxExemptionTypesEditValues = [];
        if (this.customerModel.TaxExemptionTypes) {
            this.customerModel.TaxExemptionTypes.forEach((taxExemptionTypesVal, index) => {
                this.taxExemptionTypesEditValues.push({
                    taxLevelCode: {
                        Id: taxExemptionTypesVal.TaxLevelCode.toString()
                    },
                    taxExemptionType: {
                        Id: taxExemptionTypesVal.TaxType
                    }
                });
                if (taxExemptionTypesVal.TaxAssociationCode) {
                    this.taxExemptionTypesEditValues[index].taxAssociationCode = {
                        Id: taxExemptionTypesVal.TaxAssociationCode,
                    };
                } else if (!taxExemptionTypesVal.TaxAssociationCode) {
                    this.taxExemptionTypesEditValues[index].taxAssociationCode = null;
                }
                if (taxExemptionTypesVal.State) {
                    this.taxExemptionTypesEditValues[index].addressStateProvinceRegionCode = {
                        Id: taxExemptionTypesVal.State,
                    };
                } else if (!taxExemptionTypesVal.State) {
                    this.taxExemptionTypesEditValues[index].addressStateProvinceRegionCode = null;
                }
                if (taxExemptionTypesVal.OverridePercentage !== null) {
                    this.taxExemptionTypesEditValues[index].overrideValue = taxExemptionTypesVal.OverridePercentage;
                }
            });
        }

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.state.isDbss) {
            this.countryLabel = i18n.translate(CustomerCareLocaleKeys.COUNTRY);
        } else {
            this.countryLabel = i18n.translate(CustomerCareLocaleKeys.HOME_COUNTRY);
        }
        if (this.state.isDbss && !this.state.isTaxTypeAssociationCodeLoaded) {
            this.actions.getTaxAssociationCodes();
        }

        if (!this.state.homeCountriesLoaded) {
            this.actions.fetchCodeTypes(CODES.AddressCountry).then(() => {
                this.checkHomeCountry();
            });
        } else {
            this.checkHomeCountry();
        }

        if (!this.state.allCountriesLoaded) {
            this.actions.fetchCodeTypes(CODES.AllCountry);
        }

        if (this.isEditingProfile() && this.state.isDbss) {
            this.actions.setCustomerCategory(this.customerModel.Category);
        } else if (!this.customerModel.SubscriberTypeCode && !this.isEditingProfile()) {
            this.customerModel.SubscriberTypeCode = this.state.defaultCustomerType;
        }
        this.customerModel.PreferredTaxReportingCurrency = this.customerModel.PreferredTaxReportingCurrency || null;
        this.customerModel.Language = this.customerModel.Language ? this.customerModel.Language : this.state.currentBusinessUnit.defaultLanguage;
        this.updateMinimumAgeByCountry();

        this.selectedTaxLevelExemptions = (this.isNewCustomer && !this.customerModel.TaxExemption) ?
            [] : this.taxLevelExemptions.slice(0);

        this.lockedCountry = this.customerModel.HomeCountry;

        if (!this.state.contactMethodsLoaded) {
            this.actions.fetchCodeTypes(CODES.ContactMethod);
        }

        if (!this.state.preferredTaxReportingCurrencyConfigLoaded) {
            this.actions.fetchCodeTypes(CODES.DefaultPreferredTaxReportingCurrencyConfig);
        }

        if (!this.state.contactMappingLoaded) {
            this.actions.fetchCodeTypes(CODES.ContactMapping);
        }

        if (!this.state.subscriberTypeSecurityRoleLoaded) {
            this.actions.fetchCodeTypes(CODES.SubscriberTypeSecurityRole);
        }

        if (!this.state.currenciesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.Currency);
        }
        if (!this.state.countryCurrenciesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.CountryCurrency).then(() => {
                this.triggerFormChange();
            });
        } else {
            this.triggerFormChange();
        }
        this.actions.searchSubtenants().then(() => {
            this.setSubtenantOptions();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        if (!this.state.subscriberIdentificationsLoaded) {
            this.actions.fetchCodeTypes(CODES.SubscriberIdentifications);
        }

        if (!this.state.subscriberValidationTypesLoaded) {
            this.actions.fetchCodeTypes(CODES.SubscriberValidationTypes);
        }

        if (!this.state.regularExpressionLoaded) {
            this.actions.fetchCodeTypes(CODES.RegularExpression);
        }

        if (!this.state.homeCountriesStatesLoaded) {
            this.actions.fetchCodeTypes(CODES.AddressStateProvinceRegion);
        }

        this.isCountryDisabled = this.isNewConnect || (!this.isNewCustomer && this.state.hasConvergentBillerId);

        this.handleConsentFormChanged = () => {
            this.onFormChanged()(this.customerModel);
        };

        this.externalReferenceId = this.customerModel.ExternalReference;
    }

    get canDisplayConsentFields() {
        return (this.mandatoryConsents.length > 0);
    }

    get requirements() {
        if (this.state) {
            return this.state.customerCategory === CUSTOMER_CATEGORY.COMMERCIAL ? this.state.subscriberCategoryRequirements : this.state.subscriberRequirements;
        } else {
            return {};
        }
    }

    triggerFormChange() {
        if (this.state.isDbss && !this.isNewConnect) {
            this.handleChangeFormCountry();
        } else {
            this.handleFormChanged();
        }
    }

    getLanguageFriendlyName() {
        const langObj = find(propEq(this.customerModel.Language, 'value'), this.state.languages);
        return (langObj) ? langObj.label : this.customerModel.Language;
    }

    getSubtenantName() {
        if (this.customerModel.SubtenantId) {
            const subtenant = this.state.currentSubtenants.find((subtenant) => {
                return subtenant.Id.Value === this.customerModel.SubtenantId.Value;
            });

            return subtenant ? subtenant.Name : i18n.translate(this.CoreLocaleKeys.NOT_APPLICABLE);
        } else {
            return i18n.translate(this.CoreLocaleKeys.NOT_APPLICABLE);
        }
    }

    setSubtenantOptions() {
        if (!this.selectedCustomerSubtenant) {
            this.subtenantOptions = this.state.subtenantOptions;
        } else {
            this.subtenantOptions = (this.selectedCustomerSubtenant !== NO_SUBTENANTS) ? this.state.subtenantOptions.filter((subtenant) => {
                return this.selectedCustomerSubtenant === subtenant.value;
            }) : [];
        }
    }

    shouldShowSubtenant() {
        return this.state.currentBusinessUnitHasSubtenants && !this.isNewConnect && this.requirements.display_subtenant && this.subtenantOptions.length;
    }

    handleAcceptingForChanged() {
        this.customerModel.IsMinor = this.customerModel.ConsentAcceptingFor === this.AcceptingFor.CHILD;
        this.updateMinimumAgeByCountry();
        this.updateMinimumAgeByAcceptingFor();
        this.handleFormChanged();
    }

    handleChangeFormCountry() {
        if (this.isGdprCompliant) {
            this.updateMinimumAgeByCountry();
            this.updateMinimumAgeByAcceptingFor();
            if (this.isNewCustomer) {
                this.customerModel.ConsentAcceptingMandatoryTerms.IsAccepted = false;
            } else if (this.customerModel.ConsentAcceptingMandatoryTerms) {
                this.customerModel.ConsentAcceptingMandatoryTerms.IsAccepted = (this.customerModel.HomeCountry === this.state.selectedCustomerHomeCountry);
            }
        }
        if (this.state.isDbss) {
            if (this.isCurrencyDisabled && !this.isCountryChangeAllowed()) {
                this.customerModel.HomeCountry = this.lockedCountry;
                this.handleFormChanged();
                this.uiNotificationService.error(i18n.translate(CustomerCareLocaleKeys.CURRENCY_NOT_SUPPORTED), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            } else {
                this.handleFormChanged();
                this.updateCountryCurrencies();

            }
        } else {
            this.handleFormChanged();
        }
    }

    handleChangeForExternalReference() {
        if (!!this.customerModel.ExternalReference && !this.externalReferenceId) {
            this.parentForm.externalId.$setValidity('emptyId', false);
        } else {
            this.parentForm.externalId.$setValidity('emptyId', true);
            this.customerModel.ExternalReference = this.externalReferenceId;
            this.handleFormChanged();
        }
    }

    handleFormChanged() {
        this.onFormChanged()(this.customerModel);
    }

    updateCountryCurrencies() {
        if (this.state.countryCurrencies) {
            this.updateCountryCurrencyField();
        } else {
            this.uiNotificationService.error(i18n.translate(CustomerCareLocaleKeys.CURRENCY_NOT_FOUND), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        }
    }

    isCountryChangeAllowed() {
        const currencies = this.state.allCountryCurrencies.filter((currency) => {
            return (currency.AdditionalProperties.find(propEq('country', 'Key')).Value === this.customerModel.HomeCountry && currency.AdditionalProperties.find(propEq('currency', 'Key')).Value === this.customerModel.SubscriberCurrency);
        });
        return currencies.length;
    }

    updateMinimumAgeByAcceptingFor() {
        if (this.customerModel.ConsentAcceptingFor === this.AcceptingFor.CHILD) {
            const today = new Date();
            this.maximumAgeDate = today;
            this.setNewErrorLookup(today);
        }
    }

    updateMinimumAgeByCountry() {
        if (this.customerModel.IsMinor) { // IsMinor would be returned as part of the API Response in RetrieveSubscriber (DD-43048).
            const today = new Date();
            this.maximumAgeDate = today;
            this.setNewErrorLookup(today);
        } else {
            const matchingCountry = this.getMatchingSelectedCountry(this.customerModel.HomeCountry);
            if (matchingCountry) {
                this.setMinimumAgeFromCountry(matchingCountry);
            } else if (pathOr(null, ['minimum_age'], this.requirements)) {
                this.setMinimumAgeFromDefault(); // As per settings on SL
            } else {
                const matchingCountry = this.getMatchingSelectedCountry('');
                if (matchingCountry) {
                    this.setMinimumAgeFromCountry(matchingCountry);
                }
            }
        }
    }

    getMatchingSelectedCountry(countryCode) {
        return this.minimumAgeCountries.find((codeCountry) => {
            const countryValue = codeCountry.AdditionalProperties.find((countryProp) => {
                return countryProp.Key === 'country';
            }).Value;
            return countryValue === countryCode;
        });
    }

    setMinimumAgeFromCountry(matchingCountry) {
        const minimumAge = matchingCountry.AdditionalProperties.find((additionalProperty) => {
            return additionalProperty.Key === 'age';
        }).Value;
        const maximumAgeDate = new Date();
        maximumAgeDate.setFullYear(maximumAgeDate.getFullYear() - minimumAge);

        this.maximumAgeDate = maximumAgeDate;
        this.setNewErrorLookup(minimumAge);
    }

    setMinimumAgeFromDefault() {
        this.maximumAgeDate = this.requirements.minimum_age_birthdate;
        this.setNewErrorLookup(this.requirements.minimum_age);
    }

    setNewErrorLookup(minAge) {
        if (pathOr(null, ['subscriberRequirements', 'maximum_age'], this.state)) {
            this.errorLookup = getErrorLookupObject({
                birthDate: {
                    outOfRange: {
                        maxAge: this.state.subscriberRequirements.maximum_age,
                        minAge: minAge
                    }
                }
            });
        }
    }

    updateCountryCurrencyField() {
        if (this.state.countryCurrencies) {
            if (this.isNewCustomer) {
                this.customerModel.SubscriberCurrency = this.state.defaultCountryCurrency?.Value || this.state.countryCurrencies[0].value;
            } else {
                this.customerModel.SubscriberCurrency = this.state.createCustomer.SubscriberCurrency ||
            this.state.defaultCountryCurrency?.Value || this.state.countryCurrencies[0]?.value;
            }
        }
        this.handleFormChanged();
    }

    isError(elementName, fields = []) {
        const formElement = this.parentForm[elementName];
        let isError = false;
        if (formElement && (formElement.$touched || this.isFormSubmitted)) {
            if (fields.length === 0 && Object.keys(formElement.$error).length > 0) {
                isError = true;
            }

            fields.forEach(field => {
                if (formElement.$error[field]) {
                    isError = true;
                }
            });
        }
        return isError;
    }

    onSelectedTaxLevelExemptionsChanged() {
        this.customerModel.TaxExemption = taxModelHelper.createTaxExemptionFromOptions(this.selectedTaxLevelExemptions, this.customerModel);
        this.handleFormChanged();
    }

    onTaxTypeAndLevelSelectorChanged() {
        if (this.taxExemptionTypesEditValues && this.taxExemptionTypesEditValues.length > 0 ) {
            this.customerModel.TaxExemptionTypes = this.formatTaxExemptionDataForServer(this.taxExemptionTypesEditValues);
        } else {
            this.customerModel.TaxExemptionTypes = [];
        }
        this.handleFormChanged();
    }

    onSubtenantChanged() {
        if (!this.customerModel.SubtenantId.Value) {
            this.customerModel.SubtenantId = null;
        }
        this.handleFormChanged();
    }

    formatTaxExemptionDataForServer(taxExemptionTypesEditValues) {
        const taxExemptionTypes = [];
        taxExemptionTypesEditValues.forEach((taxExemptionTypesEditValue) => {
            if (taxExemptionTypesEditValue.taxLevelCode && taxExemptionTypesEditValue.taxExemptionType) {
                taxExemptionTypes.push({
                    TaxLevelCode: convertStringToNumber(taxExemptionTypesEditValue.taxLevelCode.Id),
                    TaxType: taxExemptionTypesEditValue.taxExemptionType.Id,
                    TaxAssociationCode: taxExemptionTypesEditValue.taxAssociationCode ? taxExemptionTypesEditValue.taxAssociationCode.Id : null,
                    State: taxExemptionTypesEditValue.addressStateProvinceRegionCode ? taxExemptionTypesEditValue.addressStateProvinceRegionCode.Id : null,
                    OverridePercentage: taxExemptionTypesEditValue.overrideValue
                });
            }
        });

        return taxExemptionTypes;
    }

    synchronizeMultiSelectOptions(options, items, propertyName = 'text') {
        return options.map((option) => {
            const selectedItemExists = find(propEq(option[propertyName], propertyName))(items);
            if (selectedItemExists) {
                option.selected = selectedItemExists.selected;
            } else if (option.selected) {
                option.selected = !option.selected;
            }
            return option;
        });
    }

    updateTaxCategoryExemptions(taxCategoryExemptions) {
        this.taxCategoryExemptionsOptions = clone(taxCategoryExemptions);
        if (this.alreadyExistingCustomer() && this.hasSelectedTaxCategoryExemptions()) {
            this.setSelectedTaxCategories(this.customerSelectedTaxCategoryExemptions);
        } else if (this.temporaryPersistedCustomer()) {
            this.setSelectedTaxCategories(this.customerModel.TaxExemptionCategories);
        }
    }

    alreadyExistingCustomer() {
        return !this.isNewCustomer;
    }

    hasSelectedTaxCategoryExemptions() {
        return this.customerSelectedTaxCategoryExemptions.length > 0;
    }

    temporaryPersistedCustomer() {
        return !!(this.customerModel.TaxExemptionCategories);
    }

    setSelectedTaxCategories(selectedTaxCategoryExemptions) {
        this.taxCategoryExemptionsOptions.forEach((item) => {
            item.selected = selectedTaxCategoryExemptions.some((exemption) => {
                return exemption === item.id;
            });
        });
    }

    shouldShowTaxTypeAndLevelSelector() {
        return !!(this.state.taxExemptionTypes.length && this.state.taxLevelCodes.length);
    }

    isEditingProfile() {
        return !!this.state.createCustomer.Id;
    }

    isEditingInDbss() {
        return this.state.isDbss && this.isEditingProfile();
    }

    selectedCountryName() {
        const selectedCountry = this.state.homeCountries.find((country) => {
            return country.value === this.customerModel.HomeCountry;
        });

        return pathOr(this.customerModel.HomeCountry, ['label'], selectedCountry);
    }

    selectedCurrencyName() {
        const selectedCurrency = this.state.countryCurrencies && this.state.countryCurrencies.find((currency) => {
            return currency.value === this.customerModel.SubscriberCurrency;
        });

        return pathOr(this.customerModel.SubscriberCurrency, ['label'], selectedCurrency);
    }

    checkHomeCountry() {
        if (!this.customerModel.HomeCountry || !this.state.homeCountries.find((country) => {
            return country.value === this.customerModel.HomeCountry;
        })) {
            this.customerModel.HomeCountry = this.state.currentBusinessUnit.defaultCountry;
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    isIdentificationRequired(index) {
        return index < this.mutatedFormattedIdentities.numberOfRequiredIdentifications;
    }

    getCountries(customerSubscriberIdentification) {
        const result = this.customerModel.SubscriberIdentifications.find((subscriberIdentification) => {
            return subscriberIdentification.SubscriberIdentificationTypeCode === customerSubscriberIdentification.SubscriberIdentificationTypeCode;
        });
        return customerSubscriberIdentification.UseConfiguredCountries ? (result ? customerSubscriberIdentification.Countries : this.state.homeCountries) : this.state.allCountries;
    }

    beforeHandleUpdateIdentification(customerSubscriberIdentification) {
        const selectedSubscriberIdentification = this.state.subscriberIdentifications.find((subscriberIdentification) => {
            return subscriberIdentification.Value === customerSubscriberIdentification.SubscriberIdentificationTypeCode;
        });

        customerSubscriberIdentification.Countries = selectedSubscriberIdentification ? selectedSubscriberIdentification.Countries : null;
        customerSubscriberIdentification.IsPrimary = selectedSubscriberIdentification ? selectedSubscriberIdentification.IsPrimary : null;
        customerSubscriberIdentification.IsCountryStateRequired = selectedSubscriberIdentification ? selectedSubscriberIdentification.IsCountryStateRequired : null;
        customerSubscriberIdentification.IsCountryStateVisible = selectedSubscriberIdentification ? selectedSubscriberIdentification.IsCountryStateVisible : null;
        customerSubscriberIdentification.Regex = selectedSubscriberIdentification ? selectedSubscriberIdentification.Regex : null;
        customerSubscriberIdentification.UseConfiguredCountries = selectedSubscriberIdentification ? selectedSubscriberIdentification.UseConfiguredCountries : null;

        this.handleIdentificationChanged();
    }

    handleCountryChanged(subscriberIdentification) {
        subscriberIdentification.State = null;
        this.handleIdentificationChanged();
    }

    handleIdentificationChanged() {
        this.customerModel.SubscriberIdentifications = this.mutatedFormattedIdentities.formattedSubscriberIdentifications.map((subIdentification) => {
            return {
                Country: subIdentification.Country,
                Id: subIdentification.Id || null,
                State: subIdentification.State,
                SubscriberIdentificationTypeCode: subIdentification.SubscriberIdentificationTypeCode,
                SubscriberIdentificationValue: subIdentification.SubscriberIdentificationValue,
            };
        }).filter((subIdentification) => {
            return subIdentification.SubscriberIdentificationTypeCode || subIdentification.SubscriberIdentificationValue;
        });
        this.handleFormChanged();
    }

    addIdentityVerification() {
        this.mutatedFormattedIdentities.formattedSubscriberIdentifications.push({
            IsCountryStateRequired: false,
            IsCountryStateVisible: false,
            IsPrimary: false,
            Regex: null,
            SubscriberIdentificationTypeCode: null,
            SubscriberIdentificationValue: null,
            UseConfiguredCountries: null
        });
    }

    removeIdentityVerification(index) {
        this.mutatedFormattedIdentities.formattedSubscriberIdentifications.splice(index, 1);
        this.handleIdentificationChanged();
    }

    filterIdentifications(index, subscriberIdentificationId) {
        return (option) => {
            if (!option.Value || (option.Value === subscriberIdentificationId)) {
                return true;
            }

            if (index === 0 && !option.IsPrimary) {
                return false;
            } else {
                // Don't show an option which was already selected in another dropdown.
                for (let itemIndex = 0; itemIndex < this.mutatedFormattedIdentities.formattedSubscriberIdentifications.length; itemIndex++) {
                    if (itemIndex !== index && option.Value === this.mutatedFormattedIdentities.formattedSubscriberIdentifications[itemIndex].SubscriberIdentificationTypeCode) {
                        return false;
                    }
                }
            }

            return true;
        };

    }

    identificationsExists() {
        return this.state.subscriberIdentifications && this.state.subscriberIdentifications.length;
    }

    getRegexPattern(subscriberIdentification, elementName) {
        if (this.isEditingProfile()) {
            return this.parentForm[elementName].$dirty ? subscriberIdentification.Regex : null;
        } else {
            return subscriberIdentification.Regex;
        }
    }

    get isRequired() {
        return this.removeMultiOfferValidations !== undefined ? !this.removeMultiOfferValidations : !this.overrideValidations;
    }

    get isRequiredForGDPRAndLiteCustomer() {
        return (this.canDisplayConsentFields && this.overrideValidations) || (this.removeMultiOfferValidations !== undefined && this.canDisplayConsentFields);
    }
}

export default {
    template: require('./customer.information.section.html'),
    bindings: {
        customerModel: '<',
        customerPreferredTaxCurrencies: '<',
        customerSelectedTaxCategoryExemptions: '<',
        defaultPreferredReportingTaxValues: '<',
        errorLookup: '<',
        formattedIdentities: '<',
        isCurrencyDisabled: '<',
        isCustomerTypeReadOnly: '<',
        isFormSubmitted: '<',
        isGdprCompliant: '<',
        isHierarchyParentSubscriberDisabled: '<?',
        isMandatoryConsentsToBeAccepted: '<',
        isNewConnect: '<?',
        isNewCustomer: '<',
        isParentNodeDisabled: '<?',
        mandatoryConsents: '<',
        markMandatoryCreditCheckFieldsDisabled: '<?',
        minimumAgeCountries: '<',
        onFormChanged: '&',
        overrideValidations: '<?',
        removeMultiOfferValidations: '<?',
        selectedCustomerSubtenant: '<',
        taxCategoryExemptions: '<',
        taxLevelExemptions: '<'
    },
    require: {
        parentForm: '^form'
    },
    controller: CustomerInformationSectionController,
    controllerAs: 'CustomerInformationSection'
};
