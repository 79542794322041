import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME} from '../../../../../../reducers/constants/products.wizard.constants';

class ServiceFeaturesController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys
        });
    }

    $onInit() {
        const controllerActions = {
            stateGo
        };
        const mapStateToProps = () => {
            return {};
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.moreMenuItems = this.getMoreMenuItems();
    }

    getMoreMenuItems() {
        const moreMenuItems = [];
        const isSelectedServiceSuspended = this.serviceStatus === NON_BULK_SERVICE_STATUSES.SUSPENDED;

        moreMenuItems.push({
            title: i18n.translate(CustomerLocaleKeys.MANAGE_ADD_ONS),
            action: (item) => {
                if (!item.isLocked) {
                    this.actions.stateGo(MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME, {
                        inventoryCategoryCode: null,
                        serviceId: this.serviceIdentifier.Value,
                        serviceTypeId: this.serviceId,
                        serviceInstanceId: this.serviceIdentifier.ServiceInstanceId
                    });
                }
            },
            isLocked: isSelectedServiceSuspended,
            disabled: isSelectedServiceSuspended
        });

        return moreMenuItems;
    }

    onMoreMenuItemSelected({item: itemProps}) {
        itemProps.action(itemProps);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        currencyCode: '<',
        deviceAddons: '<',
        physicalInventoryServiceIdentifier: '<',
        serviceAddons: '<',
        serviceId: '<',
        serviceIdentifier: '<',
        serviceStatus: '<'
    },
    template: require('./service.features.html'),
    controller: ServiceFeaturesController
};
