import {STATE_OR_NAME} from '../../../../../reducers/constants/add.offer.wizard.constants';

export function routing($stateProvider) {
    $stateProvider
        .state(STATE_OR_NAME, {
            url: '/createOffersOrder?action?storeOrderType',
            ignoreBack: true,
            template: '<add-offer class="u-sizeFull u-flexKeepHeight"></add-offer>'
        });
}
