import CustomerLocaleKeys from '../../../../../../locales/keys';
import {retrieveServiceDetails} from '../../../../../../reducers/actions/services.actions';
import {CurrentCustomerIdSelector} from '../../../../../../reducers/selectors/customer.selectors';
import {
    IsFetchingServiceDetailsSelector,
    SelectedServiceAssociatedDeviceAddonsSelector,
    SelectedServiceAssociatedDeviceFinanceDetailsSelector,
    SelectedServiceAssociatedDeviceFinanceDiscountsSelector,
    SelectedServiceAssociatedOfferDetailsSelector,
    SelectedServiceAssociatedServiceAddonsSelector,
    SelectedServiceAssociatedServiceIdSelector,
    SelectedServiceCurrencyCodeSelector,
    SelectedServiceDetailsSelector,
    SelectedServicePolicyCountersSelector,
    SharedEntitlementBalancesWithFormattedServiceIdentifierSelector
} from '../../../../../../reducers/selectors/selected.service.details.selectors';
import {IsFetchingAddressesSelector} from '../../../../../../reducers/selectors/customer.addresses.selectors';
import {retrieveCustomerAddresses} from '../../../../../../reducers/actions/customer.addresses.actions';

class ServiceDetailsController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            fetchServiceDetails: this.fetchServiceDetails.bind(this),
            uiNotificationService
        });
    }

    $onInit() {

        const controllerActions = {
            retrieveCustomerAddresses,
            retrieveServiceDetails
        };

        const mapStateToTarget = (store) => {
            return {
                currencyCode: SelectedServiceCurrencyCodeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                deviceAddons: SelectedServiceAssociatedDeviceAddonsSelector(store),
                deviceFinanceDetails: SelectedServiceAssociatedDeviceFinanceDetailsSelector(store),
                deviceFinanceDiscounts: SelectedServiceAssociatedDeviceFinanceDiscountsSelector(store),
                isFetchingAddresses: IsFetchingAddressesSelector(store),
                isFetchingServiceDetails: IsFetchingServiceDetailsSelector(store),
                offerDetails: SelectedServiceAssociatedOfferDetailsSelector(store),
                policyCounters: SelectedServicePolicyCountersSelector(store),
                serviceAddons: SelectedServiceAssociatedServiceAddonsSelector(store),
                serviceDetails: SelectedServiceDetailsSelector(store),
                serviceId: SelectedServiceAssociatedServiceIdSelector(store),
                sharedEntitlementBalances: SharedEntitlementBalancesWithFormattedServiceIdentifierSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.fetchServiceDetails();
        this.fetchAddress();
    }

    $onChanges(changesObj) {
        if (changesObj.serviceIdentifier && changesObj.serviceIdentifier.currentValue && this.actions) {
            this.fetchServiceDetails();
        }
    }

    fetchServiceDetails() {
        if (this.serviceIdentifier) {
            const serviceIdentifiers = [{
                Value: this.serviceIdentifier
            }];
            this.actions.retrieveServiceDetails(this.state.currentCustomerId, serviceIdentifiers).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }
    fetchAddress() {
        this.actions.retrieveCustomerAddresses(this.state.currentCustomerId, false)
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    onRefreshConvergentBillerDetails() {
        this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
    }
}

export default {
    bindings: {
        hideServiceTaxDetails:'<?',
        refreshConvergentBillerDetails: '&?',
        serviceIdentifier: '<'
    },
    template: require('./service.details.html'),
    controller: ServiceDetailsController
};
