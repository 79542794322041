import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerCurrencyCodeSelector
} from '../../../../../../reducers/selectors/customer.selectors';
import {OfferingIsOffCycleMapSelector} from '../../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {retrieveSubscriberOfferingDetail} from '../../../../../../reducers/actions/customer.actions';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {
    IsFetchingOfferDetailsSelector,
    SelectedOfferingAndOptionsSelector,
    SubscriberServicesWithFormatedServicesSelector,
    SelectedOfferingInventoryItemsSelector,
    SelectedOfferingInventoryItemsCountSelector
} from '../../../../../../reducers/selectors/search.subscriber.offerings.selectors';


class SubscriberOfferController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            uiNotificationService
        });
    }

    $onInit() {

        const controllerActions = {
            retrieveOfferingsMetadata,
            retrieveSubscriberOfferingDetail
        };

        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                inventoryItems: SelectedOfferingInventoryItemsSelector(store),
                inventoryItemsCount: SelectedOfferingInventoryItemsCountSelector(store),
                isFetchingOfferDetails: IsFetchingOfferDetailsSelector(store),
                offeringIsOffCycleMap: OfferingIsOffCycleMapSelector(store),
                selectedOffering: SelectedOfferingAndOptionsSelector(store),
                selectedOfferingServices: SubscriberServicesWithFormatedServicesSelector(store),
                subscriberCurrencyCode: CurrentCustomerCurrencyCodeSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.fetchOfferingDetails();
    }

    $onChanges(changesObj) {
        if (changesObj.selectedOfferingInstanceId && changesObj.selectedOfferingInstanceId.currentValue && this.actions) {
            this.fetchOfferingDetails();
        }
    }

    fetchOfferingDetails() {
        if (this.selectedOfferingInstanceId) {
            this.actions.retrieveSubscriberOfferingDetail(
                this.state.currentCustomerId,
                this.selectedOfferingInstanceId,
                this.state.offeringIsOffCycleMap?.[this.selectedOfferingInstanceId]
            ).then((response) => {
                return this.actions.retrieveOfferingsMetadata([response.SubscriberOffer.OfferingId]);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    onRefreshConvergentBillerDetails() {
        this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        refreshConvergentBillerDetails: '&?',
        selectedOfferingInstanceId: '<',
        showStatusIndicator: '<?'
    },
    template: require('./subscriber.offer.html'),
    controller: SubscriberOfferController
};
