/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-02-15T10:44:11.721Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.2.10000-1323346';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
