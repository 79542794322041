import React, { useState, useEffect } from 'react';
import clone from 'ramda/src/clone';
import {
    InformationCard,
    InformationCards,
    MuiThemeProvider,
    StyledComponentsThemeProvider,
    StyledEngineProvider
} from '@invision/ui';
import { customer } from '@invision/ui/src/themes';
import { CONVERT_TO_CREDIT } from '../../../../../billingPayments/billing.payments.constants';
import { MISC_ADJUSTMENT } from '../../../../../adjustment/adjustment.modal.constants';
import { MAKE_PAYMENT_TYPES } from '../../../../makePayment/make.payment.constants';
import LocaleKeys from '../../../../../../locales/keys';

export const InvoiceCards = ({
    invoiceData,
    convertToCredit,
    disputeInvoice,
    goToInvoiceDetails,
    makeAdjustment,
    makePayment
}) => {
    const onInvoiceLinkClick = (linkObj) => {
        goToInvoiceDetails(linkObj.id);
    };

    const onMisAdjustmentClick = (linkObj) => {
        makeAdjustment(linkObj);
    };

    const goToMakePayment = (linkObj) => {
        makePayment(linkObj);
    };

    const onDisputeInvoiceClick = (linkObj) => {
        disputeInvoice(linkObj);
    };

    const onConvertToCredit = (linkObj) => {
        convertToCredit(linkObj);
    };

    const [invoiceDataMap, updateInvoiceData] = useState(invoiceData);

    useEffect(() => {
        let cloneInvoiceData = clone(invoiceData);

        cloneInvoiceData = cloneInvoiceData.map((invoice) => {
            const invoiceActions = [];
            if (
                invoice.actions.length &&
                invoice.actions.forEach((action) => {
                    if (action.actionId === CONVERT_TO_CREDIT) {
                        invoiceActions.push({
                            id: action.id,
                            label: action.label,
                            actionId: action.actionId,
                            onClick: onConvertToCredit
                        });
                    }
                    if (action.actionId === MISC_ADJUSTMENT) {
                        invoiceActions.push({
                            id: action.id,
                            label: action.label,
                            actionId: action.actionId,
                            onClick: onMisAdjustmentClick
                        });
                    }
                    if (action.actionId === MAKE_PAYMENT_TYPES.INVOICE) {
                        invoiceActions.push({
                            invoiceNumber: action.id,
                            label: action.label,
                            actionId: action.actionId,
                            onClick: goToMakePayment
                        });
                    }
                    if (action.actionId === LocaleKeys.DISPUTE_INVOICE) {
                        invoiceActions.push({
                            invoiceNumber: action.id,
                            label: action.label,
                            actionId: action.actionId,
                            onClick: onDisputeInvoiceClick
                        });
                    }
                })
            );

            return {
                ...invoice,
                actions: invoiceActions.length ? invoiceActions : undefined,
                link: {
                    label: invoice.link.label,
                    id: invoice.link.id,
                    onClick: onInvoiceLinkClick
                }
            };
        });
        updateInvoiceData(cloneInvoiceData);
    }, [invoiceData]);

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={customer}>
                <StyledComponentsThemeProvider theme={customer}>
                    <InformationCards
                        cardsData={invoiceDataMap}
                        TemplateComponent={InformationCard}
                        keyPath="link.label"
                    />
                </StyledComponentsThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
};
