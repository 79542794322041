import pathOr from 'ramda/src/pathOr';
import isEmpty from 'ramda/src/isEmpty';
import {stateGo, stateReload} from 'redux-ui-router';
import moment from 'moment';
import {
    fetchCodeTypes,
    fetchConsents
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    hasAccess,
    hasAdminAccess
} from 'invision-core/src/components/security/permission.service';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    RouteCurrentStateSelector,
    RouteParams
} from 'invision-core/src/components/router/router.selectors';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {ARMWarningTranslatedMessageSelector} from 'invision-core/src/components/warnings/warnings.selectors';
import {VISIBLE_QUICK_ACTIONS} from 'invision-core/src/components/configurable/customercare';
import {
    INVALID_INVENTORY_REASON_CODES,
    INVALID_SHOPPING_CART_FAULT_CODE,
    HIERARCHY_MOVE_STATUS_TYPES,
    NOTIFICATION_TIME_LENGTH,
    ROOT_CARE_MODULE_ROUTE
} from '../../customercare.constants';
import {CASES_DETAILS_STATE_REDIRECT} from './cases/cases.config';
import CustomerCareLocaleKeys from '../../locales/keys';
import {
    CanUserBypassResetSelector,
    CurrentCustomerNameSelector,
    CurrentCustomerResponsibilityAccountsItemsSelector,
    CurrentCustomerSelector,
    CurrentCustomerIdSelector,
    CustomerIsFetchingDataSelector,
    InvalidInventoryItemSelector,
    IsPIIDataAccessEnabledSelector,
    IsWrappingCustomerSelector,
    OfferingIdSelector,
    OfferingInstanceIdSelector,
    SelectedCustomerConsentsMandatoryUnacceptedSelector,
    SelectedCustomerConsentsOptionalSelector,
    ShoppingCartErrorSelector,
    ShoppingCartSelector,
    ShowAutoPayBannerSelector,
    ShowDetailedTaxationPerformanceBannerSelector,
    StoreOrderErrorSelector,
    StoreOrderSelector,
    StoreOrderTypeSelector
} from '../../reducers/selectors/customer.selectors';
import {
    ConvergentBillerAccountOfferingsSelector,
    CreditLimitBreachedSelector,
    CreditLimitSummarySelector,
    CurrentAccountSummarySelector
} from '../../reducers/selectors/customer.convergent.biller.selectors';
import {
    HierarchyNameSelector,
    ResponsiblePartyCustomerSelector,
    ResponsiblePartyCustomerNameSelector
} from '../../reducers/selectors/customer.account.hierarchy.selectors';
import {
    ChallengeAnswerSelector,
    HasSubscriberPasswordAccessSelector,
    IsFetchingChallengeAnswerSelector
} from '../../reducers/selectors/customer.challenge.selectors';
import {
    CaseTypesSelector,
    FormattedInvoicesSelector
} from '../../reducers/selectors/customer.cases.selectors';
import {
    cancelAnonymizeCustomer,
    cancelStoreOrder,
    clearSavedShoppingCart,
    customerResetDevicePin,
    customerResetPassword,
    customerResetRevokedPassword,
    customerWrapUp,
    fetchShoppingCart,
    fetchStoreOrder,
    resetCustomerRecoverable,
    retrieveCustomer,
    retrieveDevicePinStatus,
    setIsEditingProfile
} from '../../reducers/actions/customer.actions';
import {setNavGroup} from '../customerActionsTemplate/customer.actions.template.actions';
import {retrieveCustomerChallengeAnswer} from '../../reducers/actions/customer.challenge.actions';
import {retrieveHierarchy, searchHierarchyNodes} from '../../reducers/actions/customer.account.hierarchy.actions';
import {
    CASE_ACCESS,
    RESET_SUBSCRIBER_PASSWORD_ACCESS,
    SUBSCRIBER_ADMIN_ACCESS
} from '../../security.attributes';
import {CustomerActionsTemplateSelector} from '../../reducers/selectors/customer.actions.template.selectors';
import {
    CANCEL_STORE_ORDER_QUICK_ACTION,
    CLEAR_SAVED_CART_QUICK_ACTION,
    CUSTOMER_WRAP_UP_QUICK_ACTION,
    DISCONNECT_CUSTOMER_QUICK_ACTION,
    NEW_DBSS_ORDER_QUICK_ACTION,
    NEW_PRODUCT_ORDER_QUICK_ACTION,
    OPEN_CASE_QUICK_ACTION,
    REDEEM_GIFT_ORDER_QUICK_ACTION,
    RESET_DEVICE_PIN_QUICK_ACTION,
    RESET_PASSWORD_QUICK_ACTION,
    RESUME_ORDER_QUICK_ACTION,
    REVIEW_STORE_ORDER_QUICK_ACTION,
    START_NEW_SUBSCRIPTION_ACTION,
    UNREVOKE_LOGIN_QUICK_ACTION,
    VALIDATE_PIN_QUICK_ACTION
} from '../customerActionsTemplate/quick.actions';
import {STATE_OR_NAME as ADD_OFFER_STATE} from '../../reducers/constants/add.offer.wizard.constants';
import {STATE_OR_NAME as ADD_SERVICES_STATE} from '../../reducers/constants/add.services.wizard.constants';
import {
    RECONNECT_OFFER_STATE_NAME,
    STATE_OR_NAME as EDIT_OFFER_STATE
} from '../../reducers/constants/edit.offer.wizard.constants';
import {
    MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME as MANAGE_SERVICE_FEATURE_STATE,
    CREATE_PRODUCTS_ORDER_STATE_NAME as ADD_PRODUCT_STATE,
    CREATE_SUBSCRIPTIONS_ORDER_STATE_NAME as ADD_SUBSCRIPTION_STATE
} from '../../reducers/constants/products.wizard.constants';
import {STORE_ORDER_TYPE} from '../shared/constants/customer.constants';
import {STATE_NAME as TRANSITION_OFFER_STATE} from './orders/transition/transition.offer.wizard.constants';
import {getNameByCountryCode} from './customer.helper';
import {
    setIsResumingStoreOrder,
    setIsResumingOrder
} from '../../reducers/actions/add.offer.wizard.actions';
import {setIsResumingStoreOrderForEditOffer} from '../../reducers/actions/edit.offer.wizard.actions';
import {
    retrieveConvergentBillerSubscriberSummary,
    retrieveConvergentBillerAccountDetails
} from '../../reducers/actions/customer.convergent.biller.actions';
import {retrieveCustomerAccountStatus} from '../../reducers/actions/customer.account.status.actions';
import {CustomerAccountStatusSelector} from '../../reducers/selectors/customer.account.status.selectors';
import {IsResumingStoreOrderSelector} from '../../reducers/selectors/transition.offer.wizard.selectors';
import {PROFILE_EVENTS} from './profile/profile.constants';
import {DISCONNECT_OFFERS_ITV_ROUTE} from './offers/disconnect/itv/disconnect.offers.itv.config';
import {DISCONNECT_CUSTOMER_DBSS_ROUTE} from './offers/disconnect/dbss/disconnect.offers.dbss.config';
import {
    BreachedAccountLevelUsageCapsMessageSelector,
    BreachedUsageCapsMessageSelector
} from '../../reducers/selectors/usage.caps.selectors';
import {ShowServicesWidgetSelector} from '../../reducers/selectors/dashboard.selectors';
import {DASHBOARD_ROUTE} from '../../reducers/constants/dashboard.constants';
import {loadSubscriberRequirements} from '../../reducers/helpers/subscriber.requirements.actions.helper';
import {TRANSFER_STATE_NAME} from './orders/transfer/transfer.wizard.config';
import {CUSTOMER_CATEGORY} from '../createCustomerPopup/create.customer.popup.constants';
import {OrderingRouteConstants} from '@invision/customercare2/src/ordering';
import {NODE_TYPE} from '../../reducers/constants/customer.account.hierarchy.constants';

function CustomerCarePageController($ngRedux, $q, $scope, $state, $filter, $timeout, uiNotificationService) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            breachedAccountLevelUsageCapsMessage: BreachedAccountLevelUsageCapsMessageSelector(store),
            breachedUsageCapsMessage: BreachedUsageCapsMessageSelector(store),
            canUserBypassPasswordReset: CanUserBypassResetSelector(store),
            caseTypes: CaseTypesSelector(store),
            casePriorityCodes: MetadataCodeTypeSelector(CODES.CasePriority, store),
            caseSeverityCodes: MetadataCodeTypeSelector(CODES.CaseSeverity, store),
            challengeAnswer: ChallengeAnswerSelector(store),
            convergentBillerAccountOfferings: ConvergentBillerAccountOfferingsSelector(store),
            currentAccountSummary: CurrentAccountSummarySelector(store),
            currentCustomer: CurrentCustomerSelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            currentCustomerName: CurrentCustomerNameSelector(store),
            currentCustomerAccountStatus: CustomerAccountStatusSelector(store),
            customerActionsTemplate: CustomerActionsTemplateSelector(store),
            creditLimitSummary: CreditLimitSummarySelector(store),
            formattedInvoices: FormattedInvoicesSelector(store),
            hierarchyName: HierarchyNameSelector(store),
            hasSubscriberPasswordAccess: HasSubscriberPasswordAccessSelector(store),
            invalidItemRsvp: InvalidInventoryItemSelector(store),
            isConsentsLoaded: MetadataCodeLoadedSelector(CODES.Consents, store),
            isCreditLimitBreached: CreditLimitBreachedSelector(store),
            isCustomerWrapUpLoaded: MetadataCodeLoadedSelector(CODES.CustomerWrapUp, store),
            isSubscriberSearchLoaded: MetadataCodeLoadedSelector(CODES.SubscriberSearchConfig, store),
            isApiResponseFaultCodeLoaded: MetadataCodeLoadedSelector(CODES.ApiResponseFaultCode, store),
            isDbss: IsDbss(store),
            isFetchingChallengeAnswer: IsFetchingChallengeAnswerSelector(store),
            isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
            isResumingStoreOrder: IsResumingStoreOrderSelector(store),
            isFetchingCustomer: CustomerIsFetchingDataSelector(store),
            isWrappingCustomer: IsWrappingCustomerSelector(store),
            languages: MetadataCodeTypeSelector(CODES.Language, store),
            languagesLoaded: MetadataCodeLoadedSelector(CODES.Language, store),
            offeringId: OfferingIdSelector(store),
            offeringInstanceId: OfferingInstanceIdSelector(store),
            responsibleParty: ResponsiblePartyCustomerSelector(store),
            responsiblePartyName: ResponsiblePartyCustomerNameSelector(store),
            responsibilityAccountsItems: CurrentCustomerResponsibilityAccountsItemsSelector(store),
            routeCurrentState: RouteCurrentStateSelector(store),
            routeParams: RouteParams(store),
            selectedCustomerConsentsOptional: SelectedCustomerConsentsOptionalSelector(store),
            selectedCustomerUnacceptedConsents: SelectedCustomerConsentsMandatoryUnacceptedSelector(store),
            shoppingCart: ShoppingCartSelector(store),
            shoppingCartError: ShoppingCartErrorSelector(store),
            showAutoPayBanner: ShowAutoPayBannerSelector(store),
            showDetailedTaxationPerformanceBanner: ShowDetailedTaxationPerformanceBannerSelector(store),
            showServicesWidget: ShowServicesWidgetSelector(store),
            storeOrder: StoreOrderSelector(store),
            storeOrderError: StoreOrderErrorSelector(store),
            storeOrderType: StoreOrderTypeSelector(store),
            subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
            subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
            userSecurityAttributes: UserSecurityAttributesSelector(store),
            warningTranslatedMessage: ARMWarningTranslatedMessageSelector(store),
        };
    };

    const visibleNavBlocklist = [
        ADD_OFFER_STATE,
        ADD_PRODUCT_STATE,
        ADD_SERVICES_STATE,
        ADD_SUBSCRIPTION_STATE,
        EDIT_OFFER_STATE,
        MANAGE_SERVICE_FEATURE_STATE,
        OrderingRouteConstants.ADD_PLAN_ORDER_PAGE.name,
        OrderingRouteConstants.EDIT_SERVICE_ATTRIBUTES_PAGE.name,
        OrderingRouteConstants.REMOVE_PLAN_ORDER_PAGE.name,
        OrderingRouteConstants.REMOVE_SERVICES_ORDER_PAGE.name,
        OrderingRouteConstants.SUSPEND_RESUME_SERVICES_ORDER_PAGE.name,
        OrderingRouteConstants.SWAP_PLAN_ORDER_PAGE.name,
        OrderingRouteConstants.UPDATE_CHARGE_AMOUNT_PAGE.name,
        OrderingRouteConstants.UPDATE_OFFERING_ADDITIONAL_PROPERTIES_PAGE.name,
        RECONNECT_OFFER_STATE_NAME,
        TRANSFER_STATE_NAME,
        TRANSITION_OFFER_STATE,
        'index.customercare.customer.subscriptions.detail.replace',
        'index.customercare.customer.subscriptions.detail.restore'
    ];

    this.$onInit = () => {
        const controllerActions = {
            cancelAnonymizeCustomer,
            cancelStoreOrder,
            clearSavedShoppingCart,
            customerResetDevicePin,
            customerResetPassword,
            customerResetRevokedPassword,
            customerWrapUp,
            fetchCodeType: fetchCodeTypes,
            fetchShoppingCart,
            fetchStoreOrder,
            getConsents: fetchConsents,
            loadSubscriberRequirements,
            retrieveCustomer,
            resetCustomerRecoverable,
            retrieveConvergentBillerSubscriberSummary,
            retrieveConvergentBillerAccountDetails,
            retrieveCustomerAccountStatus,
            retrieveCustomerChallengeAnswer,
            retrieveDevicePinStatus,
            retrieveHierarchy,
            searchHierarchyNodes,
            setIsResumingOrder,
            setIsResumingStoreOrder,
            setNavGroup,
            stateGo,
            stateReload,
            setIsEditingProfile,
            setIsResumingStoreOrderForEditOffer,
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.CoreLocaleKeys = CoreLocaleKeys;
        this.anonymizationNotificationExpanded = false;
        this.anonymizationExpanderLabel = i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.EXPAND_LABEL);
        this.resetPassword = true;
        this.visibleQuickActions = VISIBLE_QUICK_ACTIONS;
        this.showCustomerWrapUp = false;
        this.isStoreOrderError = false;

        this.actions.loadSubscriberRequirements().then(() => {
            this.requirements = this.state.currentCustomer.Category === CUSTOMER_CATEGORY.RESIDENTIAL ? this.state.subscriberRequirements : this.state.subscriberCategoryRequirements;
            this.isDataLoaded = true;
        });

        if (this.state.currentCustomer.ScheduledAnonymization) {
            const anonymizationMoment = moment(this.state.currentCustomer.ScheduledAnonymization, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
            const anonymizationDate = $filter('localShort')(anonymizationMoment);
            this.anonymizationMessage = i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.MESSAGE, {
                anonymizationDate
            });
            this.expandedAnonymizationMessage = i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.EXPANDED_MESSAGE, {
                anonymizationDate
            });
        }
        if (this.state.currentCustomer.ConvergentBillerId && !this.state.isFetchingAccountSummary) {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId, true);
        }
        if (!this.state.isConsentsLoaded) {
            this.actions.getConsents();
        }
        if (!this.state.isSubscriberSearchLoaded) {
            this.actions.fetchCodeType(CODES.SubscriberSearchConfig);
        }
        if (!this.state.isApiResponseFaultCodeLoaded) {
            this.actions.fetchCodeType(CODES.ApiResponseFaultCode);
        }
        this.fetchShoppingCart();

        if (this.state.isDbss) {
            this.fetchStoreOrder();

            if (!this.state.convergentBillerAccountOfferings?.length) {
                this.actions.retrieveConvergentBillerAccountDetails(this.state.currentCustomerId, true).catch((error) => {
                    uiNotificationService.transientError(error.translatedMessage);
                });
            }
        }

        this.identifierAbbreviationLabel = i18n.translate(CustomerCareLocaleKeys.IDENTIFIER_ABBREVIATION);
        this.quickInfoPopup = {
            onRegisterApi: (evt) => {
                this.quickInfoPopupApi = evt.api;
            }
        };

        this.openQuickInfoPopup = () => {
            this.actions.retrieveCustomerAccountStatus(this.state.currentCustomer.Id).then(() => {
                this.quickInfoPopupApi.open();
            });
        };

        this.closeQuickInfoPopup = () => {
            this.quickInfoPopupApi.close();
        };

        this.showDiscountsModal = false;
        this.discretionaryDiscountsConfig = {
            onRegisterApi: ({api: api}) => {
                this.discretionaryDiscountsPopupApi = api;
            }
        };

        this.openDiscretionaryDiscountsPopup = () => {
            this.showDiscountsModal = true;
            this.discretionaryDiscountsPopupApi.open();
        };

        this.closeDiscretionaryDiscountsPopup = () => {
            this.showDiscountsModal = false;
            this.discretionaryDiscountsPopupApi.close();
        };

        this.retrieveCustomerChallengeAnswer = () => {
            this.actions.retrieveCustomerChallengeAnswer(this.state.currentCustomer.Id);
        };

        this.caseModalProps = {
            isOpen: false,
            onClose: () => {
                this.caseModalProps.isOpen = false;
            },
            onSubmit: (caseDetails) => {
                this.caseModalProps.isOpen = false;
                uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.CASE.CREATED_SUCCESSFULLY));

                this.actions.stateGo(CASES_DETAILS_STATE_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    caseId: caseDetails.Id.Value
                });
            },
            openCaseModal: () => {
                this.caseModalProps.isOpen = true;
            },
            routeParams: {
                customerId: this.state.currentCustomerId
            }
        };

        if (this.state.currentCustomer.HierarchyId) {
            this.actions.retrieveHierarchy(this.state.currentCustomer.HierarchyId).then(() => {
                if (this.state.currentCustomer.HierarchyParentSubscriberId) {
                    this.actions.searchHierarchyNodes(this.state.currentCustomer.Id, {
                        NodeTypes: [NODE_TYPE.REGULAR, NODE_TYPE.INHERITED],
                        ParentSubscriberId: this.state.currentCustomer.HierarchyParentSubscriberId
                    });
                }
            });
        }

        if (!this.state.languagesLoaded) {
            this.actions.fetchCodeType(CODES.Language);
        }

        this.cancelAnonymizationPopupConfig = {
            onRegisterApi: (event) => {
                this.cancelAnonymizationPopupApi = event.api;
            }
        };

        this.handleResultCancelAnonymizationPopup = (proceed) => {
            if (proceed) {
                this.cancelAnonymization();
            } else {
                this.handleCloseAnonymizationPopup();
            }
        };

        this.handleCloseAnonymizationPopup = () => {
            this.cancelAnonymizationPopupApi.close();
        };

        this.cancelAnonymizationPopupConfig = {
            onRegisterApi: (event) => {
                this.cancelAnonymizationPopupApi = event.api;
            }
        };

        this.handleResultCancelAnonymizationPopup = (proceed) => {
            if (proceed) {
                this.cancelAnonymization();
            } else {
                this.handleCloseAnonymizationPopup();
            }
        };

        this.handleCloseAnonymizationPopup = () => {
            this.cancelAnonymizationPopupApi.close();
        };

        this.customerWrapUpPopup = {
            onRegisterApi: ({api: api}) => {
                this.customerWrapUpPopupApi = api;
            }
        };

        this.cancelStoreOrderConfirmationPopupConfig = {
            onRegisterApi: ({api}) => {
                this.cancelStoreOrderConfirmationPopupApi = api;
            }
        };

        this.clearSavedCartConfirmationPopupConfig = {
            onRegisterApi: ({api}) => {
                this.clearSavedCartConfirmationPopupApi = api;
            }
        };

        this.customerPinPopupConfig = {
            onRegisterApi: ({api}) => {
                this.customerPinPopupApi = api;
            }
        };
        this.handleAngularToReactActionLoads(this.state.routeParams);
    };

    this.openCustomerPinPopup = () => {
        this.showCustomerPinPopup = true;
        $timeout(() => {
            this.customerPinPopupApi.open();
        });
    };

    this.closeCustomerPinPopup = () => {
        this.showCustomerPinPopup = false;
        this.customerPinPopupApi.close();
    };

    this.$onDestroy = () => {
        disconnectRedux();
        this.quickInfoPopupApi.close();
        this.actions.resetCustomerRecoverable();
    };

    this.showAutoPayBanner = () => {
        return this.state.showAutoPayBanner && this.state.currentAccountSummary
            && this.state.currentAccountSummary.AccountNumber && this.state.currentAccountSummary.AutoPayConfigured;
    };

    this.showBreachedUsageCapsBanner = () => {
        return this.state.routeCurrentState.name === DASHBOARD_ROUTE && this.state.showServicesWidget && this.state.breachedUsageCapsMessage.length;
    };

    this.showBreachedUsageAccountCapsBanner = () => {
        return this.state.routeCurrentState.name === DASHBOARD_ROUTE && this.state.showServicesWidget && this.state.breachedAccountLevelUsageCapsMessage.length;
    };

    this.showBreachedCreditCheckBanner = () => {
        if (this.state.creditLimitSummary) {
            if (this.state.creditLimitSummary.CreditLimitBreached && this.state.creditLimitSummary.RemainingCreditAmount < 0) {
                this.creditLimitBreachErrorMessage = i18n.translate(CustomerCareLocaleKeys.CREDIT_LIMIT_BREACHED, {
                    breachAmount: $filter('invCurrency')(this.state.creditLimitSummary.RemainingCreditAmount, this.state.currentCustomer.SubscriberCurrency)
                });
            } else if (this.state.creditLimitSummary.CreditLimitBreached && !(this.state.creditLimitSummary.RemainingCreditAmount < 0)) {
                this.creditLimitBreachErrorMessage = i18n.translate(CustomerCareLocaleKeys.CREDIT_LIMIT_BREACHED_NO_AMOUNT);
            }
        }
        return this.state.routeCurrentState.name === DASHBOARD_ROUTE && !isEmpty(this.state.creditLimitSummary) && this.state.isCreditLimitBreached && this.state.currentCustomer.SubscriberCurrency;
    };

    this.showBillCycleChangeInProgressBanner = () => {
        if (this.state.currentCustomer.SubscriberTypeDetails?.BillCycleChangeInProgress) {
            this.billCycleChangeInProgressWarningMessage = i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.BILL_CYCLE_CHANGE_IN_PROGRESS_WARNING_MESSAGE);
            return true;
        }
        return false;
    };

    this.showHierarchyMoveInProgressBanner = () => {
        if (this.state.currentCustomer.SubscriberTypeDetails?.HierarchyMoveStatus === HIERARCHY_MOVE_STATUS_TYPES.AcrossHierarchies) {
            this.hierarchyMoveInProgressWarningMessage = i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.HIERARCHY_MOVE_IN_PROGRESS_WARNING_MESSAGE);
            return true;
        }
        return false;
    };

    this.toggleAnonymizationMessage = () => {
        this.anonymizationNotificationExpanded = !this.anonymizationNotificationExpanded;
        this.anonymizationExpanderLabel = this.anonymizationNotificationExpanded ? i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.COLLAPSE_LABEL) : i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.EXPAND_LABEL);
    };

    this.handleToggleNavigatorCollapsedSelected = () => {
        this.actions.toggleCollapsedSelected();
    };

    this.canUserCancelAnonymization = () => {
        return hasAdminAccess(this.state.userSecurityAttributes, SUBSCRIBER_ADMIN_ACCESS);
    };

    this.showCancelAnonymization = () => {
        this.cancelAnonymizationPopupApi.open();
    };

    this.openClearSavedCartConfirmationPopup = () => {
        this.clearSavedCartConfirmationPopupApi.open();
    };

    this.closeClearSavedCartConfirmationPopup = () => {
        this.clearSavedCartConfirmationPopupApi.close();
    };

    this.openCancelStoreOrderConfirmationPopup = () => {
        this.cancelStoreOrderConfirmationPopupApi.open();
    };

    this.closeCancelStoreOrderConfirmationPopup = () => {
        this.isStoreOrderError = false;
        this.cancelStoreOrderConfirmationPopupApi.close();
    };

    this.cancelStoreOrder = (confirm) => {
        if (confirm) {
            this.actions.cancelStoreOrder(this.state.currentCustomerId)
                .then(() => {
                    if (this.state.isResumingStoreOrder) {
                        this.actions.setIsResumingStoreOrder(false);
                    }
                    uiNotificationService.transientSuccess(
                        i18n.translate(CustomerCareLocaleKeys.CANCEL_STORE_ORDER_CONFIRMATION_POPUP.SUCCESS_MESSAGE));
                })
                .catch((error) => {
                    uiNotificationService.transientError(error.translatedMessage);
                });
        }
        this.closeCancelStoreOrderConfirmationPopup();
    };

    this.clearSavedCart = (confirm) => {
        if (confirm) {
            this.actions.clearSavedShoppingCart(this.state.currentCustomerId)
                .then(() => {
                    uiNotificationService.transientSuccess(
                        i18n.translate(CustomerCareLocaleKeys.CLEAR_SAVED_CART_CONFIRMATION_POPUP.SUCCESS_MESSAGE));
                })
                .catch((error) => {
                    uiNotificationService.transientError(error.translatedMessage);
                })
                .finally(() => {
                    this.closeClearSavedCartConfirmationPopup();
                });
        } else {
            this.closeClearSavedCartConfirmationPopup();
        }
    };

    this.cancelAnonymization = () => {
        this.handleCloseAnonymizationPopup();

        this.actions.cancelAnonymizeCustomer(this.state.currentCustomer.Id).then(() => {
            uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.CANCEL_ANONYMIZATION_SUCCESS), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });

            this.actions.stateReload();
        }).catch(() => {
            uiNotificationService.error(
                i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.CANCEL_ANONYMIZATION_FAILURE),
                i18n.translate(CustomerCareLocaleKeys.ANONYMIZATION.CANCEL_ANONYMIZATION_FAILURE_TITLE),
                {
                    timeOut: NOTIFICATION_TIME_LENGTH
                }
            );
        });
    };

    this.onQuickActionSelected = ({id, isLocked}) => {
        if (!isLocked) {
            switch (id) {
                case CANCEL_STORE_ORDER_QUICK_ACTION.id: {
                    this.openCancelStoreOrderConfirmationPopup();
                    break;
                }
                case CLEAR_SAVED_CART_QUICK_ACTION.id: {
                    this.openClearSavedCartConfirmationPopup();
                    break;
                }
                case NEW_DBSS_ORDER_QUICK_ACTION.id: {
                    this.actions.stateGo(ADD_OFFER_STATE, {
                        customerId: this.state.currentCustomerId
                    });
                    break;
                }
                case NEW_PRODUCT_ORDER_QUICK_ACTION.id: {
                    this.actions.stateGo(ADD_PRODUCT_STATE, {
                        productsOnly: true
                    });
                    break;
                }
                case OPEN_CASE_QUICK_ACTION.id: {
                    this.caseModalProps.openCaseModal();
                    break;
                }
                case REDEEM_GIFT_ORDER_QUICK_ACTION.id: {
                    this.actions.stateGo('index.customercare.customer.redeemGiftOrder');
                    break;
                }
                case RESET_DEVICE_PIN_QUICK_ACTION.id: {
                    this.openResetDevicePinDialog();
                    break;
                }
                case RESET_PASSWORD_QUICK_ACTION.id: {
                    this.resetRevokedPasswordPopUp.open();
                    break;
                }
                case UNREVOKE_LOGIN_QUICK_ACTION.id: {
                    this.resetRevokedLoginPopUp.open();
                    break;
                }
                case DISCONNECT_CUSTOMER_QUICK_ACTION.id: {
                    if (this.state.isDbss) {
                        this.actions.stateGo(DISCONNECT_CUSTOMER_DBSS_ROUTE);
                    } else {
                        this.actions.stateGo(DISCONNECT_OFFERS_ITV_ROUTE);
                    }
                    break;
                }
                case RESUME_ORDER_QUICK_ACTION.id: {
                    this.resumeOrder();
                    break;
                }
                case REVIEW_STORE_ORDER_QUICK_ACTION.id: {
                    if (this.state.storeOrderError.code === INVALID_SHOPPING_CART_FAULT_CODE) {
                        this.isStoreOrderError = true;
                        this.openCancelStoreOrderConfirmationPopup();
                    } else if (this.state.storeOrderType === STORE_ORDER_TYPE.ADD) {
                        this.actions.setIsResumingStoreOrder(true);
                        this.actions.stateGo(ADD_OFFER_STATE, {
                            customerId: this.state.currentCustomerId
                        });
                    } else if (this.state.storeOrderType === STORE_ORDER_TYPE.MODIFY) {
                        this.actions.setIsResumingStoreOrderForEditOffer(true);
                        this.actions.stateGo(EDIT_OFFER_STATE, {
                            customerId: this.state.currentCustomerId,
                            offeringId: this.state.offeringId,
                            offeringInstanceId: this.state.offeringInstanceId
                        });
                    } else if (this.state.storeOrderType === STORE_ORDER_TYPE.TRANSITION) {
                        this.actions.setIsResumingStoreOrder(true);
                        this.actions.stateGo(TRANSITION_OFFER_STATE, {
                            offeringId: this.state.storeOrder.SourceTransitionOfferingId,
                            offeringInstanceId: this.state.storeOrder.SourceTransitionOfferingInstanceId
                        });
                    }
                    break;
                }
                case START_NEW_SUBSCRIPTION_ACTION.id: {
                    this.actions.stateGo(ADD_SUBSCRIPTION_STATE);
                    break;
                }
                case CUSTOMER_WRAP_UP_QUICK_ACTION.id: {
                    this.openCustomerWrapUpPopup();
                    break;
                }
                case VALIDATE_PIN_QUICK_ACTION.id: {
                    this.openCustomerPinPopup();
                    break;
                }
                default:
                    break;
            }
        }
    };

    /* To be cleaned up later, once we have ordering flows ported to React. */
    this.handleAngularToReactActionLoads = (params) => {
        const ReactToAngularActions = {
            ReviewSavedCart : 'reviewSavedCart',
            ReviewStoreOrder : 'reviewStoreOrder'
        };

        if (params.action) {
            switch (params.action) {
                case ReactToAngularActions.ReviewSavedCart:
                    this.resumeOrder();
                case ReactToAngularActions.ReviewStoreOrder:
                    this.reviewStoreOrder(+params.storeOrderType);
                default:
                    return;
            }
        }
    };

    this.resumeOrder = () => {
        if ((this.state.shoppingCartError !== null && this.state.invalidItemRsvp === undefined)) {
            this.actions.setIsResumingOrder(true);
            this.resumeCartErrorPopup.open();
        } else if (this.state.invalidItemRsvp && this.state.invalidItemRsvp.length > 0) {
            this.invalidInventoryErrorPopUpConfig.open();
        } else {
            this.actions.setIsResumingOrder(true);
            this.actions.stateGo(ADD_OFFER_STATE, {
                customerId: this.state.currentCustomerId
            });
        }
    };

    this.reviewStoreOrder = (orderType) => {
        if (orderType === STORE_ORDER_TYPE.ADD) {
            this.actions.setIsResumingStoreOrder(true);
            this.actions.stateGo(ADD_OFFER_STATE, {
                customerId: this.state.currentCustomerId
            });
        } else if (orderType === STORE_ORDER_TYPE.MODIFY) {
            this.actions.setIsResumingStoreOrderForEditOffer(true);
            this.actions.stateGo(EDIT_OFFER_STATE, {
                customerId: this.state.currentCustomerId,
                offeringId: this.state.offeringId,
                offeringInstanceId: this.state.offeringInstanceId
            });
        } else if (orderType === STORE_ORDER_TYPE.TRANSITION) {
            this.actions.setIsResumingStoreOrder(true);
            this.actions.stateGo(TRANSITION_OFFER_STATE, {
                offeringId: this.state.storeOrder.SourceTransitionOfferingId,
                offeringInstanceId: this.state.storeOrder
                    .SourceTransitionOfferingInstanceId
            });
        } else {
            this.isStoreOrderError = true;
            this.openCancelStoreOrderConfirmationPopup();
        }
    };

    this.fetchShoppingCart = () => {
        return this.actions.fetchShoppingCart(this.state.currentCustomer.Id);
    };

    this.fetchStoreOrder = () => {
        return this.actions.fetchStoreOrder(this.state.currentCustomer.Id);
    };

    this.fetchDevicePinStatus = () => {
        return this.actions.retrieveDevicePinStatus(this.state.currentCustomer.Id);
    };


    this.isNavVisible = () => {
        return this.state && (!this.state.routeCurrentState || !visibleNavBlocklist.includes(this.state.routeCurrentState.name));
    };

    this.resetPasswordPopUp = {
        onRegisterApi: (evt) => {
            this.resetPasswordPopUp = evt.api;
        }
    };

    this.resetRevokedPasswordPopUp = {
        onRegisterApi: (evt) => {
            this.resetRevokedPasswordPopUp = evt.api;
        }
    };

    this.resetRevokedLoginPopUp = {
        onRegisterApi: (evt) => {
            this.resetRevokedLoginPopUp = evt.api;
        }
    };

    this.openResetPasswordDialog = () => {
        this.resetPasswordPopUp.open();
    };

    this.openResetRevokedPasswordDialog = () => {
        if (!this.state.canUserBypassPasswordReset) {
            this.resetRevokedPasswordPopUp.open();
        } else {
            this.resetRevokedLoginPopUp.open();
        }
    };

    this.closeResetPasswordDialog = () => {
        this.resetPasswordPopUp.close();
    };

    this.closeResetRevokedPasswordDialog = () => {
        this.resetRevokedPasswordPopUp.close();
    };

    this.closeResetRevokedLoginDialog = () => {
        this.resetRevokedLoginPopUp.close();
    };

    this.resetDevicePinPopUp = {
        onRegisterApi: (evt) => {
            this.resetDevicePinPopUp = evt.api;
        }
    };

    this.openResetDevicePinDialog = () => {
        this.resetDevicePinPopUp.open();
    };

    this.closeResetDevicePinDialog = () => {
        this.resetDevicePinPopUp.close();
    };

    this.invalidInventoryErrorPopUpConfig = {
        onRegisterApi: (evt) => {
            this.invalidInventoryErrorPopUpConfig = evt.api;
        }
    };

    this.resumeCartErrorPopup = {
        onRegisterApi: (evt) => {
            this.resumeCartErrorPopup = evt.api;
        }
    };

    this.getReasonCode = (code) => {
        switch (code) {
            case INVALID_INVENTORY_REASON_CODES.NOT_AVAILABLE:
                return CustomerCareLocaleKeys.RESUME_SAVED_ORDER_FAILURE.NOT_AVAILABLE;
            case INVALID_INVENTORY_REASON_CODES.NOT_FOUND:
                return CustomerCareLocaleKeys.RESUME_SAVED_ORDER_FAILURE.NOT_FOUND;
            case INVALID_INVENTORY_REASON_CODES.NOT_VALID:
                return CustomerCareLocaleKeys.RESUME_SAVED_ORDER_FAILURE.NOT_VALID;
            default:
                return;
        }
    };

    this.getFriendlyName = (countryCode) => {
        return getNameByCountryCode(this.state.languages, countryCode);
    };

    this.continueToDecisionOffer = () => {
        this.invalidInventoryErrorPopUpConfig.close();
        this.actions.setIsResumingOrder(true);
        this.actions.stateGo(ADD_OFFER_STATE, {
            customerId: this.state.currentCustomerId
        });
    };
    this.continueToAddOffer = () => {
        this.resumeCartErrorPopup.close();
        this.actions.stateGo(ADD_OFFER_STATE, {
            customerId: this.state.currentCustomerId
        });
    };

    this.onGroupNavSelected = (navItem) => {
        this.actions.setNavGroup(navItem);
    };

    this.resetSubscriberPassword = (result) => {
        // Double-check the permissions to avoid any scripting hacks
        if (result && hasAccess(this.state.userSecurityAttributes, RESET_SUBSCRIBER_PASSWORD_ACCESS)) {
            if (this.state.currentCustomer.LoginRevoked) {
                this.resetRevokedPassword();
            } else {
                this.actions.customerResetPassword(this.state.currentCustomer.Login).then(() => {
                    uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.RESET_PASSWORD_SUCCESS), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                })
                    .catch(() => {
                        uiNotificationService.error(i18n.translate(CustomerCareLocaleKeys.RESET_PASSWORD_FAILURE), null, {
                            timeOut: NOTIFICATION_TIME_LENGTH
                        });
                    });
            }
        }

        this.resetPasswordPopUp.close();
    };

    this.resetRevokedSubscriberPassword = (result) => {
        // Double-check the permissions to avoid any scripting hacks
        if (result && hasAccess(this.state.userSecurityAttributes, RESET_SUBSCRIBER_PASSWORD_ACCESS)) {
            this.resetRevokedPassword(this.state.canUserBypassPasswordReset && !this.resetPassword);
        }

        this.closeResetRevokedPasswordDialog();
        this.closeResetRevokedLoginDialog();
    };

    this.resetSubscriberDevicePin = (result) => {
        if (result && hasAccess(this.state.userSecurityAttributes, SUBSCRIBER_ADMIN_ACCESS)) {
            this.actions.customerResetDevicePin(this.state.currentCustomer.Id)
                .then(() => {
                    uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.RESET_DEVICE_PIN_SUCCESS), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                    return this.fetchDevicePinStatus();
                }).then(this.actions.retrieveCustomerAccountStatus)
                .catch(() => {
                    uiNotificationService.error(i18n.translate(CustomerCareLocaleKeys.RESET_DEVICE_PIN_FAILURE), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                });
        }

        this.resetDevicePinPopUp.close();
    };

    this.handleClickResponsiblePartyButton = () => {
        this.actions.stateGo('index.customercare.customer', {
            customerId: this.state.responsibleParty.Id
        });
    };

    this.handleSelectResponsibilityAccount = (responsibilityAccount) => {
        this.actions.stateGo('index.customercare.customer', {
            customerId: responsibilityAccount.customerId
        });
    };

    this.shouldUseFirstAndLastName = () => {
        return this.state.currentCustomer.FirstName && this.state.currentCustomer.LastName;
    };

    this.primaryIdentifierIsExternalRef = () => {
        return !this.shouldUseFirstAndLastName() &&
            this.state.currentCustomer.ExternalReference &&
            this.state.currentCustomer.ExternalReference === this.state.currentCustomerName;
    };

    this.primaryIdentifierIsEmail = () => {
        return !this.shouldUseFirstAndLastName() &&
            this.state.currentCustomer.Email &&
            this.state.currentCustomer.Email === this.state.currentCustomerName;
    };

    this.resetRevokedPassword = (bypassResetPassword) => {
        this.actions.customerResetRevokedPassword(this.state.currentCustomer.Id, bypassResetPassword)
            .then(() => {
                uiNotificationService.success(i18n.translate(bypassResetPassword ? CustomerCareLocaleKeys.RESET_REVOKE_SUCCESS : CustomerCareLocaleKeys.RESET_PASSWORD_SUCCESS), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                return this.actions.retrieveCustomer(this.state.currentCustomer.Id);
            })
            .then(() => {
                return this.actions.retrieveCustomerAccountStatus(this.state.currentCustomer.Id);
            })
            .catch(() => {
                uiNotificationService.error(i18n.translate(bypassResetPassword ? CustomerCareLocaleKeys.RESET_REVOKE_FAILURE : CustomerCareLocaleKeys.RESET_PASSWORD_FAILURE), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };

    this.isVisibleMandatoryConsentNotification = () => {
        return this.state && (this.state.selectedCustomerUnacceptedConsents.length > 0);
    };

    this.openEditCustomerModal = () => {
        const profilePageStateName = 'index.customercare.customer.profile';
        if (profilePageStateName === $state.current.name) {
            $scope.$broadcast(PROFILE_EVENTS.OPEN_EDIT_CUSTOMER_MODAL);
        } else {
            $state.go(profilePageStateName, {
                'customerId': this.state.currentCustomerId,
                'openEditCustomerModal': true
            });
        }
    };

    this.openCustomerWrapUpPopup = () => {
        this.showCustomerWrapUp = true;
        $timeout(this.customerWrapUpPopupApi.open);
    };

    this.closeCustomerWrapupModal = () => {
        this.showCustomerWrapUp = false;
        this.customerWrapUpPopupApi.close();
    };

    this.onCustomerWrapUp = () => {
        this.isWrapupValid
        && this.actions.customerWrapUp({
            customerId: this.state.currentCustomerId,
            reasonId: this.wrapupReason,
            comments: this.wrapupComments
        })
            .then(() => {
                this.closeCustomerWrapupModal();
                this.actions.stateGo(ROOT_CARE_MODULE_ROUTE);
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    };

    this.onWrapupUpdate = (formModel) => {
        const wrapupReasonCode = pathOr(undefined, ['reason', 'Value'], formModel);
        this.isWrapupValid = !!wrapupReasonCode;
        this.wrapupReason = wrapupReasonCode;
        this.wrapupComments = formModel.comments;
    };

    this.closePinValidationModal = (result) => {
        this.closeCustomerPinPopup();
        if (result) {
            uiNotificationService.transientSuccess(i18n.translate(CustomerCareLocaleKeys.PROFILE_PAGE.PIN_VALIDATION_SUCCESS));
        }
    };

}

export default {
    template: require('./customer.html'),
    controller: CustomerCarePageController,
    controllerAs: 'customer'
};
