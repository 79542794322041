import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {formHasErrors} from 'invision-core/src/components/helpers/form.helper';
import {
    fetchCodeTypes,
    fetchCreditCardTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    EMAIL_ADDRESSES,
    NUMBERS_ONLY_OR_BLANK
} from 'invision-core/src/constants/validation.constants';
import {AdditionalPropertyValueType} from 'invision-core/src/components/metadata/codes/codes.additional.properties.constants';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {REGEXES} from '../../../customercare.constants';
import {
    FILTER_PAYMENT_INSTRUMENT_TYPE,
    getSearchFieldsTemplate,
    SEARCH_DROPDOWN_CONSTANTS,
    SEARCH_PAYMENT_INSTRUMENT_TYPES
} from '../search.constants';
import {
    getCustomerSearch,
    searchSubscriberSuggestions
} from '../../../reducers/actions/search.customer.actions';
import {getCouponSearch} from '../../../reducers/actions/search.coupon.actions';
import {getGiftCardSearch} from '../../../reducers/actions/search.giftcard.actions';
import {
    clearSearchError,
    setSearchType
} from '../../../reducers/actions/search.actions';
import {
    CustomerSearchRecordCountSelector,
    CustomerSearchSuggestionsIsLoadingSelector,
    CustomerSearchSuggestionsSelector,
} from '../../../reducers/selectors/search.customer.selectors';
import {
    IsPredictiveSearchEnabledSelector,
    SearchApiErrorSelector,
    SearchIsFetchingResultsSelector,
    SearchTypeSelector,
    SearchOptionsSelector
} from '../../../reducers/selectors/search.selectors';
import {setEditApOnChange} from '../../../reducers/actions/customer.actions';
import {GiftCardSearchRecordCountSelector} from '../../../reducers/selectors/search.giftcard.selectors';
import {CouponSearchRecordCountSelector} from '../../../reducers/selectors/search.coupon.selectors';
import {IsPIIDataAccessEnabledSelector, SelectedCustomerCategorySelector} from '../../../reducers/selectors/customer.selectors';
import {CUSTOMER_CATEGORY} from '../../createCustomerPopup/create.customer.popup.constants';
import {SubscriberIdentificationsSelector} from '../../createCustomerPopup/customer.form.dropdown.selectors';
import {
    SubscriberAdditionalPropertiesSelector
} from '../../createCustomer/additionalPropertiesSection/additional.properties.filtered.selector';

export function SearchFieldsController($ngRedux, $element, $timeout) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            apiErrors: SearchApiErrorSelector(store),
            couponSearchResultCount: CouponSearchRecordCountSelector(store),
            creditCardTypes: MetadataCodeTypeSelector(CODES.CreditCardType, store),
            customerCategory: SelectedCustomerCategorySelector(store),
            customerSearchResultCount: CustomerSearchRecordCountSelector(store),
            customerSearchSuggestions: CustomerSearchSuggestionsSelector(store),
            customerSearchSuggestionsIsLoading: CustomerSearchSuggestionsIsLoadingSelector(store),
            externalBillTypes: MetadataCodeTypeSelector(CODES.ExternalBillType, store),
            externalBillTypesLoaded: MetadataCodeLoadedSelector(CODES.ExternalBillType, store),
            giftCardSearchResultCount: GiftCardSearchRecordCountSelector(store),
            isDbss: IsDbss(store),
            isFetchingResults: SearchIsFetchingResultsSelector(store),
            isPredictiveSearchEnabled: IsPredictiveSearchEnabledSelector(store),
            isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
            isTemplateLoaded: MetadataCodeLoadedSelector(CODES.BusinessUnitTemplate, store),
            pageSize: PageSizePreferenceSelector(store),
            paymentInstrumentTypes: MetadataCodeTypeSelector(CODES.PaymentInstrumentType, store),
            paymentInstrumentTypesLoaded: MetadataCodeLoadedSelector(CODES.PaymentInstrumentType, store),
            personTitleOptions: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.PersonTitle, store),
            searchOptions: SearchOptionsSelector(store),
            searchType: SearchTypeSelector(store),
            subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
            subscriberIdentifications: SubscriberIdentificationsSelector(store),
            subscriberIdentificationsLoaded: MetadataCodeLoadedSelector(CODES.SubscriberIdentifications, store),
            subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
            subscriberSearchConfigLoaded: MetadataCodeLoadedSelector(CODES.SubscriberSearchConfig, store),
            subscriberAdditionalPropData: SubscriberAdditionalPropertiesSelector(store)
        };
    };

    this.$onInit = () => {
        Object.assign(this, {
            $timeout,
            accountNumberRegEx: REGEXES.NUMBERS_ONLY_OR_BLANK,
            canShowNoResultsError: false,
            coreLocaleKeys: CoreLocaleKeys,
            customerCareLocaleKeys: CustomerCareLocaleKeys,
            customerFormSearchModel: angular.copy(this.customerSearchModel),
            EMAIL_ADDRESSES,
            emailRegEx: REGEXES.EMAIL,
            identificationDocuments:[],
            NUMBERS_ONLY_OR_BLANK,
            onAdditionalPropertyChange: this.onAdditionalPropertyChange.bind(this),
            orderIdRegEx: REGEXES.NUMBERS_ONLY_OR_BLANK,
            purchaseReceiptReferenceLabel: '',
            SEARCH_PAYMENT_INSTRUMENT_TYPES,
            searchDropdownConstants: SEARCH_DROPDOWN_CONSTANTS,
            singeSelectDropDownWidth: '43%',
            subscriberIdRegEx: REGEXES.NUMBERS_ONLY_OR_BLANK
        });

        this.clearAllErrors();

        const controllerActions = {
            clearSearchError,
            fetchCodeTypes,
            fetchCreditCardTypes,
            getCouponSearch,
            getCustomerSearch,
            getGiftCardSearch,
            searchSubscriberSuggestions,
            setEditApOnChange,
            setSearchType
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        // Expose inner function to the parent
        if (this.searchFieldsApi) {
            this.searchFieldsApi()({
                clearSearch: () => {
                    this.clearSearch();
                }
            });
        }

        if (!this.state.externalBillTypesLoaded) {
            this.actions.fetchCodeTypes(CODES.ExternalBillType);
        }

        if (!this.state.paymentInstrumentTypesLoaded) {
            this.actions.fetchCodeTypes(CODES.PaymentInstrumentType);
        }

        if (!this.state.subscriberSearchConfigLoaded) {
            this.actions.fetchCodeTypes(CODES.SubscriberSearchConfig);
        }

        if (!this.state.isTemplateLoaded) {
            this.actions.fetchCodeTypes(CODES.BusinessUnitTemplate);
        }

        if (this.state.creditCardTypes.length === 0) {
            this.actions.fetchCreditCardTypes();
        }

        if (!this.state.subscriberIdentificationsLoaded) {
            this.actions.fetchCodeTypes(CODES.SubscriberIdentifications).then(() => {
                this.formatSubscriberIdentificationType();
            });
        } else {
            this.formatSubscriberIdentificationType();
        }
        this.errorLookup = this.getErrorLookupObject();
        this.selectedIdentificationEntity = this.customerFormSearchModel.subscriberIdentification ?
            this.customerFormSearchModel.subscriberIdentification.Value:
            null;
        if (this.customerFormSearchModel.subscriberIdentification && this.customerFormSearchModel.subscriberIdentification.Type) {
            this.identificationDocuments = this.identificationDocuments.map((identityDocument) => {
                if (identityDocument.id === this.customerFormSearchModel.subscriberIdentification.Type) {
                    identityDocument.selected = true;
                } else {
                    identityDocument.selected = false;
                }
                return identityDocument;
            });
        }
        this.clearCreditCardNumberPartial();
        this.clearAllErrors = this.clearAllErrors.bind(this);
    };

    this.formatSubscriberIdentificationType = () => {
        this.identificationDocuments = [{
            id: null,
            text: i18n.translate(CustomerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.SINGLE_SELECT_PLACEHOLDER),
            selected: true
        }],
        this.state.subscriberIdentifications.forEach((indetificationDocument) => {
            this.identificationDocuments.push({
                id: indetificationDocument.Value,
                text: indetificationDocument.Name
            });
        });
    };

    this.isClearButtonShown = () => {
        return (Object.entries(this.customerFormSearchModel) || []).some(([key, value]) => {
            if (key === FILTER_PAYMENT_INSTRUMENT_TYPE && this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.TRANSACTION ||
                (this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.ADDITIONAL_PROPERTIES && !this.customerFormSearchModel.subscriberAdditionalProperties.length)) {
                return false;
            }
            return !!value;
        }) ||
        this.shouldShowNoResultsError() ||
        this.shouldShowSearchError();
    };
    this.isSearchCleared = false;
    this.clearSearch = () => {
        this.selectedIdentificationEntity = null;
        this.isSearchCleared= true;
        this.$timeout(() => {
            const filterPaymentInstrumentType = this.customerFormSearchModel.filterPaymentInstrumentType;
            this.customerFormSearchModel = {};
            if (filterPaymentInstrumentType !== undefined && this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.TRANSACTION) {
                this.customerFormSearchModel.filterPaymentInstrumentType = filterPaymentInstrumentType;
            }
            if (this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.ADDITIONAL_PROPERTIES) {
                this.customerFormSearchModel = getSearchFieldsTemplate();
            }
            this.isSearchCleared = false;
        });
        this.clearAllErrors();
        this.canShowNoResultsError = false;
        this.searchExecuted = false;
        this.customerSearchForm.$setUntouched();
        this.customerSearchForm.$setPristine();
        this.formatSubscriberIdentificationType();
    };

    this.emailAsLogin = () => {
        const requirements = this.state.customerCategory === CUSTOMER_CATEGORY.COMMERCIAL
            ? this.state.subscriberCategoryRequirements
            : this.state.subscriberRequirements;

        return requirements.capture_email_as_login;
    };

    this.clearCreditCardNumberPartial = () => {
        this.paymentCreditCardNumberPartial = 'false';
        this.customerFormSearchModel.paymentFirstFourDigits = undefined;
        this.customerFormSearchModel.paymentLastFourDigits = undefined;
    };

    this.clearCreditCardNumberFull = () => {
        this.customerFormSearchModel.paymentAccount = undefined;
    };

    this.switchCreditCardAccountFormat = () => {
        if (this.paymentCreditCardNumberPartial === 'true') {
            this.clearCreditCardNumberFull();
        } else {
            this.clearCreditCardNumberPartial();
        }
    };

    this.isPaymentInstrumentTypeRequired = () => {
        return this.customerFormSearchModel.paymentAccount !== undefined && this.customerFormSearchModel.paymentAccount.length > 0;
    };

    this.initiateSearch = () => {
        this.errorLookup = this.getErrorLookupObject();
        if (!this.checkForErrors(this.customerSearchForm.$error)) {
            // when they search reset the page number to 1 in case the page number was set from a previous search result
            this.onPageChange()(1);
            this.customerFormSearchModel.returnUniqueSubscribersByTransaction = this.setProperty(SEARCH_DROPDOWN_CONSTANTS.TRANSACTION, true);
            this.onCustomerSearchModelChange()(this.customerFormSearchModel);

            this.canShowNoResultsError = true;

            // In the customerFormSearchModel below, if the property is falsey (such as an empty string), we want to set
            // it to undefined, so it does not send a value to the api. Also force undefined if that category belongs to
            // a different search type.
            this.customerFormSearchModel = {
                // Address specific search-related fields
                addressLineOne: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.ADDRESS, this.customerFormSearchModel.addressLineOne),
                postalCode: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.ADDRESS, this.customerFormSearchModel.postalCode ),

                // Business specific search-related fields
                deviceId: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.BUSINESS, this.customerFormSearchModel.deviceId),
                deviceSerialNumber: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.BUSINESS, this.customerFormSearchModel.deviceSerialNumber),
                orderId: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.BUSINESS, this.customerFormSearchModel.orderId),
                orderNumber: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.BUSINESS, this.customerFormSearchModel.orderNumber),

                // Company specific search-related fields
                companyName: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.companyName),
                hierarchyName: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.hierarchyName),

                // Coupon search related fields
                redemptionCode: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COUPON, this.customerFormSearchModel.redemptionCode),

                // Gift Card search related fields
                accountNumber: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD, this.customerFormSearchModel.accountNumber),
                pin: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD, this.customerFormSearchModel.pin),
                senderEmail: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD, this.customerFormSearchModel.senderEmail),

                // Individual specific search-realated fields
                firstName: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.firstName),
                lastName: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.lastName),

                // Individual or Company specific search-related fields
                emailAddress: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.emailAddress) ||
                    this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.emailAddress),
                externalId: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.externalId) ||
                    this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.externalId),
                loginOrEmailAddress: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.emailAddress) ||
                    this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.emailAddress),
                phoneNumber: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.phoneNumber) ||
                    this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.phoneNumber),
                serviceId: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.serviceId) ||
                    this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.serviceId),
                subscriberId: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.subscriberId ? parseInt(this.customerFormSearchModel.subscriberId, 10) : undefined) ||
                    this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.subscriberId ? parseInt(this.customerFormSearchModel.subscriberId, 10) : undefined),
                emailOrLoginSuggestions: this.customerFormSearchModel.emailOrLoginSuggestions,
                armAccountNumber: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.armAccountNumber) ||
                    this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.customerFormSearchModel.armAccountNumber),
                subscriberIdentification: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.checkIfSubscriberIdentificationPresent() ?
                    this.customerFormSearchModel.subscriberIdentification : undefined) ||
                        this.setProperty(SEARCH_DROPDOWN_CONSTANTS.COMPANY, this.checkIfSubscriberIdentificationPresent() ?
                            this.customerFormSearchModel.subscriberIdentification : undefined),
                title: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL, this.customerFormSearchModel.title),

                // Transaction search-related fields
                filterPaymentInstrumentType: this.isSearchCreditCard() ? SEARCH_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD : this.customerFormSearchModel.filterPaymentInstrumentType || undefined,
                paymentAccount: this.setPaymentAccountValue(this.customerFormSearchModel.paymentAccount),
                paymentFirstFourDigits: this.setPartialCreditCardValue(this.customerFormSearchModel.paymentFirstFourDigits),
                paymentInstrumentSubType: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.TRANSACTION, this.customerFormSearchModel.paymentInstrumentSubTypeCreditCard || this.customerFormSearchModel.paymentInstrumentSubTypeExternalBiller),
                paymentInstrumentSubTypeCreditCard: this.isSearchCreditCard() ? this.customerFormSearchModel.paymentInstrumentSubTypeCreditCard || undefined : undefined,
                paymentInstrumentSubTypeExternalBiller: this.isSearchExternalBiller() ? this.customerFormSearchModel.paymentInstrumentSubTypeExternalBiller || undefined : undefined,
                paymentLastFourDigits: this.setPartialCreditCardValue(this.customerFormSearchModel.paymentLastFourDigits),
                paymentReconciliationId: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.TRANSACTION, this.customerFormSearchModel.paymentReconciliationId),
                paymentReferenceId: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.TRANSACTION, this.customerFormSearchModel.paymentReferenceId),
                purchaseReceiptReference: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.TRANSACTION, this.customerFormSearchModel.purchaseReceiptReference),
                paymentTransactionDate: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.TRANSACTION, this.customerFormSearchModel.paymentTransactionDate),
                returnUniqueSubscribersByTransaction: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.TRANSACTION, this.customerFormSearchModel.returnUniqueSubscribersByTransaction),
                subscriberAdditionalProperties: this.setProperty(SEARCH_DROPDOWN_CONSTANTS.ADDITIONAL_PROPERTIES, this.customerFormSearchModel.subscriberAdditionalProperties),
            };

            if (this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD) {
                this.actions.getGiftCardSearch(this.customerFormSearchModel, 1, this.state.pageSize);
            } else if (this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.COUPON) {
                this.actions.getCouponSearch(this.customerFormSearchModel, 1, this.state.pageSize);
            } else {
                this.actions.getCustomerSearch(this.customerFormSearchModel, 1, this.state.pageSize);
            }

            this.customerSearchForm.$setUntouched();
            this.customerSearchForm.$setPristine();
            this.searchExecuted = true;
        }
    };

    this.setProperty = (searchType, property) => {
        return this.state.searchType === searchType ? property || undefined : undefined;
    };

    this.setPaymentAccountValue = (value) => {
        return this.isSearchCreditCard() && this.paymentCreditCardNumberPartial === 'true' ? undefined : value || undefined;
    };

    this.setPartialCreditCardValue = (partialValue) => {
        return this.paymentCreditCardNumberPartial === 'true' && this.isSearchCreditCard() ? partialValue || undefined: undefined;
    };

    this.onOptionSelect = (item) => {
        if (this.state.searchType !== item.id) {
            this.setSelectedOption(item.id);
            this.actions.setSearchType(item.id);
            this.customerFormSearchModel = getSearchFieldsTemplate();
            this.customerSearchForm.$setDirty();
            this.customerSearchForm.$setUntouched();
            this.searchExecuted = false;
            this.clearAllErrors();
            this.formatSubscriberIdentificationType();
            this.selectedIdentificationEntity = null;
            if (!this.customerFormSearchModel.filterPaymentInstrumentType && item.id === SEARCH_DROPDOWN_CONSTANTS.TRANSACTION) {
                this.customerFormSearchModel.filterPaymentInstrumentType = SEARCH_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD;
            }
        }
    };

    this.setSelectedOption = (itemId) => {
        this.actions.setSearchType(itemId);
    };

    this.getAccountNumberFieldTitle = () => {
        let accountNumberTitle;

        switch (this.customerFormSearchModel.filterPaymentInstrumentType) {
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD:
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.CREDIT_CARD);
                break;
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.ECHECK:
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.ECHECK);
                break;
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILLER:
                this.purchaseReceiptReferenceLabel = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.EXTERNAL_REFERENCE_ID);
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.EXTERNAL_BILLING);
                break;
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.ITUNES:
                this.purchaseReceiptReferenceLabel = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.ORIGINAL_TRANSACTION_ID);
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.ITUNES);
                break;
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.GOOGLE_PLAY:
                this.purchaseReceiptReferenceLabel = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PURCHASE_TOKEN);
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.GOOGLE_PLAY);
                break;
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.PAYPAL:
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.PAYPAL);
                break;
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.POINT_FOR_SALE:
                this.purchaseReceiptReferenceLabel = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.RECEIPT_NUMBER);
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.DEFAULT);
                break;
            case SEARCH_PAYMENT_INSTRUMENT_TYPES.UWP:
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.UWP);
                break;
            default:
                accountNumberTitle = i18n.translate(this.customerCareLocaleKeys.SEARCH_FIELDS_CUSTOMER.PAYMENT_ACCOUNT_TITLE.DEFAULT);
        }

        return accountNumberTitle;
    };

    this.onChangePaymentType = () => {
        this.purchaseReceiptReferenceLabel = '';
        this.customerFormSearchModel.purchaseReceiptReference = '';
        this.customerFormSearchModel.paymentAccount = undefined;
        this.customerFormSearchModel.paymentInstrumentSubTypeCreditCard = undefined;
        this.customerFormSearchModel.paymentInstrumentSubTypeExternalBiller = undefined;
        this.clearCreditCardNumberPartial();
        this.getAccountNumberFieldTitle();
    };

    this.isSearchCreditCard = () => {
        return this.customerFormSearchModel.filterPaymentInstrumentType === SEARCH_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD;
    };

    this.isSearchExternalBiller = () => {
        return this.customerFormSearchModel.filterPaymentInstrumentType === SEARCH_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILLER;
    };

    this.isPartialCreditCardVisible = () => {
        return this.customerFormSearchModel.filterPaymentInstrumentType === SEARCH_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD && this.paymentCreditCardNumberPartial === 'true';
    };

    this.isPointForSaleVisible = () => {
        return this.customerFormSearchModel.filterPaymentInstrumentType === SEARCH_PAYMENT_INSTRUMENT_TYPES.POINT_FOR_SALE && !this.isSearchCreditCard();
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.shouldShowNoResultsError =() => {
        return (this.state.customerSearchResultCount === 0
            || this.state.giftCardSearchResultCount === 0
            || this.state.couponSearchResultCount < 0)
            && this.canShowNoResultsError
            && !this.hasFormErrors() && this.state.apiErrors.length === 0
            && this.searchExecuted && !this.state.isFetchingResults;
    };

    this.getErrorLookupObject = () => {
        return {
            emptyTransacationFormFields: {
                required: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.EMPTY_FORM_ERROR)
            },
            additionalSearchCriteria: {
                required: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.ADDITIONAL_SEARCH_CRITERIA_ERROR)
            },
            customerSearchForm: {
                required: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.EMPTY_FORM_ERROR)
            },
            email: {
                pattern: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.EMAIL_FORMAT_ERROR),
                regex: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.EMAIL_FORMAT_ERROR),
            },
            filterPaymentInstrumentType: {
                required: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.PAYMENT_TYPE_ERROR)
            },
            firstName: {
                required: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.INVALID_NAME_ERROR)
            },
            giftCardAccountNumber: {
                pattern: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_GIFT_CARD.ACCOUNT_NUMBER_ERROR),
                minlength: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_GIFT_CARD.ACCOUNT_NUMBER_LENGTH_ERROR)
            },
            lastName: {
                required: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.INVALID_NAME_ERROR)
            },
            orderId: {
                pattern: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.ORDERID_FORMAT_ERROR)
            },
            postalCode: {
                required: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.EMPTY_POSTAL_CODE_ERROR )
            },
            senderEmailAddress: {
                pattern: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_GIFT_CARD.EMAIL_FORMAT_ERROR)
            },
            serviceId: {
                pattern: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.SERVICEID_FORMAT_ERROR)
            },
            subscriberId: {
                pattern: i18n.translate(this.customerCareLocaleKeys.SEARCH_ERRORS_CUSTOMER.SUBSCRIBERID_FORMAT_ERROR)
            }
        };
    };

    this.requireAccountPaymentReferenceId = () => {
        return (this.customerFormSearchModel.filterPaymentInstrumentType === SEARCH_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD || !!this.customerFormSearchModel.filterPaymentInstrumentType)
            && !(this.customerFormSearchModel.filterPaymentInstrumentType === SEARCH_PAYMENT_INSTRUMENT_TYPES.POINT_FOR_SALE)
            && !this.customerFormSearchModel.paymentReferenceId
            && !this.customerFormSearchModel.paymentReconciliationId
            && !this.customerFormSearchModel.paymentAccount
            && !this.customerFormSearchModel.paymentFirstFourDigits
            && !this.customerFormSearchModel.paymentLastFourDigits
            && !this.customerFormSearchModel.purchaseReceiptReference;
    };

    this.requireOneTransactionSearchField = () => {
        return this.customerFormSearchModel.paymentTransactionDate !== undefined
            && !this.customerFormSearchModel.filterPaymentInstrumentType
            && !this.customerFormSearchModel.paymentReferenceId
            && !this.customerFormSearchModel.paymentAccount
            && !this.customerFormSearchModel.paymentFirstFourDigits
            && !this.customerFormSearchModel.paymentLastFourDigits
            && !this.customerFormSearchModel.purchaseReceiptReference;
    };

    this.checkForErrors = (ngFormError) => {
        const errorModelList = formHasErrors(ngFormError, this.errorLookup);

        // Transaction search validations
        if (this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.TRANSACTION) {
            // If payment type is chosen, check to see if
            // payment reference or account number (full or partial) is also set
            if (this.requireAccountPaymentReferenceId()) {
                errorModelList.push({
                    description: this.errorLookup.emptyTransacationFormFields.required
                });
            }

            if (this.requireOneTransactionSearchField()) {
                if (errorModelList.length === 0) {
                    errorModelList.push({
                        description: this.errorLookup.additionalSearchCriteria.required
                    });
                }
            }
        }

        // check to make sure at least one field was provided (also check for zero since it might be a code type)
        // If there was any other form error, don't log this since at least one of the form elements has been set elsewhere
        if (errorModelList.length === 0 && Object.values(this.customerFormSearchModel).every((value) => {
            return !value && value !== 0 || value && value.length === 0 || value === SEARCH_PAYMENT_INSTRUMENT_TYPES.POINT_FOR_SALE;
        } )) {
            errorModelList.push({
                description: this.errorLookup.customerSearchForm.required
            });
        }
        if (this.state.isDbss && this.customerFormSearchModel.subscriberIdentification) {
            if (errorModelList.length === 0 && this.validateSubscriberIdentification()) {
                errorModelList.push({
                    description: this.errorLookup.customerSearchForm.required
                });
            }
        }

        this.formErrors = errorModelList;
        return errorModelList.length > 0;
    };

    this.validateSubscriberIdentification = () => {
        return this.customerFormSearchModel.subscriberIdentification ?
            (!this.customerFormSearchModel.subscriberIdentification.Value && this.customerFormSearchModel.subscriberIdentification.Type) ||
            (this.customerFormSearchModel.subscriberIdentification.Value && !this.customerFormSearchModel.subscriberIdentification.Type) :
            false;
    };

    this.checkIfSubscriberIdentificationPresent = () => {
        return this.customerFormSearchModel.subscriberIdentification ?
            this.customerFormSearchModel.subscriberIdentification.Value &&
            this.customerFormSearchModel.subscriberIdentification.Type : false;
    };

    this.shouldShowSearchSelectBox = () => {
        return (this.state.searchType !== SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD && this.state.searchType !== SEARCH_DROPDOWN_CONSTANTS.COUPON);
    };


    this.shouldShowSearchError = () => {
        return !this.hasFormErrors() && this.state.apiErrors.length > 0;
    };

    this.searchTypeChanged = (item) => {
        this.searchExecuted = false;
        this.clearAllErrors();
        this.setSelectedOption(item.id);
    };

    this.clearFormErrors = () => {
        this.formErrors = [];
    };

    this.clearAllErrors = () => {
        this.getAccountNumberFieldTitle();
        if (this.actions) {
            this.actions.clearSearchError();
            this.clearFormErrors();
        }
    };

    this.hasFormErrors = () => {
        return this.formErrors && this.formErrors.length > 0;
    };

    this.getSearchTypeKey = () => {
        if (this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD) {
            return this.customerCareLocaleKeys.GIFT_CARD;
        } else if (this.state.searchType === SEARCH_DROPDOWN_CONSTANTS.COUPON) {
            return this.customerCareLocaleKeys.COUPON;
        } else {
            return this.customerCareLocaleKeys.CUSTOMER;
        }

    };

    this.fetchSubscriberSuggestions = (queryStr) => {
        return this.actions.searchSubscriberSuggestions(queryStr, this.state.searchType).then(() => {
            this.customerFormSearchModel.emailOrLoginSuggestions = this.state.customerSearchSuggestions;
            return this.state.customerSearchSuggestions;
        });
    };

    this.onSuggestionSelected = () => {
        $element.find('#customerSearchForm .c-searchPanel-footer .c-button--primary').trigger('click');
    };

    this.onCustomerSearchIdetifificaticonTypeSelect = (selectedIdentificationType) => {
        if (!this.customerFormSearchModel.subscriberIdentification) {
            this.customerFormSearchModel.subscriberIdentification = {};
        }
        if (selectedIdentificationType.id) {
            this.customerFormSearchModel.subscriberIdentification.Type = selectedIdentificationType.id;
        } else {
            delete this.customerFormSearchModel.subscriberIdentification;
        }
    };

    this.onCustomerSearchIdentificationValue = () => {
        if (!this.customerFormSearchModel.subscriberIdentification) {
            this.customerFormSearchModel.subscriberIdentification = {
                Type: null
            };
        }
        if (this.selectedIdentificationEntity) {
            this.customerFormSearchModel.subscriberIdentification.Value = this.selectedIdentificationEntity;
        } else {
            delete this.customerFormSearchModel.subscriberIdentification;
        }
    };

    this.onAdditionalPropertyChange = (id, newValue) => {
        const currentProperty = this.customerFormSearchModel.subscriberAdditionalProperties &&
            this.customerFormSearchModel.subscriberAdditionalProperties.length ?
            this.customerFormSearchModel.subscriberAdditionalProperties.filter((prop) => {
                return prop.Code === id;
            }) : [];
        const currentAdditionalProperty = this.state.subscriberAdditionalPropData.find((prop) => {
            return prop.id === id;
        });
        let value = newValue;
        if (currentAdditionalProperty.type === AdditionalPropertyValueType.BOOLEAN || currentAdditionalProperty.type === AdditionalPropertyValueType.SELECT) {
            value = (currentAdditionalProperty.options.find((option) => {
                return option.id === newValue;
            })).name;
        }
        if (!currentProperty.length) {
            this.customerFormSearchModel.subscriberAdditionalProperties.push({
                Code: id,
                Value: value.toString()
            });
        } else {
            this.customerFormSearchModel.subscriberAdditionalProperties.map((property) => {
                if (property.Code !== id) {
                    return property;
                } else {
                    return Object.assign(property, {
                        Value: value.toString()
                    });
                }
            });
        }
        this.actions.setEditApOnChange(id, newValue);
    };
}

// Definition for the self-contained SearchFields component
export const SearchFields = {
    template: require('./search.fields.html'),
    controller: SearchFieldsController,
    controllerAs: 'SearchFields',
    bindings: {
        customerSearchModel: '<',
        pageNumber: '<',
        onPageChange: '&',
        onCustomerSearchModelChange: '&',
        searchFieldsApi: '&?'
    }
};
