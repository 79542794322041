import {stateGo} from 'redux-ui-router';
import __ from 'ramda/src/__';
import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';
import groupBy from 'ramda/src/groupBy';
import i18n from 'invision-core/src/components/i18n/i18n';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {hasAdminAccess} from 'invision-core/src/components/security/permission.service';
import {
    PageSizePreferenceSelector,
    UserSecurityAttributesSelector
} from 'invision-core/src/components/session/session.selectors';

import LocaleKeys from './../../../../locales/keys';
import {ORDERING_ACCESS} from '../../../../security.attributes';

import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    IsCurrentCustomerBlocklisted
} from '../../../../reducers/selectors/customer.selectors';
import {
    CurrentPageNumberSelector,
    CustomerProductsCollectionSelector,
    IncludeExpiredSelector,
    IncludeRemovedSelector,
    IncludeSearchSelector,
    IsFetchingProductsDataSelector,
    IsRecentProductsDataSelector,
    ProductClassificationSelector,
    RecordCountSelector,
    SelectedPageSizePreference,
    SortCriteriaSelector,
    TableDataSelector,
    TotalPagesSelector
} from '../../../../reducers/selectors/customer.products.selectors';
import {
    retrieveProducts,
    setCurrentPage,
    setIncludedExpiredFilter,
    setIncludedRemovedFilter,
    setIncludedSearchFilter,
    setPageSizePreference,
    setProductsClassificationType,
    setSortCriteria
} from '../../../../reducers/actions/customer.products.actions';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';
import {isProductEditable} from '../products.component.helpers';
import {DBSS_PRODUCT_DETAILS_PAGE_REDIRECT} from '../../../../reactRoutes';
import {DETAIL_STATE_OR_NAME} from '../products.config';

const MAX_SLOTS_FOR_PAGER = 5;
export const productsListGridSortConstants = {
    column: {
        ProductName: 1,
        OrderDate: 2
    },
    direction: {
        asc: 1,
        desc: 2
    }
};

class CustomerProductsListController {
    constructor($ngRedux, $timeout, $interval, uiGridConstants, $scope, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            $interval,
            uiGridConstants,
            $scope,
            uiNotificationService,
            classifications: [],
            CustomerLocaleKeys: LocaleKeys,
            openProductDetailsPage: this.openProductDetailsPage.bind(this),
            selectedItems: [],
            stateOrName: 'index.customercare.customer.products'
        });
    }

    $onInit() {
        const columnDefs = [
            {
                field: 'OrderDate',
                displayName: i18n.translate(LocaleKeys.PRODUCTS_PAGE.CREATED_DATE),
                width: 150,
                enableSorting: true,
                sortDirectionCycle: [this.uiGridConstants.DESC, this.uiGridConstants.ASC],
                cellTemplate: require('./cellTemplates/order.date.html')
            }, {
                field: 'Status',
                displayName: i18n.translate(LocaleKeys.STATUS),
                width: 150,
                cellTemplate: require('./cellTemplates/status.template.html')
            }, {
                field: 'ProductName',
                displayName: i18n.translate(LocaleKeys.PRODUCT),
                minWidth: 150,
                width:'*',
                enableSorting: true,
                sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
                cellTemplate: require('./cellTemplates/product.name.html')
            }, {
                field: 'Offer',
                displayName: i18n.translate(LocaleKeys.PRODUCTS_PAGE.OFFER),
                maxWidth: 300,
                sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC]
            }, {
                field: 'Service',
                displayName: i18n.translate(LocaleKeys.SERVICE),
                maxWidth: 150,
                sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC]
            }, {
                field: 'PricingPlan.Name',
                displayName: i18n.translate(LocaleKeys.PRICING_PLAN),
                maxWidth: 350
            }, {
                field: 'Class',
                displayName: i18n.translate(LocaleKeys.PRODUCTS_PAGE.CLASS),
                minWidth: 150,
                sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC]
            }
        ];
        const onRegisterApi = (event) => {
            this.gridApi = event.grid.api;
            this.gridApi.selection.on.rowSelectionChanged(null, this.getSelectedRows.bind(this));
            this.gridApi.selection.on.rowSelectionChangedBatch(null, this.getSelectedRows.bind(this));
            this.gridApi.selection.clearSelectedRows();

            const sortCriteria = pathOr({}, ['sortCriteria'], this.state);
            if (equals(productsListGridSortConstants.column.OrderDate, sortCriteria.SortBy)) {
                this.state.tableData.columnDefs[0].sort = {
                    direction: (sortCriteria.SortDirection === productsListGridSortConstants.direction.desc) ? this.uiGridConstants.DESC : this.uiGridConstants.ASC
                };
            } else if (equals(productsListGridSortConstants.column.ProductName, sortCriteria.SortBy)) {
                this.state.tableData.columnDefs[2].sort = {
                    direction: (sortCriteria.SortDirection === productsListGridSortConstants.direction.desc) ? this.uiGridConstants.DESC : this.uiGridConstants.ASC
                };
            }
            this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                this.actions.setSortCriteria({
                    SortBy: productsListGridSortConstants.column[sortColumns[0].field],
                    SortDirection: productsListGridSortConstants.direction[sortColumns[0].sort.direction]
                });
                this.actions.setCurrentPage(1);
                this.gridApi.selection.clearSelectedRows();
                this.fetchProducts();
            });
        };
        const rowTemplate = require('./cellTemplates/row.template.html');

        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPageNumber: CurrentPageNumberSelector(store),
                data: TableDataSelector(store),
                includeExpired: IncludeExpiredSelector(store),
                includeRemoved: IncludeRemovedSelector(store),
                includeSearch: IncludeSearchSelector(store),
                isCurrentCustomerBlocklisted: IsCurrentCustomerBlocklisted(store),
                isDbss: IsDbss(store),
                isFetchingProductsData: IsFetchingProductsDataSelector(store),
                isRecentProductsOnly: IsRecentProductsDataSelector(store),
                maximumSlots: MAX_SLOTS_FOR_PAGER,
                numberOfPages: TotalPagesSelector(store),
                productClassification: ProductClassificationSelector(store),
                productClassifications: MetadataOptionsForCodeValuesSelector(CODES.ProductClassification, store),
                products: CustomerProductsCollectionSelector(store),
                recordCount: RecordCountSelector(store),
                selectedPageSizePreference: SelectedPageSizePreference(store),
                sortCriteria: SortCriteriaSelector(store),
                tableData: {
                    appScopeProvider: this,
                    columnDefs: columnDefs,
                    data: TableDataSelector(store),
                    enableHorizontalScrollbar: this.uiGridConstants.scrollbars.NEVER,
                    enableSorting: false,
                    isRowSelectable: isRowSelectable,
                    onRegisterApi: onRegisterApi,
                    rowTemplate: rowTemplate,
                    useExternalSorting: true
                },
                userPageSizePreference: PageSizePreferenceSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const isRowSelectable = (row) => {
            return hasAdminAccess(this.state.userSecurityAttributes, ORDERING_ACCESS) &&
                isProductEditable(row.entity, this.state.isDbss);
        };

        const controllerActions = {
            fetchCodeTypes: fetchCodeTypesThunk,
            retrieveProducts,
            setCurrentPage,
            setIncludedExpiredFilter,
            setIncludedRemovedFilter,
            setIncludedSearchFilter,
            setPageSizePreference,
            setProductsClassificationType,
            setSortCriteria,
            stateGo
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.fetchProducts();

        this.model = {
            Classification: this.state.productClassification,
            SearchString: this.state.includeSearch
        };

        this.bulkActionList = [
            {
                glyph: 'trash',
                name: i18n.translate(this.CustomerLocaleKeys.REMOVE),
                actionFn: () => {
                    this.openRemoveProductsPopup();
                }
            }
        ];

        if (!this.state.codeTypeLoaded(CODES.ProductClassification)) {
            this.actions.fetchCodeTypes(CODES.ProductClassification).then(() => {
                this.mapProductClassifications();
            });
        }

        this.mapProductClassifications();

        this.removeProductsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.removeProductsPopupConfig = api;
            }
        };

        this.multiClassErrorPopup = {
            onRegisterApi: ({api}) => {
                this.multiClassErrorPopup = api;
            }
        };
    }

    deselectAll() {
        if (this.gridApi) {
            this.gridApi.selection.clearSelectedRows();
        }
        this.selectedItems = [];
    }

    get disabledNewOrderButtonTooltip() {
        if (this.state.isCurrentCustomerBlocklisted) {
            return this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT_STATUS_BLOCKLISTED_LOCKED_ORDERING;
        } else {
            return this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.CONVERGENT_BILLER_ERROR;
        }
    }

    getSelectedRows() {
        this.selectedItems = this.gridApi.selection.getSelectedGridRows().map((gridRow) => {
            return gridRow.entity;
        });
    }

    handleStartNewOrder() {
        if (!this.showDisabledNewOrderButton()) {
            this.actions.stateGo('index.customercare.customer.orders.createProductsOrder', {
                productsOnly: true
            });
        }
    }

    handleIncludeExpiredClick() {
        this.actions.setIncludedExpiredFilter(!this.state.includeExpired);
        this.onPageSelected(1);
    }

    handleIncludeRemovedClick() {
        this.actions.setIncludedRemovedFilter(!this.state.includeRemoved);
        this.onPageSelected(1);
    }

    handleSearchString() {
        this.actions.setIncludedSearchFilter(this.model.SearchString);
        this.onPageSelected(1);
    }

    clearSearchText() {
        this.model.SearchString = '';
        this.handleSearchString();
    }

    handleClassificationFilter() {
        this.actions.setProductsClassificationType(+this.model.Classification ?? null);
        this.onPageSelected(1);
    }

    onPageSelected(page) {
        this.deselectAll();
        this.actions.setCurrentPage(page);
        this.fetchProducts();
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setPageSizePreference(pageSize);
        this.onPageSelected(1);
    }

    hasFiltersApplied() {
        return this.state.includeRemoved
            || this.state.includeExpired
            || this.state.includeSearch
            || !!this.state.productClassification;
    }

    fetchProducts() {
        if (!this.state.selectedPageSizePreference) {
            this.actions.setPageSizePreference(this.state.userPageSizePreference);
        }

        const additionalOpts = {
            IncludeExpired: this.state.includeExpired,
            IncludeRemoved: this.state.includeRemoved,
            PageNumber: this.state.currentPageNumber,
            PageSize: this.state.selectedPageSizePreference,
            ProductClassifications: this.state.productClassification,
            SearchString: this.state.includeSearch,
            SortBy: this.state.sortCriteria.SortBy,
            SortDirection: this.state.sortCriteria.SortDirection
        };

        this.actions.retrieveProducts(this.state.currentCustomerId, additionalOpts);
    }

    mapProductClassifications() {
        this.productClassifications = this.state.productClassifications.map((item) => {
            if (item.value === null) {
                item.label = i18n.translate(LocaleKeys.ALL);
            }
            return item;
        });
    }

    submitRemoveProductsPopup() {
        this.closeRemoveProductsPopup();
        this.deselectAll();
        this.waitForLocker();
    }

    waitForLocker() {
        let productCheckCount = 0;
        this.isLoading = true;

        const orderCompletionInterval = this.$interval(() => {
            this.checkLibraryForProducts().then((result) => {
                if (result) {
                    this.$interval.cancel(orderCompletionInterval);
                    this.isLoading = false;
                    this.fetchProducts();
                }
            });

            if (productCheckCount >= 3) {
                this.$interval.cancel(orderCompletionInterval);
                this.isLoading = false;
                this.fetchProducts();
                this.uiNotificationService.info(i18n.translate(this.CustomerLocaleKeys.REMOVE_PRODUCTS.LIBRARY_UPDATE), null, {
                    timeout: NOTIFICATION_TIME_LENGTH
                });
            } else {
                productCheckCount++;
            }
        }, 2000);
    }

    checkLibraryForProducts() {
        const date = new Date();
        date.setSeconds(date.getSeconds() - 15);

        const additionalOpts = {
            IncludeRemoved: true,
            ModifiedSince: date.toISOString()
        };

        return this.actions.retrieveProducts(this.state.currentCustomerId, additionalOpts).then((recentItems) => {
            if (recentItems && recentItems.RecordCount > 0) {
                return true;
            }
        });
    }

    closeRemoveProductsPopup() {
        this.removeProductsPopupConfig.close();
        this.showRemoveProductsPopup = false;
    }

    closeMultiClassErrorPopup() {
        this.multiClassErrorPopup.close();
    }

    openRemoveProductsPopup() {
        const groupedByProductClassification = groupBy((item) => {
            return item.Product.ProductClassification;
        }, this.selectedItems);

        if (Object.keys(groupedByProductClassification).length === 1) {
            // 1 being they are all the same type of classification
            this.showRemoveProductsPopup = true;

            this.$timeout(this.removeProductsPopupConfig.open);
        } else {
            this.$timeout(this.multiClassErrorPopup.open);
        }
    }

    openProductDetailsPage(productId) {
        if (this.state.isDbss) {
            this.actions.stateGo(DBSS_PRODUCT_DETAILS_PAGE_REDIRECT, {
                customerId: this.state.currentCustomerId,
                productId: productId
            });
        } else {
            this.actions.stateGo(DETAIL_STATE_OR_NAME, {
                lockerItemId: productId
            });
        }
    }

    hasOrderingAccess() {
        return hasAdminAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
    }

    showDisabledNewOrderButton() {
        return (this.state.isDbss
            && !this.state.currentCustomer.ConvergentBillerId)
            || this.state.isCurrentCustomerBlocklisted;
    }

    $onDestroy() {
        this.disconnect();
    }
}

export default {
    template: require('./products.list.html'),
    controller: CustomerProductsListController,
    controllerAs: 'CustomerProductsList'
};
