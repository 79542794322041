import pathOr from 'ramda/src/pathOr';
import {stateGo} from 'redux-ui-router';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {createOverrideSession} from 'invision-core/src/reducers/actions/user/user.actions';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {MODULES} from 'invision-core/src/constants/module.constants';
import {
    IsOverrideActiveSelector,
    SessionIdSelector,
    UserSecurityAttributesSelector
} from 'invision-core/src/components/session/session.selectors';
import {
    retrieveDiscountsMetadata,
    retrieveDiscretionaryDiscountsMetadata
} from 'invision-core/src/components/metadata/discounts/discounts.actions';
import CustomerCareLocaleKeys from '../../locales/keys';
import {
    CUSTOMERCARE_PAGE_APP_ID,
    LoadNavigatorItems,
    NavigatorFlyoutCaption,
    RECENT_CUSTOMERS_NAV_ITEM_ID,
    SEARCH_NAV_ITEM_ID,
    customerCareRoute
} from './customercare.page.constants';
import {
    IsCustomerWrapUpRequiredSelector,
    IsSearchPanelVisibleSelector
} from '../../reducers/selectors/search.selectors';
import {CUSTOMER_CATEGORY} from '../createCustomerPopup/create.customer.popup.constants';
import {
    CreateEditCustomerSelector,
    CurrentCustomerIdSelector,
    IsWrappingCustomerSelector
} from '../../reducers/selectors/customer.selectors';
import {
    hideSearchPanel,
    showSearchPanel
} from '../../reducers/actions/search.actions';
import {
    NOTIFICATION_TIME_LENGTH,
    ROOT_CARE_MODULE_ROUTE
} from '../../customercare.constants';
import {
    AddOfferingBetaUIFeatureToggleSelector,
    ChangeOfferingBetaUIFeatureToggleSelector,
    CustomerActionsTemplateSelector,
    EditOfferingBetaUIFeatureToggleSelector,
    DisconnectOfferingBetaUIFeatureToggleSelector,
    NewConnectBetaUIFeatureToggleSelector,
    RemoveOfferingBetaUIFeatureToggleSelector
} from '../../reducers/selectors/customer.actions.template.selectors';
import {
    CREATE_COMMERCIAL_CUSTOMER_GLOBAL_ACTION,
    CREATE_CUSTOMER_GLOBAL_ACTION,
    CREATE_RESIDENTIAL_CUSTOMER_GLOBAL_ACTION,
    NEW_CONNECT_GLOBAL_ACTION
} from '../customerActionsTemplate/global.actions';
import {
    IsPanelVisibleSelector
} from '../../reducers/selectors/recent.customers.selectors';
import {
    hideRecentCustomersPanel,
    showRecentCustomersPanel
} from '../../reducers/actions/recent.customers.actions';
import {loadSubscriberRequirements} from '../../reducers/helpers/subscriber.requirements.actions.helper';
import {
    INITIAL_STATE as CREATE_CUSTOMER_INITIAL_STATE
} from '../../reducers/create.customer.reducer';
import {
    STATE_OR_NAME as NEW_CONNECT_STATE
} from '../../reducers/constants/new.connect.wizard.constants';
import PanelAutoClose from 'invision-core/src/utilities/panel.auto.close.handler';
import {customerWrapUp} from '../../reducers/actions/customer.actions';
import {OVERRIDE_OFFER_QUALIFICATION_ENFORCEMENT} from '../../security.attributes';
import {getReactRoutes} from './../../reactRoutes';
import {setupReactRoutes} from 'invision-core/src/components/application/setupReactRoutes';

export function CustomerCarePageController($ngRedux, $timeout, $scope, $rootScope, $state, uiNotificationService, unsavedChangesPrompt) {
    let disconnectRedux;
    this.showCustomerWrapUp = false;
    const mapStateToTarget = (store) => {
        return {
            createCustomer: CreateEditCustomerSelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            customerActionsTemplate: CustomerActionsTemplateSelector(store),
            isAccountingMethodLoaded: MetadataCodeLoadedSelector(CODES.Accounting, store),
            isAddOfferingBetaEnabled: AddOfferingBetaUIFeatureToggleSelector(store),
            isChangeOfferingBetaEnabled: ChangeOfferingBetaUIFeatureToggleSelector(store),
            isRemoveOfferingBetaEnabled: RemoveOfferingBetaUIFeatureToggleSelector(store),
            isDisconnectOfferingBetaEnabled: DisconnectOfferingBetaUIFeatureToggleSelector(store),
            isConvergentBillerBehaviorConfigLoaded: MetadataCodeLoadedSelector(CODES.ConvergentBillerBehaviorConfig, store),
            isCustomerRequirementsLoaded: MetadataCodeLoadedSelector(CODES.SubscriberRequirements)(store),
            isCustomerWrapUpLoaded: MetadataCodeLoadedSelector(CODES.CustomerWrapUp, store),
            isCustomerWrapupRequired: IsCustomerWrapUpRequiredSelector(store),
            isEditOfferingBetaEnabled: EditOfferingBetaUIFeatureToggleSelector(store),
            isFeatureConfigurationLoaded: MetadataCodeLoadedSelector(CODES.FeatureConfiguration)(store),
            isNewConnectBetaEnabled: NewConnectBetaUIFeatureToggleSelector(store),
            isOverrideActive: IsOverrideActiveSelector(store),
            isRecentCustomersVisible: IsPanelVisibleSelector(store),
            isSearchPanelVisible: IsSearchPanelVisibleSelector(store),
            isSubscriberCategoryDefaultSubscriberTypeLoaded: MetadataCodeLoadedSelector(CODES.SubscriberCategoryDefaultSubscriberType)(store),
            isSubscriberSearchLoaded: MetadataCodeLoadedSelector(CODES.CustomerWrapUp, store),
            isWrappingCustomer: IsWrappingCustomerSelector(store),
            router: store.router,
            sessionId: SessionIdSelector(store),
            userSecurityAttributes: UserSecurityAttributesSelector(store)
        };
    };
    const controllerActions = {
        createOverrideSession,
        customerWrapUp,
        fetchCodeTypes,
        hideRecentCustomersPanel,
        hideSearchPanel,
        loadSubscriberRequirements,
        retrieveDiscountsMetadata,
        retrieveDiscretionaryDiscountsMetadata,
        stateGo,
        showRecentCustomersPanel,
        showSearchPanel
    };

    const BLANK_CUSTOMER = CREATE_CUSTOMER_INITIAL_STATE;

    let unsavedChangesPromptPopupApi = null;
    let createCustomerPopupApi = null;
    let unsavedChangesPromptConfirmCallback = null;

    this.$onInit = () => {

        // most browsers won't actually display this message, a violation of the
        // spec, in the name of protecting the unsuspecting users ("security"),
        // but we set it anyway, as a few do
        unsavedChangesPrompt.setMessage(i18n.translate(CustomerCareLocaleKeys.UNSAVED_CHANGES_PROMPT.MESSAGE));
        unsavedChangesPrompt.setInterceptor((confirm) => {
            unsavedChangesPromptConfirmCallback = confirm;
            unsavedChangesPromptPopupApi.open();
        });

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.customerCareLocaleKeys = CustomerCareLocaleKeys;
        this.coreLocaleKeys = CoreLocaleKeys;
        this.onOverride = this.onOverride.bind(this);

        this.navItems = LoadNavigatorItems();
        this.navFlyoutCaption = NavigatorFlyoutCaption();

        // hook the popupApi
        this.unsavedChangesPromptPopupConfig = {
            onRegisterApi: ({api: api}) => {
                unsavedChangesPromptPopupApi = api;
            }
        };

        this.showWelcomePage = () => {
            return this.state.router.currentState.name === customerCareRoute;
        };

        this.createCustomerPopupConfig = {
            onRegisterApi: (event) => {
                createCustomerPopupApi = event.api;
            }
        };

        this.customerWrapUpPopup = {
            onRegisterApi: ({api: api}) => {
                this.customerWrapUpPopupApi = api;
            }
        };

        this.editCustomer = BLANK_CUSTOMER.asMutable({
            deep: true
        });

        if (!this.state.isCustomerRequirementsLoaded) {
            this.actions.loadSubscriberRequirements();
        }

        if (!this.state.isConvergentBillerBehaviorConfigLoaded) {
            this.actions.fetchCodeTypes(CODES.ConvergentBillerBehaviorConfig);
        }

        if (!this.state.isSubscriberSearchLoaded) {
            this.actions.fetchCodeTypes(CODES.SubscriberSearchConfig);
        }

        this.actions.fetchCodeTypes(CODES.BusinessUnitTemplate).then(() => {
            setupReactRoutes($rootScope, $state, getReactRoutes({
                isAddOfferingBetaEnabled: this.state.isAddOfferingBetaEnabled,
                isChangeOfferingBetaEnabled: this.state.isChangeOfferingBetaEnabled,
                isEditOfferingBetaEnabled: this.state.isEditOfferingBetaEnabled,
                isNewConnectBetaEnabled: this.state.isNewConnectBetaEnabled,
                isRemoveOfferingBetaEnabled: this.state.isRemoveOfferingBetaEnabled,
                isDisconnectCustomerBetaEnabled: this.state.isDisconnectOfferingBetaEnabled
            }));
        });
        this.actions.retrieveDiscountsMetadata();
        this.actions.retrieveDiscretionaryDiscountsMetadata();

        if (!this.state.isFeatureConfigurationLoaded) {
            this.actions.fetchCodeTypes(CODES.FeatureConfiguration);
        }
        if (!this.state.isAccountingMethodLoaded) {
            this.actions.fetchCodeTypes(CODES.Accounting);
        }

        PanelAutoClose.start();
    };

    this.$onDestroy = () => {
        disconnectRedux();
        createCustomerPopupApi.close();
        PanelAutoClose.stop();
    };

    this.onSubmitCreateCustomerForm = (response) => {
        this.closeCreateCustomerPopup();
        uiNotificationService.success(i18n.translate(this.customerCareLocaleKeys.CREATE_CUSTOMER_FORM.SUCCESS), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });

        $timeout(() => {
            this.actions.stateGo('index.customercare.customer', {
                customerId: response.Subscriber.Id
            });
        });
    };

    this.handleToggleNavigatorCollapsedSelected = () => {
        this.actions.toggleCollapsedSelected();
    };

    this.itemSelected = (navItem) => {
        this.clearActiveFlagsOnNavItems();

        if (navItem.id === SEARCH_NAV_ITEM_ID) {
            if (this.bypassCustomerWrapup()) {
                if (this.state.isSearchPanelVisible) {
                    this.actions.hideSearchPanel();
                } else {
                    this.actions.showSearchPanel();
                }
            }

            navItem.isActive = this.state.isSearchPanelVisible;
        } else if (navItem.id === RECENT_CUSTOMERS_NAV_ITEM_ID) {
            if (this.bypassCustomerWrapup()) {
                if (this.state.isRecentCustomersVisible) {
                    this.actions.hideRecentCustomersPanel();
                } else {
                    this.actions.showRecentCustomersPanel();
                }

                navItem.isActive = this.state.isRecentCustomersVisible;
            }
        }
    };

    this.clearActiveFlagsOnNavItems = () => {
        this.navItems.forEach((navItem) => {
            navItem.isActive = false;
        });
    };

    this.flyoutItemSelected = ({id}) => {
        if (this.bypassCustomerWrapup()) {
            switch (id) {
                case CREATE_COMMERCIAL_CUSTOMER_GLOBAL_ACTION.id: {
                    this.openCreateCustomerPopup(CUSTOMER_CATEGORY.COMMERCIAL);
                    break;
                }
                case CREATE_CUSTOMER_GLOBAL_ACTION.id: {
                    this.openCreateCustomerPopup();
                    break;
                }
                case CREATE_RESIDENTIAL_CUSTOMER_GLOBAL_ACTION.id: {
                    this.openCreateCustomerPopup(CUSTOMER_CATEGORY.RESIDENTIAL);
                    break;
                }
                case NEW_CONNECT_GLOBAL_ACTION.id: {
                    this.actions.stateGo(NEW_CONNECT_STATE, {}, {
                        location: true,
                        notify: true,
                        reload: true
                    });
                    break;
                }
                default:
                    break;
            }
        }
    };

    this.appId = CUSTOMERCARE_PAGE_APP_ID;

    this.openCreateCustomerPopup = (customerCategory) => {
        this.customerCategory = customerCategory;

        this.editCustomer = BLANK_CUSTOMER.asMutable({
            deep: true
        });
        this.showCustomerPopup = true;

        if (!this.state.isCustomerRequirementsLoaded) {
            this.actions.loadSubscriberRequirements().then(() => {
                $scope.$applyAsync(() => {
                    createCustomerPopupApi.open();
                });
            });
        } else {
            $timeout(() => {
                createCustomerPopupApi.open();
            });
        }
    };

    this.closeCreateCustomerPopup = () => {
        createCustomerPopupApi.close();
        this.showCustomerPopup = false;
    };

    this.handleResultUnsavedChangesPromptPopup = (confirmed) => {
        confirmed && unsavedChangesPromptConfirmCallback();
        unsavedChangesPromptConfirmCallback = null;
        unsavedChangesPromptPopupApi.close();
    };

    this.handleCloseUnsavedChangesPromptPopup = () => {
        unsavedChangesPromptPopupApi.close();
    };

    this.openCustomerWrapUpPopup = () => {
        this.showCustomerWrapUp = true;
        $timeout(this.customerWrapUpPopupApi.open);
    };

    this.closeCustomerWrapupModal = () => {
        this.showCustomerWrapUp = false;
        this.customerWrapUpPopupApi.close();
    };

    this.onCustomerWrapUp = () => {
        this.isWrapupValid
            && this.actions.customerWrapUp({
                customerId: this.state.currentCustomerId,
                reasonId: this.wrapupReason,
                comments: this.wrapupComments
            })
                .then(() => {
                    this.closeCustomerWrapupModal();
                    this.actions.stateGo(ROOT_CARE_MODULE_ROUTE);
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
    };

    this.bypassCustomerWrapup = () => {
        return this.state.isCustomerWrapupRequired && !!this.state.currentCustomerId
            ? this.openCustomerWrapUpPopup()
            : true;
    };

    this.hasOverrideAccess = () => {
        return this.state && this.state.userSecurityAttributes ?
            hasAccess(this.state.userSecurityAttributes, OVERRIDE_OFFER_QUALIFICATION_ENFORCEMENT) :
            undefined;
    };

    this.onOverride = () => {
        this.actions.createOverrideSession(this.state.sessionId, !this.state.isOverrideActive, MODULES.CUSTOMER_CARE);
    };

    this.onWrapupUpdate = (formModel) => {
        const wrapupReasonCode = pathOr(undefined, ['reason', 'Value'], formModel);
        this.isWrapupValid = !!wrapupReasonCode;
        this.wrapupReason = wrapupReasonCode;
        this.wrapupComments = formModel.comments;
    };
}
