import {INVENTORY_STATE_NAME} from '../../../../inventory/inventory.config';
import CustomerLocaleKeys from '../../../../../../locales/keys';


class FinancingAndInventoryController {
    constructor() {
        Object.assign(this, {
            CustomerLocaleKeys,
            INVENTORY_STATE_NAME,
            INVENTORY_DETAILS_STATE_REDIRECT: 'index.customercare.customer.inventoryDetails'
        });
    }
}

export default {
    bindings: {
        customerId: '<',
        currencyCode: '<',
        inventoryItems: '<',
        inventoryItemsCount: '<'
    },
    template: require('./financing.and.inventory.html'),
    controller: FinancingAndInventoryController
};