import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../../locales/keys';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';
import {DateTimeHelper} from 'invision-core';
export const ADJUSTMENTS = 2;
export const INVOICES = 1;
export const CREDIT_NOTES = 16;
export const DEBIT_NOTES = 32;
export const PAYMENTS = 4;
export const MISC = 2;
export const ITEM = 1;
export const OPEN_INVOICES = 64;
export const OVERALL = 0;
export const DEPOSITS = 8;
export const FUTURE_INVOICE_URL = 'index.customercare.customer.billingpayments.futureinvoice';
export const FUTURE_INVOICE_DETAILS_URL = 'index.customercare.customer.billingpayments.futureinvoicedetails';
export const BILLING_INVOICE_DETAILS_URL = 'index.customercare.customer.billingpayments.detail';
export const FUTURE_INVOICE_CALCULATION_COMPLETION_STATUS = {
    IN_PROGRESS: 1,
    READY: 2
};
export const FUTURE_INVOICE_START_CALCULATION_ACTIONS = {
    START_CALCULATION: 1,
    CHECK_STATUS_OR_START: 2
};
export default {
    ADJUSTMENTS: ADJUSTMENTS,
    INVOICES: INVOICES,
    PAYMENTS: PAYMENTS,
    OVERALL : OVERALL,
    MISC: MISC,
    ITEM: ITEM
};

export const ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS = {
    APPLY_CREDIT_NOTE: 1,
    VIEW_TRANSACTION_DETAILS: 2
};

export const FUTURE_INVOICE_COLUMNDEFS = [
    {
        name: 'Date Generated',
        displayName: i18n.translate(LocaleKeys.DATE_GENERATED),
        cellTemplate: require('./futureInvoices/cellTemplates/future.invoices.date.html'),
        enableSorting: false,
        resizable: false,
        width: '250'
    },
    {
        name: 'Invoice Dates',
        displayName: i18n.translate(LocaleKeys.INVOICE_DATES),
        cellTemplate: require('./futureInvoices/cellTemplates/future.invoices.duration.html'),
        enableSorting: false,
        resizable: false,
    },
    {
        name: 'Estimated Invoice Amount',
        displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.ESTIMATED_INVOICE_AMOUNT),
        cellTemplate: require('./futureInvoices/cellTemplates/future.invoices.estimated.amount.html'),
        enableSorting: false,
        resizable: false,
        width: '228'
    },
    {
        name:'View Details',
        displayName: '',
        cellTemplate: require('./futureInvoices/cellTemplates/future.invoices.open.new.window.html'),
        enableSorting: false,
        resizable: false,
        width: '150'
    }
];
export const FUTURE_INVOICE_REFRESH_TIMEOUT = 5;
export const MISC_ADJUSTMENT_ID = 0;
export const CREATE_NOTE_ID = 1;
export const APPLY_TAX_CERTIFICATE_ID = 2;
export const ENABLE_CREATE_NOTE = 'enable_credit_debit_note';
export const ADJUSTMENT_CONFIG_ENABLED = 'True';
export const ENABLE_GENERATE_STATEMENT = 'generate_statement';
export const CREDIT_NOTE_TYPE = 'Credit';
export const billingLedgerTabList = () => {
    return {
        OPEN_INVOICES: {
            active: false,
            name: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.OPEN_INVOICES),
            key: 'OPEN_INVOICES'
        },
        ACCOUNT_SUMMARY: {
            active: false,
            name: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.ACCOUNT_SUMMARY),
            key: 'ACCOUNT_SUMMARY'
        },
        DEPOSITS: {
            active: false,
            name: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.DEPOSITS),
            key: 'DEPOSITS'
        }
    };
};

export const OPEN_INVOICES_COL_DEFS = [
    {
        field: 'invoice',
        displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE),
        cellTemplate: require('./list/tabs/cellTemplates/openInvoice.invoice.template.html'),
        width: '*'
    },
    {
        field: 'invoiceType',
        displayName: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.INVOICE_TYPE),
        width: '*'
    },
    {
        field: 'dateCreated',
        displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.DATE_CREATED),
        width: '*',
        enableSorting: true,
        sortingAlgorithm: DateTimeHelper.dateTimeComparator
    },
    {
        field: 'charge',
        displayName: i18n.translate(LocaleKeys.CHARGE),
        cellTemplate: require('./list/tabs/cellTemplates/openInvoice.charge.template.html'),
        width: 200,
        minWidth: 70,
        cellClass: 'c-dataType-number'
    }, {
        field: 'dueDate',
        displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.DUE_DATE),
        minWidth: 70,
        enableSorting: true,
        sortingAlgorithm: DateTimeHelper.dateTimeComparator
    },
    {
        field: 'payments',
        displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.PAYMENTS),
        cellTemplate: require('./list/tabs/cellTemplates/openInvoice.payment.template.html'),
        headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
        cellClass: 'c-dataType-number',
        minWidth: 70
    },
    {
        field: 'adjustments',
        displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.ADJUSTMENTS),
        cellTemplate: require('./list/tabs/cellTemplates/openInvoice.adjustments.template.html'),
        headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
        cellClass: 'c-dataType-number',
        minWidth: 70
    },
    {
        field: 'invoiceBalance',
        displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_BALANCE),
        cellTemplate: require('./list/tabs/cellTemplates/openInvoice.invoice.balance.template.html'),
        headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
        cellClass: 'c-dataType-number',
        minWidth: 70
    }
];

export const BILLING_PAYMENTS_SEARCH_PLACEHOLDER = () => {
    return `${i18n.translate(LocaleKeys.FILTER_SEARCH_PLACEHOLDER)} ${i18n.translate(LocaleKeys.INVOICE_NUMBER)}`;
};

export const BILL_ADJUSTMENT = 1001;
export const MISC_CREDIT_ADJUSTMENT = 1002;
export const CONVERSION_CREDIT_ADJUSTMENT = 1004;
export const CONVERSION_DEBIT_ADJUSTMENT = 1005;
export const CREDIT_NOTE_ADJUSTMENT = 1006;
export const CONVERT_TO_CREDIT = 1006;
export const DEBIT_NOTE_ADJUSTMENT = 1007;