import {STATE_NAME} from './transition.offer.wizard.constants';
import {
    MetadataActions,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {
    TransitionIsAllowedSelector
} from '../../../../reducers/selectors/transition.offer.wizard.selectors';

export function routing($stateProvider) {
    $stateProvider
        .state(STATE_NAME, {
            url: '/offering/:offeringId/offeringInstance/:offeringInstanceId/transition',
            ignoreBack: true,
            template: '<transition-offer class="u-sizeFull u-flexKeepHeight"></transition-offer>',
            resolve: {
                offerIdIsValid: ($q, $stateParams) => {
                    const offerId = $stateParams.offeringId;
                    if (offerId !== undefined && offerId.trim().length && !isNaN(offerId)) {
                        return $q.resolve();
                    } else {
                        return $q.reject({
                            redirectTo: 'index.customercare.customer.dashboard',
                            toParams: {
                                customerId: $stateParams.customerId
                            }
                        });
                    }
                },
                trasitionIsAllowed: ($q, $ngRedux, $stateParams) => {
                    return retrieveTransitionConfiguration($ngRedux.dispatch, $ngRedux.getState(), $q).then(() => {
                        if (TransitionIsAllowedSelector($ngRedux.getState())) {
                            return $q.resolve();
                        } else {
                            return $q.reject({
                                redirectTo: 'index.customercare.customer.dashboard',
                                toParams: {
                                    customerId: $stateParams.customerId
                                }
                            });
                        }
                    });
                }
            }
        });

    const retrieveTransitionConfiguration = (dispatch, state, $q) => {
        if (!MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TransitionConfiguration, state)) {
            return MetadataActions.codes.fetchCodeTypesPromise(dispatch, MetadataConstants.codes.TransitionConfiguration);
        } else {
            return $q.resolve();
        }
    };
}
