import {stateGo} from 'redux-ui-router';
import merge from 'ramda/src/mergeRight';
import omit from 'ramda/src/omit';
import pathOr from 'ramda/src/pathOr';
import isEmpty from 'ramda/src/isEmpty';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {LastRouteSelector} from 'invision-core/src/components/session/session.selectors';
import {DASHBOARD_ROUTE} from '../../../../reducers/constants/dashboard.constants';

import {
    USAGE_CAPS_ELECTION_LEVEL,
    USAGE_CAPS_THRESHOLD_EXTRA_PROPS,
    USAGE_CAPS_URL,
    USAGE_CONTROL_TABS,
    USAGE_NOTIFICATION_METHOD
} from './usage.caps.constants';
import {
    CurrentCustomerIdSelector,
    CurrentServiceIdSelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    AccountLevelUsageCaps,
    AccountLevelUsageNotification,
    IsUpdatingUsageCapSelector,
    IsUsageCapsElectionDisabledSelector,
    IsUsageCapsEnabledSelector,
    MappedUsageCapsSelector,
    MappedUsageNotificationEnabledSelector,
    NoUsageCapsMessageSelectors,
    RetrievedUsageCaps,
    SelectedAccountLevelSelector,
    UsageCapsElectionsSelector
} from '../../../../reducers/selectors/usage.caps.selectors';
import {
    retrieveUsageCaps,
    updateUsageCapsElections,
    updateUsageCapsDetails
} from '../../../../reducers/actions/customer.usage.caps.actions';
import {setSelectAccountLevel} from '../../../../reducers/actions/services.actions';

export class UsageCapsController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CoreLocaleKeys: CoreLocaleKeys,
            CustomerCareLocaleKeys: CustomerCareLocaleKeys,
            dashboardUrl: DASHBOARD_ROUTE,
            onTabSelected: this.onTabSelected.bind(this),
            showServiceLevelUsageNotificationsHeader: false,
            showServiceLevelUsageCapsHeader: false,
            stateOrName: USAGE_CAPS_URL,
            uiNotificationService: uiNotificationService,
            usageControlTabs: USAGE_CONTROL_TABS,
            closeUsageCapsMessage: this.closeUsageCapsMessage.bind(this)
        });
    }

    $onInit() {
        this.mappedUsageCaps = [];
        this.usageCapsElections = [];
        this.pageTitle = '';

        const mapStateToTarget = (store) => {
            return {
                allServicesUsageCaps: AccountLevelUsageCaps(store),
                allServicesUsageNotification: AccountLevelUsageNotification(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                isUsageCapsElectionDisabled: IsUsageCapsElectionDisabledSelector(store),
                isUsageCapsEnabled: IsUsageCapsEnabledSelector(store),
                isRetrievingUsageCaps: RetrievedUsageCaps.isFetchingDataSelector(store),
                isAccountLevelSelected: SelectedAccountLevelSelector(store),
                isServiceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                isUnitOfMeasurementLoaded: MetadataCodeLoadedSelector(CODES.UnitsOfMeasure, store),
                isUpdatingUsageCaps: IsUpdatingUsageCapSelector(store),
                lastRoute: LastRouteSelector(store),
                mappedUsageCaps: MappedUsageCapsSelector(store),
                mappedUsageNotification: MappedUsageNotificationEnabledSelector(store),
                noUsageCapsMenuMessage: NoUsageCapsMessageSelectors(store),
                serviceId: CurrentServiceIdSelector(store),
                retrievedUsageCaps: RetrievedUsageCaps.dataSelector(store),
                unitOfMeasures: MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure, store),
                usageCapsElections: UsageCapsElectionsSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeTypesThunk,
            retrieveUsageCaps,
            stateGo,
            updateUsageCapsElections,
            updateUsageCapsDetails,
            setSelectAccountLevel
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.isServiceAttributesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.ServiceAttribute);
        }

        if (!this.state.isUnitOfMeasurementLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.UnitsOfMeasure);
        }

        this.actions.retrieveUsageCaps(this.state.currentCustomerId, null)
            .then(() => {
                this.allServicesUsageCaps = this.state.allServicesUsageCaps.asMutable({
                    deep: true
                });
                this.allServicesUsageNotification = this.state.allServicesUsageNotification.asMutable({
                    deep: true
                });
                this.mappedUsageCaps = this.state.mappedUsageCaps.asMutable({
                    deep: true
                });
                this.mappedUsageNotification = this.state.mappedUsageNotification.asMutable({
                    deep: true
                });
                this.usageCapsElections = this.state.usageCapsElections.asMutable({
                    deep: true
                });
                this.pageTitle = this.getUsageCapsPageTitle();
                this.showServiceLevelUsageCapsHeader =  Object.keys(this.mappedUsageCaps).length;
                this.showServiceLevelUsageNotificationsHeader =  Object.keys(this.mappedUsageNotification).length;
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            })
            .finally(() => {});

        this.termsAndConditionsPopUp = {
            onRegisterApi: ({api}) => {
                this.termsAndConditionsPopUp = api;
            }
        };

        this.changeUsageCapMessagePopUp = {
            onRegisterApi: (evt) => {
                this.changeUsageCapMessagePopUp = evt.api;
            }
        };

        if (this.state.isAccountLevelSelected) {
            this.tabs = [
                {
                    id: USAGE_CONTROL_TABS.ACCOUNT_LEVEL,
                    name: i18n.translate(
                        this.CustomerCareLocaleKeys.USAGE_CAPS.ACCOUNT_LEVEL
                    ),
                    active: true
                }
            ];
        } else {
            this.tabs = [
                {
                    id: USAGE_CONTROL_TABS.SERVICE_LEVEL,
                    name: i18n.translate(
                        this.CustomerCareLocaleKeys.USAGE_CAPS.SERVICE_LEVEL
                    ),
                    active: true
                },
                {
                    id: USAGE_CONTROL_TABS.ACCOUNT_LEVEL,
                    name: i18n.translate(
                        this.CustomerCareLocaleKeys.USAGE_CAPS.ACCOUNT_LEVEL
                    ),
                    active: false
                }
            ];
        }
    }

    $onDestroy() {
        this.actions.setSelectAccountLevel(false);
        this.disconnectRedux();
    }

    onTabSelected(selectedTab) {
        this.tabs = this.tabs.map((tab) => {
            tab.active = selectedTab.id === tab.id;
            return tab;
        });
    }

    openTermsAndConditionsPopUp(usageCapTermsAndConditions) {
        this.currentTermsAndConditions = usageCapTermsAndConditions;
        this.termsAndConditionsPopUp.open();
    }

    closeTermsAndConditionsPopUp() {
        this.currentTermsAndConditions = {};
        this.termsAndConditionsPopUp.close();
    }

    isLoading() {
        return this.state.isRetrievingUsageCaps || this.state.isUpdatingUsageCaps;
    }

    getUsageCapsPageTitle() {
        const serviceKeys = Object.keys(this.state.mappedUsageCaps);
        const serviceKeysLength = serviceKeys.length;
        //Showing Service number when viewing a specific service or having only 1 service under 'All Services' section
        if (this.state.isAccountLevelSelected) {
            return i18n.translate(this.CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CONTROLS_LABEL);

        } else if (serviceKeysLength === 1 || serviceKeysLength <= 1 && this.state.serviceId) {
            const serviceId = this.state.serviceId || serviceKeys[0];

            return i18n.translate(this.CustomerCareLocaleKeys.USAGE_CAPS.SINGLE_SERVICE_TITLE, {
                serviceId: pathOr(serviceId, [serviceId, 0, 'ServiceIdentifier', 'FormattedValue'], this.state.mappedUsageCaps),
            });
        }
        return i18n.translate(this.CustomerCareLocaleKeys.USAGE_CAP_SETTINGS);
    }

    getUomShortName(uomCode) {
        const unitCode = this.state && this.state.unitOfMeasures[uomCode];
        return unitCode && (unitCode.AdditionalProperties.short_name || unitCode.Name) || '';
    }

    getTooltipContent(usageCap) {
        if (usageCap.UsageCapUsageNotification.NotificationMethod === USAGE_NOTIFICATION_METHOD.BY_INCREMENTAL_VALUE && usageCap.UsageCapUsageNotification.NumberOfResets) {
            return i18n.translate(this.CustomerCareLocaleKeys.USAGE_CAPS.NOTIFICATION_TOOLTIP_BY_INCREMENTAL_VALUE_LIMITED_RESETS, {
                sendNotificationEvery: usageCap.UsageCapUsageNotification.SendNotificationEvery,
                notificationEveryUoM: this.getUomShortName(usageCap.UsageCapUsageNotification.SendNotificationEveryUoM),
                resetCount: usageCap.UsageCapUsageNotification.NumberOfResets,
            });
        } else if (usageCap.UsageCapUsageNotification.NotificationMethod === USAGE_NOTIFICATION_METHOD.BY_INCREMENTAL_VALUE && !usageCap.UsageCapUsageNotification.NumberOfResets) {
            return i18n.translate(this.CustomerCareLocaleKeys.USAGE_CAPS.NOTIFICATION_TOOLTIP_BY_INCREMENTAL_VALUE_UNLIMITED_RESETS, {
                sendNotificationEvery: usageCap.UsageCapUsageNotification.SendNotificationEvery,
                notificationEveryUoM: this.getUomShortName(usageCap.UsageCapUsageNotification.SendNotificationEveryUoM)
            });
        } else if (usageCap.UsageCapUsageNotification.NotificationMethod === USAGE_NOTIFICATION_METHOD.PERCENTAGE) {
            return i18n.translate(this.CustomerCareLocaleKeys.USAGE_CAPS.NOTIFICATION_TOOLTIP_PERCENTAGE);
        }
    }

    closeUsageCapsMessage() {
        this.changeUsageCapMessagePopUp.close();
    }

    emptyCheck(val) {
        return isEmpty(val);
    }

    //Overwrite services when All Services properties are changed
    overwriteIndividualServices(usageCap, activate = undefined, electedThreshold = usageCap.ElectedThreshold, toActivate = false) {
        if (!activate && toActivate) {
            this.changeUsageCapMessagePopUp.open();
        }
        if (usageCap) {
            const filterCorrespondingUsageCaps = ((usageCapElection) => {
                return usageCapElection.Id === usageCap.Id &&
                    usageCapElection.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.SERVICE_LEVEL;
            });

            const filterCorrespondingCustomerUsageCaps = ((usageCapElection) => {
                return usageCapElection.Id === usageCap.Id &&
                    usageCapElection.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.CUSTOMER_LEVEL;
            });

            const overwrittenUsageCaps = this.usageCapsElections
                .filter(filterCorrespondingUsageCaps)
                .map((usageCapElection) => {
                    return merge(usageCapElection, {
                        Elected: false
                    });
                });

            const overwrittenCustomerUsageCaps = this.usageCapsElections
                .filter(filterCorrespondingCustomerUsageCaps)
                .map((usageCapElection) => {
                    return merge(usageCapElection, {
                        Elected: activate !== undefined ? activate : usageCapElection.Elected,
                        ElectedThreshold: electedThreshold ? electedThreshold : usageCapElection.ElectedThreshold
                    });
                });

            overwrittenUsageCaps.forEach((overwrittenUsageCap) => {
                const getUsageCapIndex = (usageCapElection) => {
                    return usageCapElection.Id === overwrittenUsageCap.Id
                        && usageCapElection.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.SERVICE_LEVEL
                        && usageCapElection.ServiceIdentifier.Value === overwrittenUsageCap.ServiceIdentifier.Value;
                };
                const modifiedCapIndex = this.usageCapsElections.findIndex(getUsageCapIndex, {
                    overwrittenUsageCap
                });
                //TODO: omit extra properties for the UI (displayName, selected)
                this.usageCapsElections[modifiedCapIndex] = overwrittenUsageCap;
            });

            overwrittenCustomerUsageCaps.forEach((overwrittenUsageCap) => {
                const getUsageCapIndex = (usageCapElection) => {
                    return usageCapElection.Id === overwrittenUsageCap.Id
                        && usageCapElection.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.CUSTOMER_LEVEL;
                };
                const modifiedCapIndex = this.usageCapsElections.findIndex(getUsageCapIndex, {
                    overwrittenUsageCap
                });
                //TODO: omit extra properties for the UI (displayName, selected)
                this.usageCapsElections[modifiedCapIndex] = overwrittenUsageCap;
            });

            this.actions.updateUsageCapsDetails(this.usageCapsElections)
                .then(() => {
                    this.mappedUsageCaps = this.state.mappedUsageCaps.asMutable({
                        deep: true
                    });
                    this.allServicesUsageCaps = this.state.allServicesUsageCaps.asMutable({
                        deep: true
                    });
                    this.allServicesUsageNotification = this.state.allServicesUsageNotification.asMutable({
                        deep: true
                    });
                });
        }
    }

    updateUsageCapsDetails(usageCap) {
        if (usageCap) {
            const getUsageCapIndex = (usageCapElection) => {
                return usageCapElection.Id === usageCap.Id
                    && usageCapElection.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.SERVICE_LEVEL
                    && usageCapElection.ServiceIdentifier.Value === usageCap.ServiceIdentifier.Value;
            };
            const modifiedCapIndex = this.usageCapsElections.findIndex(getUsageCapIndex);
            //TODO: omit extra properties for the UI (displayName, selected)
            this.usageCapsElections[modifiedCapIndex] = usageCap;

            const getCustomerUsageCapIndex = (usageCapElection) => {
                return usageCapElection.Id === usageCap.Id
                    && usageCapElection.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.CUSTOMER_LEVEL;
            };
            //TODO: omit extra properties for the UI (displayName, selected)
            this.actions.updateUsageCapsDetails(this.usageCapsElections)
                .then(() => {
                    this.allServicesUsageCaps = this.state.allServicesUsageCaps.asMutable({
                        deep: true
                    });
                    this.allServicesUsageNotification = this.state.allServicesUsageNotification.asMutable({
                        deep: true
                    });
                });
        }
    }

    applyUsageCapsElections() {
        if (this.usageCapsElections) {
            const mappedUsageCaps = this.usageCapsElections.map((usageCapElection) => {
                const {Id, ServiceIdentifier, Elected = false, ElectedThreshold, ElectionLevel} = usageCapElection;

                return {
                    Id,
                    ServiceIdentifier,
                    Elected,
                    ElectionLevel,
                    ElectedThreshold: Elected && (!usageCapElection?.UsageNotificationEnabled || usageCapElection?.UsageCapEnabled) ? omit(USAGE_CAPS_THRESHOLD_EXTRA_PROPS, ElectedThreshold) : undefined
                };
            });
            this.actions.updateUsageCapsElections(this.state.currentCustomerId, {
                'UsageCapElections': mappedUsageCaps
            })
                .then(() => {
                    this.uiNotificationService.success(i18n.translate(this.CustomerCareLocaleKeys.USAGE_CAPS.UPDATE_SUCCESS));
                    this.UsageCapDetailsForm.$setPristine();
                    this.goBack();
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    showIndividualServicesHeader() {
        return this.state.mappedUsageCaps && Object.keys(this.state.mappedUsageCaps).length > 1;
    }

    goBack() {
        const route = this.state.lastRoute.name || this.dashboardUrl;
        const params = this.state.lastRoute.params || {};
        this.actions.stateGo(route, params);
    }
}

export default {
    template: require('./usage.caps.html'),
    controller: UsageCapsController,
    controllerAs: 'UsageCaps'
};
