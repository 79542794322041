import __ from 'ramda/src/__';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import coreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import customerLocaleKeys from '../../../../../locales/keys';
import {
    retrieveEntitlementUsage,
    retrieveSharedEntitlements,
    setCurrentPage,
    setEntitlementShareStatus,
    setEntitlementsPaginationData,
    setEntitlementsSearchTerm,
    setSelectedSharedEntitlementIndex,
    setSharedEntitlementsPageSize,
    updateEntitlementShareStatus
} from '../../../../../reducers/actions/shared.entitlements.actions';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {
    currentPageNumberSelector,
    entitlementRecordCountSelector,
    entitlementsPaginationDataSelector,
    entitlementUsagesTableDataSelector,
    formattedEntitlementsSelector,
    isFetchingEntitlementsSelector,
    isUpdatingEntitlementShareStatusSelector,
    isFetchingEntitlementUsageSelector,
    selectedEntitlementIndexSelector,
    selectedEntitlementSelector,
    sharedEntitlementsPageSizeSelector
} from '../../../../../reducers/selectors/shared.entitlements.selectors';
import {
    DEFAULT_PAGE_NUMBER,
    DEFAULT_MAXIMUM_SLOTS
} from './shared.entitlements.list.constants';
import {
    ENTITLEMENT_CONTEXT_TYPES,
    ENTITLEMENT_SHARE_STATUS,
    UNIT_OF_MEASURES
} from '../../../../../customercare.constants';
import {SERVICE_USAGE_DETAILS_STATE_NAME} from '../../../servicesAndUsage/usageDetails/usage.details.config';
import {NA} from '../../../../../reducers/constants/billing.payments.constants';
import {TRANSACTION_DETAILS_STATE} from '../../../transactions/transactions.config';
import {ConvergentBillerCurrencyCodeSelector} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {SERVICES_AND_SHARED_ENTITLEMENTS} from '../../../servicesAndUsage/services.and.usage.config';

class SharedEntitlementsController {
    constructor($ngRedux, $state, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            coreLocaleKeys,
            customerLocaleKeys,
            ENTITLEMENT_CONTEXT_TYPES,
            handleSubscriberIdClick: this.handleSubscriberIdClick.bind(this),
            maximumSlots: DEFAULT_MAXIMUM_SLOTS,
            NA,
            onEntitlementsPageSelected: this.onEntitlementsPageSelected.bind(this),
            onEntitlementsPageSizeOptionSelected: this.onEntitlementsPageSizeOptionSelected.bind(this),
            onSearchSubmitted: this.onSearchSubmitted.bind(this),
            SERVICE_USAGE_DETAILS_STATE_NAME,
            TRANSACTION_DETAILS_STATE,
            uiNotificationService,
            UNIT_OF_MEASURES
        });
    }

    $onInit() {
        const codeTables = [
            CODES.UnitsOfMeasure,
            CODES.UsageUnitConversion
        ];
        const controllerActions = {
            fetchCodeTypes,
            retrieveEntitlementUsage,
            retrieveSharedEntitlements,
            setCurrentPage,
            setEntitlementShareStatus,
            setEntitlementsPaginationData,
            setEntitlementsSearchTerm,
            setSelectedSharedEntitlementIndex,
            setSharedEntitlementsPageSize,
            stateGo,
            updateEntitlementShareStatus
        };

        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                currencyCode: ConvergentBillerCurrencyCodeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPageNumber: currentPageNumberSelector(store),
                entitlementUsageTableData: entitlementUsagesTableDataSelector(store),
                entitlements: formattedEntitlementsSelector(store),
                entitlementsPaginationData: entitlementsPaginationDataSelector(store),
                isFetchingEntitlementUsage: isFetchingEntitlementUsageSelector(store),
                isFetchingEntitlements: isFetchingEntitlementsSelector(store),
                isUpdatingEntitlementShareStatus: isUpdatingEntitlementShareStatusSelector(store),
                pageSize: sharedEntitlementsPageSizeSelector(store),
                recordCount: entitlementRecordCountSelector(store),
                selectedEntitlement: selectedEntitlementSelector(store),
                selectedEntitlementIndex: selectedEntitlementIndexSelector(store)
            };
        };

        this.entitlementTooltip = require('../../../../../components/shared/tooltipTemplates/entitlement.tooltip.html');

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });
        this.entitlementColumn = {
            field: 'entitlementName',
            displayName: i18n.translate(customerLocaleKeys.ENTITLEMENT),
            visible: false
        };
        this.subscriberIdColumn = {
            field: 'subscriberId',
            displayName:  i18n.translate(customerLocaleKeys.SERVICES_AND_USAGE.SHARED_ENTITLEMENTS.SUBSCRIBER_ID),
            cellTemplate: require('./cellTemplates/subscriberId.html'),
            visible: false
        };
        this.servicesGrid = {
            appScopeProvider: this,
            enableSorting: false,
            data: this.state.entitlementUsageTableData,
            onRegisterApi: (gridApi) => {
                this.serviceGridApi = gridApi;
            },
            columnDefs: [{
                field: 'serviceId',
                displayName: i18n.translate(customerLocaleKeys.IDENTIFIER_ABBREVIATION),
                cellTemplate: require('./cellTemplates/serviceId.html')
            }, {
                field: 'friendlyName',
                displayName:  i18n.translate(customerLocaleKeys.SERVICES_AND_USAGE.SHARED_ENTITLEMENTS.FRIENDLY_NAME),
            },
            this.subscriberIdColumn,
            this.entitlementColumn,
            {
                field: 'ownerLabel',
                displayName:  '',
                cellTemplate: require('./cellTemplates/owner.html')
            }, {
                field: 'usage',
                displayName:  i18n.translate(customerLocaleKeys.SERVICES_AND_USAGE.SHARED_ENTITLEMENTS.USAGE),
                cellTemplate: require('./cellTemplates/usage.html')
            }]
        };

        this.onEntitlementsPageSelected(DEFAULT_PAGE_NUMBER);
    }

    fetchSharedEntitlements() {
        this.actions.retrieveSharedEntitlements(
            this.state.currentCustomerId, this.state.entitlementsPaginationData.searchTerm,
            this.state.entitlementsPaginationData.pageNumber, this.state.entitlementsPaginationData.pageSize
        ).then(() => {
            if (this.state.entitlements.length) {
                this.retrieveEntitlementUsage();
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onSearchSubmitted(searchTerm) {
        this.actions.setEntitlementsSearchTerm(searchTerm);
        this.actions.setEntitlementsPaginationData(DEFAULT_PAGE_NUMBER, this.state.entitlementsPaginationData.pageSize);
        this.fetchSharedEntitlements();
    }

    onEntitlementsPageSelected(pageNumber) {
        this.actions.setEntitlementsPaginationData(pageNumber, this.state.entitlementsPaginationData.pageSize);
        this.fetchSharedEntitlements();
    }

    onEntitlementsPageSizeOptionSelected(pageSize) {
        this.actions.setEntitlementsPaginationData(DEFAULT_PAGE_NUMBER, pageSize);
        this.fetchSharedEntitlements();
    }

    selectEntitlement(entitlementIndex) {
        if (entitlementIndex === this.state.selectedEntitlementIndex) {
            return;
        }
        this.actions.setSelectedSharedEntitlementIndex(entitlementIndex);
        this.actions.setCurrentPage(DEFAULT_PAGE_NUMBER);
        this.retrieveEntitlementUsage();
    }

    retrieveEntitlementUsage() {
        this.entitlementColumn.visible = !!(this.state.selectedEntitlement &&
                                            this.state.selectedEntitlement.EntitlementIdentifier &&
                                            this.state.selectedEntitlement.EntitlementIdentifier.GroupCode);

        this.servicesGrid.data = [];
        return this.actions.retrieveEntitlementUsage(
            this.state.currentCustomerId,
            this.state.selectedEntitlement.EntitlementIdentifier,
            this.state.currentPageNumber,
            this.state.pageSize
        ).then(() => {
            this.subscriberIdColumn.visible = this.state.selectedEntitlement.EntitlementContextTypeCode === ENTITLEMENT_CONTEXT_TYPES.HIERARCHY &&
                this.state.entitlementUsageTableData.data &&
                this.state.entitlementUsageTableData.data.some((service) => {
                    return service.subscriberId;
                });
            this.servicesGrid.data = this.state.entitlementUsageTableData;
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    updateEntitlementShareStatus(entitlement) {
        const newStatus = entitlement.SharingStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
            ? ENTITLEMENT_SHARE_STATUS.DISABLED
            : ENTITLEMENT_SHARE_STATUS.ENABLED;
        this.actions.updateEntitlementShareStatus(this.state.currentCustomerId, entitlement.EntitlementIdentifier, newStatus).then(() => {
            this.actions.setEntitlementShareStatus(this.state.entitlements.indexOf(entitlement), newStatus);
            this.uiNotificationService.transientSuccess(i18n.translate(
                newStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
                    ? this.customerLocaleKeys.SHARING_ACTIONS.ENABLE_SUCCESS
                    : this.customerLocaleKeys.SHARING_ACTIONS.DISABLE_SUCCESS));
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    handleSubscriberIdClick(subscriberId) {
        this.$state.go(SERVICES_AND_SHARED_ENTITLEMENTS, {
            customerId: subscriberId
        });
    }

    pageSizeOptionSelected(pageSize) {
        this.actions.setSharedEntitlementsPageSize(pageSize);
        this.onPageSelected(DEFAULT_PAGE_NUMBER);
    }

    onPageSelected(pageNumber) {
        this.actions.setCurrentPage(pageNumber);
        this.retrieveEntitlementUsage();
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    controller: SharedEntitlementsController,
    template: require('./shared.entitlements.template.html')
};
