import {stateGo} from 'redux-ui-router';
import {USAGE_CAPS_URL} from '../../../servicesAndUsage/usageCaps/usage.caps.constants';
import {MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME} from '../../../../../reducers/constants/products.wizard.constants';
import {
    SERVICES_AND_SHARED_ENTITLEMENTS,
    SERVICE_DETAILS_STATE_NAME
} from '../../services.and.usage.config';
import {SERVICE_USAGE_DETAILS_STATE_NAME} from '../../../servicesAndUsage/usageDetails/usage.details.config';

export const SERVICES_MORE_OPTIONS_CONSTANTS = {
    VIEW_SERVICE_ATTRIBUTES: 1,
    VIEW_SERVICE_DETAILS: 2,
    VIEW_USAGE_CAPS: 3,
    VIEW_MANAGE_FEATURES: 4,
    RESUME_SERVICE: 5,
    SUSPEND_SERVICE: 6,
    UPDATE_SERVICE_LIFECYCLE_STATUS: 7
};

export function navigateToView(ngRedux, targetURL, params = {}) {
    ngRedux.dispatch(stateGo(targetURL, params));
}

export function handleMoreOptionSelected(
    scope,
    log,
    ngRedux,
    routeParams,
    entity
) {
    let params = {};

    return ({item}) => {
        switch (item.id) {
            case SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_SERVICE_ATTRIBUTES:
                this.openServiceAttributes(entity);
                return;
            case SERVICES_MORE_OPTIONS_CONSTANTS.RESUME_SERVICE:
                this.onOpenSuspendService()(entity);
                return;
            case SERVICES_MORE_OPTIONS_CONSTANTS.SUSPEND_SERVICE:
                this.onOpenSuspendService()(entity);
                return;
            case SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_SERVICE_DETAILS:
                params = {
                    serviceIdentifier: entity.ServiceIdentifier.Value,
                };
                this.navigateToView(SERVICE_DETAILS_STATE_NAME, params);
                return;
            case SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_USAGE_CAPS:
                params = {
                    serviceId: entity.ServiceIdentifier.Value
                };
                this.navigateToView(USAGE_CAPS_URL, params);
                return;
            case SERVICES_MORE_OPTIONS_CONSTANTS.VIEW_MANAGE_FEATURES:
                if (!item.isLocked) {
                    params = {
                        inventoryCategoryCode: null,
                        service: entity.ServiceIdentifier,
                        serviceId: entity.ServiceIdentifier.Value,
                        serviceInstanceId:
                            entity.ServiceIdentifier.ServiceInstanceId,
                        serviceTypeId: entity.ServiceIdentifier.ServiceId,
                        subscriberProductId:
                            entity.ServiceIdentifier.SubscriberProductId
                    };
                    this.navigateToView(
                        MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME,
                        params
                    );
                }
                return;
            case SERVICES_MORE_OPTIONS_CONSTANTS.UPDATE_SERVICE_LIFECYCLE_STATUS:
                this.openUpdateServiceLifeCycleStatus(entity);
                return;
            default:
                return;
        }
    };
}

export function navigateToUsageDetails(
    routeParams,
    entity
) {
    const params = {
        serviceIdentifierName: entity.ServiceName,
        serviceAttributeId: entity.ServiceIdentifierAttributeId,
        serviceIdentifierValue: entity.ServiceIdentifier.Value,
        serviceIdentifierFriendlyName: entity.ServiceName,
        fromStateObject: {
            name: SERVICES_AND_SHARED_ENTITLEMENTS,
            params: routeParams
        }
    };
    this.navigateToView(SERVICE_USAGE_DETAILS_STATE_NAME, params);
}
