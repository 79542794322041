import {stateGo} from 'redux-ui-router';
import __ from 'ramda/src/__';
import findIndex from 'ramda/src/findIndex';
import pathOr from 'ramda/src/pathOr';
import moment from 'moment';
import clone from 'ramda/src/clone';
import debounce from 'lodash/debounce';

import i18n from 'invision-core/src/components/i18n/i18n';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {IsConvergentBillerSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {LastRouteSelector} from 'invision-core/src/components/session/session.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeIntegerSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {
    registerUnsavedChanges,
    unregisterUnsavedChanges
} from 'invision-core/src/components/unsavedChangesPrompt/actions';
import {loadCodeTables} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {getShippingMethodsByCountryCode} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.actions';

import LocaleKeys from '../../../../locales/keys';
import {STATE_NAME} from './transition.offer.wizard.constants';
import {ContextOfferingAction} from '../../../../reducers/constants/wizard.constants';
import {ShowCustomerAutoPayBannerSelector} from '../../../../reducers/selectors/customer.account.status.selectors';
import {duplicatedServiceIdentifierAttributes} from '../../../../reducers/helpers/offer.ordering.wizard.helper';
import {
    EwalletErrorSelector,
    SelectedPaymentAutoPayEnable,
    SelectedPaymentMethodSelector,
    SelectedPaymentForSubmitAtCheckoutSelector,
    RecordPaymentInformationSelector,
    SupplyPaymentInstrumentSelector
} from '../../../../reducers/selectors/customer.ewallet.selectors';
import {
    AllAttributesAreValidSelector,
    AttributeServiceSelector,
    CurrentActiveAttributeNameSelector,
    FilteredFormAttributeGroups,
    FilteredPhysicalAttributeGroups,
    FinancedProductGroupInCartSelector,
    HasRequiredAndUnavailableInventorySelector,
    ShowNextButtonSelector,
    ShowPreviousButtonSelector
} from './transition.offer.wizard.selectors';
import {
    AvailableCreditFromQuoteSelector,
    AvailableFinanceCreditFromQuoteSelector,
    BackButtonTextSelector,
    CartAdditionalPropertiesSelector,
    CartAdditionalPropertyListMapSelector,
    CompletedStepsSelector,
    CurrentStepIndexSelector,
    CurrentStepIsInvalidSelector,
    CurrentStepSelector,
    DeliveryOptionsByPricingPlanSelector,
    EditOptionSelector,
    EditOptionsSelector,
    EditSelectedFacetIdsSelector,
    EditSelectedOfferingChargeTypeIdSelector,
    FormattedCityStateSelector,
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    FormattedPhysicalAttributesSelector,
    FormattedAttributesSelector,
    FormattedDeliveryDecisionsSelector,
    FormattedNameSelector,
    ForwardButtonTextSelector,
    IsAttributesStepSelector,
    IsCheckoutStepSelector,
    IsCompareOffersStepSelector,
    IsCreditLimitBreachedFromQuoteSelector,
    IsDecisionsStepSelector,
    IsOffersStepSelector,
    IsResumingStoreOrderSelector,
    IsSendToStoreButtonEnabledSelector,
    IsSubmittingCOSOrderSelector,
    IsSummaryTabVisibleSelector,
    OfferOptionViewModelSelector,
    OrderAdditionalPropertiesSelector,
    OrderAdditionalPropertyListMapSelector,
    PaymentInfoEditSelector,
    PaymentInfoSelector,
    IsShippingRequiredSelector,
    IsSubscriberProductIdUndefinedSelector,
    QuoteHasInvoiceItemsSelector,
    ShippingAddressSelector,
    ShippingAddressDropdownOptionsSelector,
    SelectedOfferIdSelector,
    SelectedOfferingResponseIdSelector,
    SelectedTabIndexSelector,
    ServiceAddressSelector,
    ServiceTaxDecisionsSelector,
    SkippedStepSelector,
    StepProgressBarViewModelSelector,
    SummaryTabViewModelSelector,
    TabPanelViewModelSelector,
    TaxLocationDecisionsByPricingPlanChangeOfferSelector,
    TransitionContextSelector,
    TransitionLastAttemptErrorSelector,
    TransitionOfferDeliveryMethodOptionsSelector,
    TransitionOfferingInstanceIdSelector,
    TransitionOfferSubmittedCOSSelector,
    TransitionOutcomesSelector,
} from '../../../../reducers/selectors/transition.offer.wizard.selectors';
import {setIsResumingStoreOrder} from '../../../../reducers/actions/add.offer.wizard.actions';
import {
    DEBOUNCE_TIME_MS,
    DELIVERY_OPTIONS,
    DUPLICATE_SERVICE_IDENTIFIER_FAULT_CODE,
    EXISTING_SAVED_CART_FOR_SEND_TO_STORE_FAULT_CODE,
    ORDER_SCENARIOS,
    RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION
} from '../../../../customercare.constants';
import {GroupedCheckboxFilterOptionsSelector} from '../create/offer/add.offer.wizard.selectors';
import {
    FullCartOfferingOrderQuotesSelector,
    FullCartOfferingOrderQuoteTotalSelector,
    FullCartOfferingOrderFeeQuoteSelector,
    FullCartOfferingOrderTaxQuoteSelector,
    FullCartOfferingOrderShippingQuoteSelector,
    ShowHideFullCartColumnsSelector
} from '../../../../reducers/selectors/full.cart.selectors';
import {
    CurrentCustomerCreditLimitSelector,
    CurrentCustomerSelector,
    CurrentCustomerIdSelector,
    StoreOrderSelector,
    SelectedCustomerHomeCountrySelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    beginTransitionOfferOrder,
    cancelOrder,
    clearQuoteAndSetSelectedOffer,
    fetchAddresses,
    fetchDecisionsAndNavigate,
    fetchAttributes,
    fetchAttributesQuoteAndNavigate,
    fetchPreviousAttributesAndNavigate,
    fetchWallet,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    restoreOptionDefaultPricing,
    resumeStoreOrderForTransition,
    saveBulkPricing,
    searchAttributeInventory,
    sendPortInOrItemReservation,
    setCurrentStepIsInvalid,
    setEditAttributeGroups,
    setEditOption,
    setEditCartAdditionalProperty,
    setEditCartAdditionalPropertyOnChange,
    setEditOrderAdditionalProperty,
    setEditOrderAdditionalPropertyOnChange,
    setEditPhysicalAttributeGroups,
    setPaymentInfo,
    setSelectedFacetsEditCopy,
    setSelectedOffer,
    setSelectedOfferingChargeTypesEditCopy,
    setSelectedOfferEditCopy,
    setSelectedTab,
    transitionOfferSubmitCosOrder,
    updateAttribute,
    updateBillCycle,
    updateCart,
    updateEditOptionPrice,
    updateInventorySelection,
    updateOfferPhysicalInventoryMakeAndModel,
    updateOfferPhysicalInventoryTypeAttribute,
    updatePortInRequest,
    updateSelectedServiceFeatures,
    updatePaymentInfo,
    updateServiceTaxLocationDecisionChangeOffer
} from '../../../../reducers/actions/transition.offer.wizard.actions';
import {
    AddItemsSelector,
    AllPhysicalInventoryItemsFromSelectedOffering,
    AttributeFormSubmittedSelector,
    CartContainsOffcycleOfferSelector,
    CompletedDecisionsSelector,
    FilteredStoresOnSelectedRegionForStoresSearchModalSelector,
    HasOffCycleChargeRenewalSelector,
    IsAllSelectedPhysicalInventoryPresentAtSelectedStore,
    InventoryAvailabilitySelector,
    ModifyItemsSelector,
    PagesIsFetchingDataSelector,
    PaymentInstrumentRequiredSelector,
    PhysicalAttributeDecisionsSelector,
    PhysicalAttributeGroupsSelector,
    RemoveItemsSelector,
    SelectedOfferChangesEffectiveSelector,
    SelectedOfferingPhysicalInventoryAvailabilitySelector,
    SelectedOfferingPickupIdSelector,
    SelectedOfferingServiceIdentifiersSelector,
    SelectedOfferingStoreAvailabilitySelector,
    ShoppingCartIsPostpaidSelector,
    SubmittedOrderErrorSelector
} from '../../../../reducers/selectors/selected.offering.order.selectors';
import {
    DbssShippingEnabledRegionIdSelector,
    FullCartAsyncStatusSelector,
    InventoryStorePickupDetailsSelector,
    InventoryStoresTableDataSelector,
    IsAsyncCompleteSelector,
    IsFullCartAsyncCompleteSelector,
    IsInventoryStoreConfiguredSelector,
    IsQuoteBypassedSelector,
    IsQuotingOfferSelector,
    OffcycleRenewalScheduleSelector,
    OfferingOrderAsyncIdSelector,
    OfferingOrderQuoteSelector,
    QuoteCalculatedSelector,
    SelectedDeliveryDecisionSelector,
    SelectedInventoryRegionSelector,
    SelectedInventoryStoresDetailsSelector,
    SelectedOrderExecutionOptionsSelector,
    SelectedShippingAddressAndMethodSelector,
    SubscriptionOrderQuoteSelector
} from '../../../../reducers/selectors/offering.order.selectors';
import {
    calculateChangeOfServiceOfferingOrderQuote,
    resetAvailableInventoryInformation,
    retrieveOffCycleNextRenewalSchedules,
    retrieveOfferingOrderQuote,
    retrieveOrderQuote,
    searchInventoriesAvailability,
    sendInventoryToStore,
    setActiveAttributeFormName,
    setAttributeFormSubmitted,
    setDevicePaymentOptions,
    setInventoryRegionFilter,
    setSelectedInventoryStores
} from '../../../../reducers/actions/offering.order.actions';
import {
    cancelStoreOrder,
    setCustomerCategory
} from '../../../../reducers/actions/customer.actions';
import {setCustomerAutoPayBannerFlag} from '../../../../reducers/actions/customer.account.status.actions';
import {OfferWizard} from '../offer.wizard.common';
import {CUSTOMER_URL} from '../../../customer/customer.config';
import {
    MINI_CART_MORE_ACTIONS,
    moreActionMenuItems
} from '../../../wizards/tabs/summaryTab/summary.tab.helper';
import {asyncRepeatInterval} from '../../../../reducers/helpers/async.helper';
import {FormattedEditCustomerSubscriberIdentitiesSelector} from '../../../../reducers/selectors/customer.information.selectors';
import {CurrentCustomerNodeSelector} from '../../../../reducers/selectors/customer.account.hierarchy.selectors';
import {REPORTING_LEVELS} from '../../accountHierarchy/account.hierarchy.constants';
import {CUSTOMER_CATEGORY} from '../../../createCustomerPopup/create.customer.popup.constants';
class TransitionOfferWizardController extends OfferWizard {
    constructor($scope,
        $ngRedux,
        $interval,
        $filter,
        $state,
        $stateParams,
        $timeout,
        uiNotificationService,
        unsavedChangesPrompt) {
        super();
        Object.assign(this, {
            $ngRedux,
            $interval,
            $filter,
            $scope,
            $state,
            $timeout,
            autoPayBannerFlag: false,
            customerCategory: CUSTOMER_CATEGORY,
            initialServiceFeatures: {},
            LocaleKeys,
            miniCartMoreActionMenuItems: moreActionMenuItems([MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER]),
            offerId: $stateParams.offeringId,
            offeringInstanceId: $stateParams.offeringInstanceId,
            overrideBypassQuoteConfiguration: false,
            REPORTING_LEVELS,
            retrieveOfferingOrderQuoteTimestamp: null,
            retrieveOrderQuoteTimestamp: null,
            stateName: STATE_NAME,
            submitChangeOrderErrorPopupApi: null,
            uiNotificationService,
            unsavedChangesPrompt,

            closeSearchInventoryPopupDialog: this.closeSearchInventoryPopupDialog.bind(this),
            closeSelectStorePopupDialog: this.closeSelectStorePopupDialog.bind(this),
            closeSendToStorePopup: this.closeSendToStorePopup.bind(this),
            closeViewFullCartPopupDialog: this.closeViewFullCartPopupDialog.bind(this),
            DELIVERY_OPTIONS,
            handleCloseSubmitChangeOrderErrorPopup: this.handleCloseSubmitChangeOrderErrorPopup.bind(this),
            handleSubmitForPickupErrorPopup: this.handleSubmitForPickupErrorPopup.bind(this),
            handleSubmitForPickupCancel: this.handleSubmitForPickupCancel.bind(this),
            handleSendToStore: this.handleSendToStore.bind(this),
            onCartAdditionalPropertyChanged: this.onCartAdditionalPropertyChanged.bind(this),
            onCartAdditionalPropertyListUpdated: this.onCartAdditionalPropertyListUpdated.bind(this),
            onInventoryRegionFilterChanged: this.onInventoryRegionFilterChanged.bind(this),
            onInventoryRegionFilterChangedForStoreSearch: this.onInventoryRegionFilterChangedForStoreSearch.bind(this),
            onMoreActionSelected: this.onMoreActionSelected.bind(this),
            onOfferingDeselected: this.onOfferingDeselected.bind(this),
            onOfferSelected: this.onOfferSelected.bind(this),
            onOrderAdditionalPropertyChanged: this.onOrderAdditionalPropertyChanged.bind(this),
            onOrderAdditionalPropertyListUpdated: this.onOrderAdditionalPropertyListUpdated.bind(this),
            onOverrideCalculateQuote: this.onOverrideCalculateQuote.bind(this),
            onRefreshOfferingQuote: this.onRefreshOfferingQuote.bind(this),
            onRefreshQuote: this.onRefreshQuote.bind(this),
            onSelectInventoryStore: this.onSelectInventoryStore.bind(this),
            onUpdateSelectedServiceFeatures: this.onUpdateSelectedServiceFeatures.bind(this),
            openStoreSearch: this.openStoreSearch.bind(this),
            recalculateOrderQuote: this.recalculateOrderQuote.bind(this),
            recallOfferingContext: this.recallOfferingContext.bind(this),
            repeatRetrieveOfferingQuote: this.repeatRetrieveOfferingQuote.bind(this),
            repeatRetrieveQuote: this.repeatRetrieveQuote.bind(this),
            setSelectedStoreForSearchModal: this.setSelectedStoreForSearchModal.bind(this),
            updateCart: this.updateCart.bind(this)
        });
        Promise.all(loadCodeTables(this.$ngRedux.getState(), this.$ngRedux.dispatch, [
            CODES.FinanceBillerRuleConfiguration,
            CODES.InventoryRegion,
            CODES.OneTimeBillerRuleConfiguration,
            CODES.RecurringBillerRuleConfiguration,
            CODES.Stores
        ])).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    $onInit() {
        this.creditCheckPopupApi = null;
        this.isFormDirty = false;
        this.contextOfferingAction = ContextOfferingAction.TRANSITION;
        this.disconnectRedux = this.connectRedux();
        this.orderScenarios = ORDER_SCENARIOS;
        this.cartMessages = [
            this.LocaleKeys.CART_SUMMARY.QUOTE_SUMMARY
        ];

        this.debouncedAttemptNextStep = debounce(this.attemptNextStep, DEBOUNCE_TIME_MS);

        this.actions.fetchAddresses(this.state.customerId);
        this.actions.setCustomerCategory(this.state.customerInfo.Category);

        this.actions.fetchWallet(this.state.customerId).catch(() => {
            if (this.state.retrieveEwalletError) {
                this.uiNotificationService.error(this.state.retrieveEwalletError.message);
            }
        });

        this.EditSelectedOfferingChargeTypeIdSelector = EditSelectedOfferingChargeTypeIdSelector;
        this.EditSelectedFacetIdsSelector = EditSelectedFacetIdsSelector;
        this.actions.beginTransitionOfferOrder();

        this.actions.registerUnsavedChanges('wizardForm', {
            isDirty: () => {
                return this.isFormDirty;
            },
            setPristine: () => {
                this.actions.unregisterUnsavedChanges('wizardForm');
            }
        });
        // hook the order confirmation popupApi
        this.changeOrderConfirmationPopup = {
            onRegisterApi: ({api}) => {
                this.changeOrderConfirmationPopup = api;
            }
        };

        this.viewFullCartPopupConfig = {
            onRegisterApi: (event) => {
                this.viewFullCartPopupApi = event.api;
            }
        };

        this.sendToStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.sendToStorePopupApi = api;
            }
        };

        // hook the submit order error popupApi
        this.submitChangeOrderErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitChangeOrderErrorPopupApi = api;
            }
        };

        this.submitForPickupErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitForPickupErrorPopupApi = api;
            }
        };

        this.offersFilter = (offer) => {
            // ITV Offers are pre-paid only & contain Subscription property
            return (offer.Prepaid && !this.state.isDbss) || !(offer.ContainsBulkPlans || offer.Prepaid);
        };

        this._previewRenewalStatementsPopupApi = null;
        this.showPreviewRenewalStatementsDialog = false;
        this.previewRenewalStatementsPopupConfig = {
            onRegisterApi: (event) => {
                this._previewRenewalStatementsPopupApi = event.api;
            }
        };
        this.handlePreviewRenewalStatements = () => {
            this._openPreviewRenewalStatementsPopup();
        };

        this.handleClosePreviewRenewalStatementsDialog = () => {
            this._closePreviewRenewalStatementsPopup();
        };

        this.goToPreviousStep = () => {
            if (this.state.isCheckoutStep) {
                this.actions.setPaymentInfo(this.state.paymentInfoEdit);
            }
            if (this.state.isDecisionsStep) {
                this.actions.setAttributeFormSubmitted(false);
                this.actions.setActiveAttributeFormName(null);
            }
            if (this.state.isAttributesStep &&
                this.isAttributesFormInvalid()) {
                this.wizardForm.$setSubmitted();
                this.actions.setAttributeFormSubmitted(true);
                return;
            }
            this.actions.goToPreviousStep();
        };

        this.creditCheckPopupConfig = {
            onRegisterApi: ({api}) => {
                this.creditCheckPopupApi = api;
            }
        };

        this.openCreditCheckModal = () => {
            this.creditCheckPopupApi.open();
        };

        this.storeSearchInventoryConfig = {
            onRegisterApi: ({api}) => {
                this.storeSearchInventoryApi = api;
            }
        };

        this.selectStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectStorePopupApi = api;
            }
        };
        /*
            For the resumeStoreOrder scenario in edit or change offer, when we hit
            the `sendToStore` API unlike `addOffer` we do not send along a saved shopping cart.
            Therefore we do not need to update the shopping cart like in add since we do not get back
            a shopping cart from the API.
        */
        if (this.state.isResumingStoreOrder) {
            this.actions.resumeStoreOrderForTransition(this.state.customerId, this.state.storeOrder)
                .catch((err) => {
                    this.uiNotificationService.transientError(err.translatedMessage);
                });
        }

        if (this.state.isDbss) {
            this.actions.getShippingMethodsByCountryCode(this.state.selectedCustomerHomeCountry).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    _openPreviewRenewalStatementsPopup() {
        this.showPreviewRenewalStatementsDialog = true;
        this.$timeout(this._previewRenewalStatementsPopupApi.open);
    }

    _closePreviewRenewalStatementsPopup() {
        this.showPreviewRenewalStatementsDialog = false;
        this._previewRenewalStatementsPopupApi.close();
    }

    openViewFullCartPopupDialog() {
        this.showViewFullCartPopup = true;
        this.$timeout(this.viewFullCartPopupApi.open);
    }

    closeViewFullCartPopupDialog() {
        this.showViewFullCartPopup = false;
        this.viewFullCartPopupApi.close();
    }

    onOverrideCalculateQuote() {
        this.overrideBypassQuoteConfiguration = true;
        this.recalculateOrderQuote();
    }

    recalculateOrderQuote() {
        this.actions.calculateChangeOfServiceOfferingOrderQuote(
            this.state.customerInfo,
            this.state.transitionOfferingInstanceId,
            this.state.addedItems,
            this.state.removedItems,
            this.state.modifiedItems,
            this.state.executionOptions,
            undefined,
            this.state.selectedShippingAddressAndMethod,
            !!this.state.shippingAddress,
            this.overrideBypassQuoteConfiguration
        ).then(() => {
            if (this.state.offeringOrderAsyncId) {
                this.repeatRetrieveQuote();
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    };

    onViewFullCartRequest() {
        if (this.state.offeringOrderAsyncId) {
            this.repeatRetrieveOfferingQuote();
            this.openViewFullCartPopupDialog();
        } else {
            if (!Object.keys(this.state.offeringOrderQuote).length) {
                this.actions.calculateChangeOfServiceOfferingOrderQuote(
                    this.state.customerInfo,
                    this.state.transitionOfferingInstanceId,
                    this.state.addedItems,
                    this.state.removedItems,
                    this.state.modifiedItems,
                    this.state.executionOptions,
                    undefined,
                    this.state.selectedShippingAddressAndMethod,
                    undefined,
                    this.overrideBypassQuoteConfiguration
                ).then(() => {
                    if (this.state.cartContainsOffcycleOffer) {
                        this.actions.retrieveOffCycleNextRenewalSchedules(
                            this.state.customerInfo.Id,
                            this.state.addedItems,
                            this.state.modifiedItems,
                            this.state.removedItems
                        )
                            .catch((error) => {
                                this.uiNotificationService.transientError(error.translatedMessage);
                            });
                    }
                    this.openViewFullCartPopupDialog();
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            } else {
                if (this.state.cartContainsOffcycleOffer) {
                    this.actions.retrieveOffCycleNextRenewalSchedules(
                        this.state.customerInfo.Id,
                        this.state.addedItems,
                        this.state.modifiedItems,
                        this.state.removedItems
                    )
                        .catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                }
                this.openViewFullCartPopupDialog();
            }
        }
    }

    gotoDecisionsPage() {
        this.closeViewFullCartPopupDialog();
        this.actions.goToStep(LocaleKeys.WIZARD.STEPS.DECISIONS);
    }

    connectRedux() {
        const mapStateToTarget = (store) => {
            return {
                addedItems: AddItemsSelector(store),
                additionalPropValueOptions: MetadataCodeTypeIntegerSelector(CODES.AdditionalPropertyValue, store),
                allAttributesAreValid: AllAttributesAreValidSelector(store),
                attributeFormSubmitted: AttributeFormSubmittedSelector(store),
                availableCreditFromQuote: AvailableCreditFromQuoteSelector(store),
                availableFinanceCreditFromQuote: AvailableFinanceCreditFromQuoteSelector(store),
                availableInventoryInformation: InventoryAvailabilitySelector(store),
                backButtonText: BackButtonTextSelector(store),
                cartAdditionalProperties: CartAdditionalPropertiesSelector(store),
                cartAdditionalPropertyListMap: CartAdditionalPropertyListMapSelector(store),
                cartContainsOffcycleOffer: CartContainsOffcycleOfferSelector(store),
                changesEffective: SelectedOfferChangesEffectiveSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                completedDecisions: CompletedDecisionsSelector(store),
                completedSteps: CompletedStepsSelector(store),
                currentAttributeTitle: CurrentActiveAttributeNameSelector(store),
                currentCustomerCreditLimit: CurrentCustomerCreditLimitSelector(store),
                currentCustomerNode: CurrentCustomerNodeSelector(store),
                currentStep: CurrentStepSelector(store),
                currentStepIndex: CurrentStepIndexSelector(store),
                currentStepIsInvalid: CurrentStepIsInvalidSelector(store),
                customerId: CurrentCustomerIdSelector(store),
                customerInfo: CurrentCustomerSelector(store),
                dbssShippingEnabledRegionId: DbssShippingEnabledRegionIdSelector(store),
                deliveryMethodOptions: TransitionOfferDeliveryMethodOptionsSelector(store),
                deliveryOptionsByPricingPlan: DeliveryOptionsByPricingPlanSelector(store),
                executionOptions: SelectedOrderExecutionOptionsSelector(store),
                editOption: EditOptionSelector(store),
                editOptions: EditOptionsSelector(store),
                filteredFormAttributeGroups: FilteredFormAttributeGroups(store),
                filteredPhysicalAttributeGroups: FilteredPhysicalAttributeGroups(store),
                filteredStoresOnSelectedRegionForStoresSearchModal: FilteredStoresOnSelectedRegionForStoresSearchModalSelector(store),
                financedProductGroupInCart: FinancedProductGroupInCartSelector(store),
                formattedEditCustomerSubscriberIdentities: FormattedEditCustomerSubscriberIdentitiesSelector(store),
                formattedAttributes: FormattedAttributesSelector(store),
                formattedCityState: FormattedCityStateSelector(store),
                formattedDeliveryDecisions: FormattedDeliveryDecisionsSelector(store),
                formattedName: FormattedNameSelector(store),
                formattedPhysicalAttributes: FormattedPhysicalAttributesSelector(store),
                formattedPhysicalInventories: SelectedOfferingPhysicalInventoryAvailabilitySelector(store),
                formAttributeGroups: FormAttributeGroups(store),
                formPhysicalAttributeGroups: FormPhysicalAttributeGroups(store),
                forwardButtonText: ForwardButtonTextSelector(store),
                fullCartAsyncStatus: FullCartAsyncStatusSelector(store),
                fullCartOfferingOrderQuotes: FullCartOfferingOrderQuotesSelector(store),
                fullCartOfferingOrderQuoteTotal: FullCartOfferingOrderQuoteTotalSelector(store),
                fullCartOfferingOrderFeeQuote: FullCartOfferingOrderFeeQuoteSelector(store),
                fullCartOfferingOrderTaxQuote: FullCartOfferingOrderTaxQuoteSelector(store),
                fullCartOfferingOrderShippingQuote: FullCartOfferingOrderShippingQuoteSelector(store),
                groupedCheckboxFilterOptions: GroupedCheckboxFilterOptionsSelector(store),
                hasInvoiceItems: QuoteHasInvoiceItemsSelector(store),
                hasOffCycleChargeRenewal: HasOffCycleChargeRenewalSelector(store),
                hasRequiredAndUnavailableInventory: HasRequiredAndUnavailableInventorySelector(store),
                inventoryRegions: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.InventoryRegion, store),
                inventoryStorePickupDetails: InventoryStorePickupDetailsSelector(store),
                isAllSelectedPhysicalInventoryAvailable: IsAllSelectedPhysicalInventoryPresentAtSelectedStore(store),
                isAttributesStep: IsAttributesStepSelector(store),
                isCalculatingQuote: IsQuotingOfferSelector(store),
                isCheckoutStep: IsCheckoutStepSelector(store),
                isCompareOffersStep: IsCompareOffersStepSelector(store),
                isConvergentBiller: IsConvergentBillerSelector(store),
                isCreditLimitBreached: IsCreditLimitBreachedFromQuoteSelector(store),
                isDbss: IsDbss(store),
                isDecisionsStep: IsDecisionsStepSelector(store),
                isAsyncComplete: IsAsyncCompleteSelector(store),
                isFetchingDecisionsOrAttributes: PagesIsFetchingDataSelector(store),
                isFullCartAsyncComplete: IsFullCartAsyncCompleteSelector(store),
                isInventoryStoreConfigured: IsInventoryStoreConfiguredSelector(store),
                isOffersStep: IsOffersStepSelector(store),
                isQuoteBypassed: IsQuoteBypassedSelector(store),
                isQuoteCalculated: QuoteCalculatedSelector(store),
                isResumingStoreOrder: IsResumingStoreOrderSelector(store),
                isSendToStoreButtonEnabled: IsSendToStoreButtonEnabledSelector(store),
                isShippingRequired: IsShippingRequiredSelector(store),
                isSubmittingCOSOrder: IsSubmittingCOSOrderSelector(store),
                isSummaryTabVisible: IsSummaryTabVisibleSelector(store),
                lastAttemptError: TransitionLastAttemptErrorSelector(store),
                lastRoute: LastRouteSelector(store),
                lastSubmittedOrderAttemptError: SubmittedOrderErrorSelector(store),
                modifiedItems: ModifyItemsSelector(store),
                offcycleRenewalSchedule: OffcycleRenewalScheduleSelector(store),
                offeringOrderAsyncId: OfferingOrderAsyncIdSelector(store),
                offeringOrderQuote: OfferingOrderQuoteSelector(store),
                offerOptionViewModel: OfferOptionViewModelSelector(store),
                orderAdditionalProperties: OrderAdditionalPropertiesSelector(store),
                orderAdditionalPropertyListMap: OrderAdditionalPropertyListMapSelector(store),
                paymentInfo: PaymentInfoSelector(store),
                paymentInfoEdit: PaymentInfoEditSelector(store),
                paymentInstrumentRequired: PaymentInstrumentRequiredSelector(store),
                physicalAttributeGroups: PhysicalAttributeGroupsSelector(store),
                isSubscriberProductIdUndefined: IsSubscriberProductIdUndefinedSelector(store),
                physicalInventories: AllPhysicalInventoryItemsFromSelectedOffering(store),
                physicalInventoriesDecisions: PhysicalAttributeDecisionsSelector(store),
                recordPaymentInfo: RecordPaymentInformationSelector(store),
                removedItems: RemoveItemsSelector(store),
                retrieveEwalletError: EwalletErrorSelector(store),
                rocResponseId: SelectedOfferingResponseIdSelector(store),
                selectedCustomerHomeCountry: SelectedCustomerHomeCountrySelector(store),
                selectedDeliveryDecision: SelectedDeliveryDecisionSelector(store),
                selectedInventoryRegion: SelectedInventoryRegionSelector(store),
                selectedInventoryStores: SelectedInventoryStoresDetailsSelector(store),
                selectedInventoryStoresItemAvailability: SelectedOfferingStoreAvailabilitySelector(store),
                selectedOfferId: SelectedOfferIdSelector(store),
                selectedOfferingPickupId: SelectedOfferingPickupIdSelector(store),
                selectedOfferingServiceIdentifiers: SelectedOfferingServiceIdentifiersSelector(store),
                selectedPayment: SelectedPaymentMethodSelector(store),
                selectedPaymentClean: SelectedPaymentForSubmitAtCheckoutSelector(store),
                selectedPaymentMethodAutoPay: SelectedPaymentAutoPayEnable(store),
                selectedShippingAddressAndMethod: SelectedShippingAddressAndMethodSelector(store),
                selectedTabIndex: SelectedTabIndexSelector(store),
                serviceAddress: ServiceAddressSelector(store),
                services: AttributeServiceSelector(store),
                serviceTaxDecisions: ServiceTaxDecisionsSelector(store),
                shippingAddress: ShippingAddressSelector(store),
                shippingAddressDropdownOptions: ShippingAddressDropdownOptionsSelector(store),
                shoppingCartIsPostpaid: ShoppingCartIsPostpaidSelector(store),
                shouldShowNextButton: ShowNextButtonSelector(store),
                shouldShowPreviousButton: ShowPreviousButtonSelector(store),
                showCustomerAutoPayBanner: ShowCustomerAutoPayBannerSelector(store),
                showHideFullCartColumns: ShowHideFullCartColumnsSelector(store),
                skippedStep: SkippedStepSelector(store),
                stepProgressBarViewModel: StepProgressBarViewModelSelector(store),
                storeOrder: StoreOrderSelector(store),
                storesTableData: InventoryStoresTableDataSelector(store),
                submittedOrder: TransitionOfferSubmittedCOSSelector(store),
                subscriptionOrderQuotes: SubscriptionOrderQuoteSelector(store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                summaryTabViewModel: SummaryTabViewModelSelector(store),
                supplyPaymentInstrument: SupplyPaymentInstrumentSelector(store),
                tabPanelViewModel: TabPanelViewModelSelector(store),
                taxLocationDecisionsByPricingPlanChangeOffer: TaxLocationDecisionsByPricingPlanChangeOfferSelector(store),
                transitionContext: TransitionContextSelector(store),
                transitionOfferingInstanceId: TransitionOfferingInstanceIdSelector(store),
                transitionOutcomes: TransitionOutcomesSelector(store)
            };
        };
        const controllerActions = {
            beginTransitionOfferOrder,
            calculateChangeOfServiceOfferingOrderQuote,
            cancelOrder,
            cancelStoreOrder,
            fetchAddresses,
            fetchAttributes,
            fetchCodeTypes,
            fetchWallet,
            getShippingMethodsByCountryCode,
            registerUnsavedChanges,
            resetAvailableInventoryInformation,
            resumeStoreOrderForTransition,
            retrieveOffCycleNextRenewalSchedules,
            retrieveOfferingOrderQuote,
            retrieveOfferingsMetadata,
            retrieveOrderQuote,
            searchInventoriesAvailability,
            sendInventoryToStore,
            setActiveAttributeFormName,
            setAttributeFormSubmitted,
            setCustomerAutoPayBannerFlag,
            setCustomerCategory,
            setDevicePaymentOptions,
            setEditCartAdditionalProperty,
            setEditCartAdditionalPropertyOnChange,
            setEditOrderAdditionalProperty,
            setEditOrderAdditionalPropertyOnChange,
            setIsResumingStoreOrder,
            setPaymentInfo,
            stateGo,
            transitionOfferSubmitCosOrder,
            updateBillCycle,
            updatePortInRequest,
            updateServiceTaxLocationDecisionChangeOffer,
            unregisterUnsavedChanges
        };

        const attributesActions = {
            fetchAttributesQuoteAndNavigate,
            fetchPreviousAttributesAndNavigate,
            searchAttributeInventory,
            sendPortInOrItemReservation,
            setEditAttributeGroups,
            setEditPhysicalAttributeGroups,
            updateAttribute,
            updateInventorySelection,
            updateOfferPhysicalInventoryMakeAndModel,
            updateOfferPhysicalInventoryTypeAttribute,
            updateSelectedServiceFeatures
        };

        const decisionActions = {
            restoreOptionDefaultPricing,
            saveBulkPricing,
            setEditOption,
            updateCart,
            updateEditOptionPrice
        };

        const offeringActions = {
            clearQuoteAndSetSelectedOffer,
            fetchDecisionsAndNavigate,
            setInventoryRegionFilter,
            setSelectedFacetsEditCopy,
            setSelectedInventoryStores,
            setSelectedOffer,
            setSelectedOfferEditCopy,
            setSelectedOfferingChargeTypesEditCopy
        };

        const wizardActions = {
            goToNextStep,
            goToPreviousStep,
            goToStep,
            setCurrentStepIsInvalid,
            setSelectedTab,
            updatePaymentInfo
        };

        return this.connectToStore(mapStateToTarget, Object.assign(controllerActions, attributesActions, decisionActions, offeringActions, wizardActions));
    }

    cancelTransition() {
        if (this.isFormDirty) {
            this.unsavedChangesPrompt.showPrompt(() => {
                this.actions.cancelOrder();
                this.redirectToCustomer();
            });
        } else {
            this.actions.cancelOrder();
            this.redirectToCustomer();
        }
    }

    checkCreditandFinanceLimitAndSubmitOrder(paymentInfo) {
        const isFinanceCreditLimitBreached = this.validateFinanceCreditLimitFromQuote();
        if ( !this.state.isCreditLimitBreached && !isFinanceCreditLimitBreached) {
            this.changeOfferSubmitCOSOrder(paymentInfo);
        }
    }

    validateFinanceCreditLimitFromQuote() {
        return this.state.availableFinanceCreditFromQuote > 0;
    }

    repeatRetrieveQuote() {
        asyncRepeatInterval(
            this.onRefreshQuote,
            () => {
                return !this.state.isAsyncComplete && this.state.isCheckoutStep;
            }
        );
    }

    onRefreshQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.customerId, this.state.offeringOrderAsyncId)
            .then((response) => {
                this.retrieveOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    repeatRetrieveOfferingQuote() {
        if (this.state.offeringOrderQuote.OfferingQuotes.length > 0 && this.state.isFullCartAsyncComplete) {
            this.openViewFullCartPopupDialog();
        } else {
            asyncRepeatInterval(
                this.onRefreshOfferingQuote,
                () => {
                    return !this.state.isFullCartAsyncComplete && this.state.isCheckoutStep;
                }
            );
        }
    }

    onRefreshOfferingQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.customerId, this.state.offeringOrderAsyncId)
            .then((response) => {
                this.retrieveOfferingOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                return this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    onOfferSelected(...args) {
        this.actions.setSelectedOffer(...args);
        this.actions.goToNextStep();
        this.actions.retrieveOfferingsMetadata([this.state.selectedOfferId]);
        if (this.state.isResumingStoreOrder) {
            this.actions.setIsResumingStoreOrder(false);
        }
    }

    onOfferingDeselected() {
        this.isFormDirty = false;
        if (this.state.isResumingStoreOrder) {
            this.actions.setIsResumingStoreOrder(false);
        }
    }

    onSubmit() {
        if (!this.state.isSubmittingOrder) {
            this.$timeout(() => {
                this.debouncedAttemptNextStep();
            });
        }
    }

    isAttributesFormInvalid() {
        return (this.wizardForm['attributesController.attributesForm'] && this.wizardForm['attributesController.attributesForm'].$invalid) ||
            !this.state.allAttributesAreValid;
    }

    isCheckoutFormInvalid() {
        return this.wizardForm['$ctrl.paymentInstrumentForm'] &&
            this.wizardForm['$ctrl.paymentInstrumentForm'].$invalid;
    }

    recallOfferingContext() {
        this.actions.fetchAttributes({
            offeringId: this.state.selectedOfferId ? this.state.selectedOfferId : this.state.storeOrder.Context.OfferingIds[0],
            offeringInstanceId: this.state.selectedOfferId ? this.state.selectedOfferId : this.state.storeOrder.Context.OfferingIds[0],
            completedDecisions: this.getCompletedDecisions(),
            completedAttributes: this.state.formattedAttributes,
            completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
            isConvergentBiller: this.state.transitionContext,
            transitionOutcomes: this.state.transitionOutcomes
        })
            .catch((error) => {
                if (error) {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }
            });
    }

    getCompletedDecisions() {
        return [...this.state.serviceTaxDecisions, ...this.state.formattedDeliveryDecisions, ...this.state.completedDecisions];
    }

    attemptNextStep(isSendToStore = false) {
        switch (this.state.currentStep) {
            case this.LocaleKeys.WIZARD.STEPS.OFFERS:
                if (!this.state.currentStepIsInvalid) {
                    this.actions.goToNextStep();
                    this.isFormDirty = true;
                }
                break;
            case this.LocaleKeys.WIZARD.STEPS.COMPARE_OFFERS:
                if (this.state.rocResponseId && this.state.selectedOfferId === this.state.rocResponseId && this.getCompletedDecisions().length) {
                    this.actions.goToNextStep();
                } else {
                    this.actions.fetchDecisionsAndNavigate(this.state.selectedOfferId ? this.state.selectedOfferId : pathOr(null, ['Context', 'OfferingIds', '0'], this.state.storeOrder),
                        this.state.customerInfo, {
                            transitionContext: this.state.transitionContext,
                            grandfatherPricingPlans: true,
                            isConvergentBiller: this.state.isConvergentBiller
                        }, this.offerId, this.state.transitionOfferingInstanceId, undefined, this.overrideBypassQuoteConfiguration);
                    this.wizardForm.$setPristine();
                }
                this.isFormDirty = true;
                break;
            case this.LocaleKeys.WIZARD.STEPS.DECISIONS:
                if (this.state.currentStepIsInvalid) {
                    break;
                }
                if (this.state.isResumingStoreOrder) {
                    if (this.state.formattedPhysicalAttributes.length === 0) {
                        //We are setting this to populate the decition type 4 selectors
                        this.actions.setEditPhysicalAttributeGroups({
                            physicalAttributeGroups: this.state.physicalAttributeGroups,
                            formPhysicalAttributeGroups: this.state.formPhysicalAttributeGroups
                        });
                    }
                    if (this.state.physicalInventoriesDecisions) {
                        this.state.physicalInventoriesDecisions.forEach((item) => {
                            item.InventoryType.InventoryTypeAttributes.forEach((attribute) => {
                                if (attribute) {
                                    this.actions.updateOfferPhysicalInventoryTypeAttribute(
                                        item.PricingPlanId,
                                        item.Id,
                                        attribute.Id,
                                        attribute.SelectedValue
                                    );
                                }
                            });
                        });
                    }

                    if (!this.state.filteredFormAttributeGroups || this.state.filteredFormAttributeGroups.length === 0) {
                        this.actions.setEditAttributeGroups(this.state.attributeGroups);
                    }
                }
                this.actions.fetchPreviousAttributesAndNavigate(
                    this.state.customerInfo,
                    this.state.selectedOfferId ? this.state.selectedOfferId : pathOr(null, ['Context', 'OfferingIds', '0'],
                        this.state.storeOrder, this.state.isResumingStoreOrder),
                    this.state.formattedAttributes ? [...this.state.formattedAttributes, ...this.getCompletedDecisions()] : this.getCompletedDecisions(),
                    this.state.transitionContext,
                    this.offerId,
                    this.state.transitionOfferingInstanceId,
                    this.state.isConvergentBiller,
                    undefined,
                    this.overrideBypassQuoteConfiguration)
                    .then(() => {
                        if (this.state.isResumingStoreOrder) {
                            this.actions.setIsResumingStoreOrder(false);
                        }
                        this.wizardForm.$setPristine();
                    });
                this.isFormDirty = true;
                break;
            case this.LocaleKeys.WIZARD.STEPS.ATTRIBUTES:
                if (!this.state.hasRequiredAndUnavailableInventory) {
                    this.actions.setAttributeFormSubmitted(true);
                    if (!this.isAttributesFormInvalid() && !duplicatedServiceIdentifierAttributes(this.state.formAttributeGroups).length) {
                        if (this.state.formattedPhysicalAttributes.length) {
                            this.actions.resetAvailableInventoryInformation();
                            this.actions.fetchAttributes({
                                offeringId: this.state.selectedOfferId ? this.state.selectedOfferId : this.state.storeOrder.Context.OfferingIds[0],
                                offeringInstanceId: this.state.selectedOfferId ? this.state.selectedOfferId : this.state.storeOrder.Context.OfferingIds[0],
                                completedDecisions: this.getCompletedDecisions(),
                                completedAttributes: this.state.formattedAttributes,
                                completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
                                isConvergentBiller: this.state.transitionContext,
                                transitionOutcomes: this.state.transitionOutcomes
                            })
                                .then(() => {
                                    if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                        this.actions.setInventoryRegionFilter(this.state.dbssShippingEnabledRegionId);
                                    }
                                    if (this.state.selectedInventoryStores.length < 1) {
                                        if (isSendToStore || this.state.isSubscriberProductIdUndefined) {
                                            this.openStoreSearch(false, isSendToStore);
                                        } else {
                                            this.handleInventoryAvailabilityContinue();
                                        }
                                    } else if (isSendToStore || this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                        this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories)
                                            .then(() => {
                                                if (!this.state.isAllSelectedPhysicalInventoryAvailable) {
                                                    if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                                        this.uiNotificationService.transientError(i18n.translate(this.LocaleKeys.ATTRIBUTES.UNAVAILABLE_SHIPPING_INVENTORY));
                                                    } else if (isSendToStore) {
                                                        this.openStoreSearch();
                                                    }
                                                } else if (isSendToStore) {
                                                    this.openSendToStorePopup();
                                                } else {
                                                    this.handleInventoryAvailabilityContinue()
                                                        .then(() => {
                                                            if (this.state.offeringOrderAsyncId) {
                                                                this.repeatRetrieveQuote();
                                                            }
                                                        });
                                                }
                                            }).catch((err) => {
                                                if (isSendToStore) {
                                                    this.showStoreInventoryPopup= true;
                                                    this.$timeout(() => {
                                                        this.storeSearchInventoryApi.open();
                                                    });
                                                }
                                                this.onSearchInventoryAvailabilityFailure(err);
                                            });
                                    } else {
                                        this.handleInventoryAvailabilityContinue()
                                            .then(() => {
                                                if (this.state.offeringOrderAsyncId) {
                                                    this.repeatRetrieveQuote();
                                                }
                                            });
                                    }
                                })
                                .catch((error) => {
                                    if (error.Code !== DUPLICATE_SERVICE_IDENTIFIER_FAULT_CODE) {
                                        this.uiNotificationService.transientError(error.translatedMessage);
                                    }
                                });
                        } else if (isSendToStore) {
                            this.openSendToStorePopup();
                        } else {
                            this.handleInventoryAvailabilityContinue()
                                .then(() => {
                                    if (this.state.offeringOrderAsyncId) {
                                        this.repeatRetrieveQuote();
                                    }
                                });
                        }
                    }
                }
                break;
            case this.LocaleKeys.WIZARD.STEPS.CHECKOUT:
                if (!this.isCheckoutFormInvalid()) {
                    if (isSendToStore) {
                        if (!this.isAttributesFormInvalid()) {
                            if (this.state.selectedInventoryStores.length < 1) {
                                this.openStoreSearch();
                                this.wizardForm.$setPristine();
                            } else {
                                this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories)
                                    .then(() => {
                                        if (!this.state.isAllSelectedPhysicalInventoryAvailable) {
                                            this.openStoreSearch();
                                        } else if (isSendToStore) {
                                            this.openSendToStorePopup();
                                        }
                                    });
                            }
                        }
                    } else {
                        let paymentInfo;

                        if (pathOr(false, ['Id'], this.state.selectedPayment)) {
                            paymentInfo = (this.state.supplyPaymentInstrument || this.state.paymentInstrumentRequired)
                                ? this.state.selectedPaymentClean : null;
                        } else {
                            this.actions.setPaymentInfo(this.state.paymentInfoEdit);
                            paymentInfo = this.state.paymentInfo;

                            if (this.state.paymentInfo && this.state.paymentInfo.BillingAddressIsServiceAddress) {
                                paymentInfo = Object.assign({}, paymentInfo, {
                                    BillingAddressId: this.state.serviceAddress.Id
                                });
                            }
                            if (this.state.paymentInfo && !this.state.paymentInfo.BillingAddressIsServiceAddress) {
                                const billingAddress = Object.assign({}, paymentInfo.BillingAddress, {
                                    Name: this.state.paymentInfo.BillingAddress.LineOne // Name is required by the API and needs to be unique.
                                });
                                paymentInfo = Object.assign({}, paymentInfo, {
                                    BillingAddress: billingAddress
                                });
                            }
                        }
                        this.actions.setCustomerAutoPayBannerFlag(this.state.selectedPaymentMethodAutoPay);
                        if (this.state.currentCustomerCreditLimit) {
                            this.checkCreditandFinanceLimitAndSubmitOrder(paymentInfo);
                        } else {
                            this.changeOfferSubmitCOSOrder(paymentInfo);
                        }
                    }
                }
                break;
            default:
                break;
        }
    }

    handleInventoryAvailabilityContinue(isSendToStore) {
        if (isSendToStore) {
            this.openSendToStorePopup();
        } else {
            return this.actions.fetchAttributesQuoteAndNavigate(this.state.customerInfo,
                this.state.selectedOfferId ? this.state.selectedOfferId : this.state.storeOrder.Context.OfferingIds[0],
                this.getCompletedDecisions(),
                this.state.formattedAttributes,
                this.state.formattedPhysicalAttributes,
                this.state.transitionContext,
                undefined,
                this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED,
                !isSendToStore,
                this.state.transitionOfferingInstanceId,
                this.overrideBypassQuoteConfiguration)
                .then(() => {
                    if (this.state.offeringOrderAsyncId) {
                        this.repeatRetrieveQuote();
                    }
                })
                .then(() => {
                    this.wizardForm.$setPristine();
                });
        }
    }

    openSendToStorePopup() {
        this.sendToStorePickupDetailsConfig = {
            maxDate: undefined,
            minDate: Date.now()
        };
        this.showSendToStorePopup = true;
        this.$timeout(() => {
            this.sendToStorePopupApi.open();
        });
    }

    closeSendToStorePopup() {
        this.showSendToStorePopup = false;
        this.sendToStorePopupApi.close();
    }

    onInventoryRegionFilterChangedForStoreSearch(region) {
        this.actions.setInventoryRegionFilter(region);
        this.actions.resetAvailableInventoryInformation();
        const formattedInventories = this.state.physicalInventories,
            formattedPayload = !this.state.formattedInventories ? {
                InventoryTypes: formattedInventories,
                RegionId: region
            } : this.state.formattedPhysicalInventories;

        this.actions.searchInventoriesAvailability(formattedPayload)
            .catch(this.onSearchInventoryAvailabilityFailure);
    }

    setSelectedStoreForSearchModal(selectedStore) {
        this.actions.setSelectedInventoryStores([selectedStore]);
        this.showStoreInventoryPopup = false;
        this.storeSearchInventoryApi.close();
        if (this.state.isSubscriberProductIdUndefined) {
            this.handleInventoryAvailabilityContinue(this.isSubmitForPickUp);
        }
    }

    closeSearchInventoryPopupDialog() {
        this.showStoreInventoryPopup = false;
        this.actions.setInventoryRegionFilter(null);
        this.storeSearchInventoryApi.close();
        if (this.isInventoryItemReservationFault) {
            this.openSendToStorePopup();
        }
    }

    handleSendToStore() {
        const offering = {
            Action: ContextOfferingAction.TRANSITION,
            OfferingId: this.state.selectedOfferId ? this.state.selectedOfferId : this.state.rocResponseId
        };
        const transitionOfferingIds = {
            transitionSourceOfferingId: this.offerId,
            transitionSourceOfferingInstanceId: this.offeringInstanceId
        };

        this.isSendToStoreLoading = true;
        const completedDecisions = [...this.state.formattedAttributes, ...this.getCompletedDecisions(), ...this.state.formattedPhysicalAttributes];
        this.actions.sendInventoryToStore(
            this.state.customerInfo.Id,
            this.state.cartAdditionalProperties.length ? {
                AdditionalProperties: this.state.cartAdditionalProperties
            } : null,
            this.state.inventoryStorePickupDetails,
            offering,
            completedDecisions,
            transitionOfferingIds)
            .then(() => {
                this.closeSendToStorePopup();
                this.actions.unregisterUnsavedChanges('$ctrl.sendToStoreForm');
                this.onOrderActionSuccess(this.LocaleKeys.SEND_TO_STORE.SEND_SUCCESS);
            }).catch((error) => {
                if (error.Code === EXISTING_SAVED_CART_FOR_SEND_TO_STORE_FAULT_CODE) {
                    this.submitForPickupErrorPopupApi.open();
                } else {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }
            })
            .finally(() => {
                this.isSendToStoreLoading = false;
            });
    }

    onCartAdditionalPropertyListUpdated() {
        this.actions.setEditCartAdditionalProperty(this.state.cartAdditionalPropertyListMap);
    }

    onCartAdditionalPropertyChanged(id, newValue) {
        this.actions.setEditCartAdditionalPropertyOnChange(id, newValue);
    }

    onOrderAdditionalPropertyListUpdated() {
        this.actions.setEditOrderAdditionalProperty(this.state.orderAdditionalPropertyListMap);
    }

    onOrderAdditionalPropertyChanged(id, newValue) {
        this.actions.setEditOrderAdditionalPropertyOnChange(id, newValue);
    }

    changeOfferSubmitCOSOrder(paymentInfo) {
        this.actions.transitionOfferSubmitCosOrder(
            this.state.customerId,
            this.state.selectedOfferId,
            this.state.addedItems,
            this.state.modifiedItems,
            this.state.removedItems,
            paymentInfo,
            this.offerId,
            this.state.changesEffective,
            this.state.executionOptions,
            this.state.recordPaymentInfo,
            pathOr(null, ['selectedOfferingPickupId', 'Id'], this.state),
            this.state.orderAdditionalProperties,
            this.state.selectedShippingAddressAndMethod
        ).then(() => {
            this.isFormDirty = false;
            this.changeOrderConfirmationPopup.open();
        }).catch((error) => {
            this.submitChangeOrderErrorPopupApi.open(error);
        });
    }

    onOrderActionSuccess(messageKey) {
        this.uiNotificationService.transientSuccess(i18n.translate(messageKey));
        this.redirectToCustomer();
    }

    redirectToCustomer() {
        this.actions.unregisterUnsavedChanges('wizardForm');
        this.isReturning = true;
        this.$state.go(CUSTOMER_URL, {
            'customerId': this.state.customerInfo.Id,
            'showAutoPayBanner': {
                enabled: this.state.showCustomerAutoPayBanner,
                customerId: this.state.customerInfo.Id
            }
        }, {
            reload: true
        });
    }

    tabSelected(selectedTab) {
        const selectedIndex = findIndex((tab) => {
            return tab === selectedTab;
        })(this.state.tabPanelViewModel);

        this.actions.setSelectedTab(selectedIndex);
    }

    updateCart(offerId,  offeringInstanceId = undefined, completedDecisions, isModify, customerId, callback, contextOfferingAction,
        transitionContext, isConvergentBiller, customerLanguage) {
        this.actions.setDevicePaymentOptions([]);

        /**
     * Updates the cart, also handles the user language
     * @param {string} offerId
     * @param {number} offeringInstanceId
     * @param {[]} completedDecisions
     * @param {boolean} isModify
     * @param {number} customerId
     * @param {number} contextOfferingAction
     * @param {Object} transitionContext
     * @param {boolean} isConvergentBiller
     * @param {string} customerLanguage
     */

        /**Callback function passed in as callback
      *  @callback callback
    */

        this.actions.updateCart(
            this.state.selectedOfferId ? this.state.selectedOfferId : pathOr(null, ['Context', 'OfferingIds', '0'], this.state.storeOrder),
            offeringInstanceId,
            completedDecisions,
            isModify,
            customerId,
            callback,
            ContextOfferingAction.TRANSITION,
            transitionContext,
            isConvergentBiller,
            customerLanguage,
            this.state.selectedOfferingPickupId
        );
    }

    handleCloseSubmitChangeOrderErrorPopup() {
        this.submitChangeOrderErrorPopupApi.close();
    }

    handleSubmitForPickupErrorPopup() {
        this.submitForPickupErrorPopupApi.close();
    }

    handleSubmitForPickupCancel() {
        this.actions.cancelStoreOrder(this.state.customerInfo.Id)
            .then(() => {
                this.handleSendToStore();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.submitForPickupErrorPopupApi.close();
            });
    }

    isLoading() {
        return this.isReturning || this.state.isFetchingDecisionsOrAttributes || this.state.isCalculatingQuote || this.state.isSubmittingCOSOrder;
    }

    onSelectInventoryStore() {
        this.showSelectStorePopup = true;

        if (this.state.selectedInventoryRegion) {
            this.actions.setInventoryRegionFilter(this.state.selectedInventoryRegion);
        }

        this.$timeout(() => {
            this.selectStorePopupApi.open();
        });
    }

    openStoreSearch(isSearchInventoriesAvailability = false, isSendToStore = false) {
        this.showStoreInventoryPopup = true;
        this.isSubmitForPickUp = isSendToStore;
        this.$timeout(() => {
            this.storeSearchInventoryApi.open();
            if (isSearchInventoriesAvailability) {
                this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories).then(() => {
                    if (this.isInventoryItemReservationFault) {
                        this.isInventoryItemReservationFault = false;
                    }
                }).catch(this.onSearchInventoryAvailabilityFailure);
            }
        });
    }

    closeSelectStorePopupDialog(applyChanges, selectedStore) {
        this.showSelectStorePopup = false;
        if (applyChanges) {
            this.actions.setSelectedInventoryStores([selectedStore]);
        }
        this.actions.setInventoryRegionFilter(null);
        this.selectStorePopupApi.close();
    }

    onInventoryRegionFilterChanged(region) {
        this.actions.setInventoryRegionFilter(region);
    }

    onUpdateSelectedServiceFeatures(attributeId, selectedServiceFeatures, billerRuleInstanceDetails) {
        const removedItems = clone(this.state.removedItems);
        if (!this.initialServiceFeatures[attributeId]) {
            this.state.formAttributeGroups.forEach((attributeGroup) => {
                return attributeGroup.attributes.find((attribute) => {
                    if (attribute.id === attributeId) {
                        return this.initialServiceFeatures[attributeId] = attribute.serviceFeatures;
                    }
                });
            });
        }

        selectedServiceFeatures.forEach((serviceFeature) => {
            if (!serviceFeature.SubscriberProductId) {
                if (this.initialServiceFeatures[attributeId] && this.initialServiceFeatures[attributeId].find((initialServiceFeature) => {
                    return initialServiceFeature.ProductId === serviceFeature.ProductId &&
                        initialServiceFeature.PricingPlanId === serviceFeature.PricingPlanId;
                })) {
                    const restorableIndex = removedItems.findIndex((item) => {
                        return item.ProductId === serviceFeature.ProductId &&
                        item.PricingPlanId === serviceFeature.PricingPlanId;
                    });
                    if (restorableIndex > -1) {
                        serviceFeature.SubscriberProductId = removedItems[restorableIndex].LockerItemId;
                    }
                }


            }

        });
        this.actions.updateSelectedServiceFeatures(attributeId, selectedServiceFeatures, billerRuleInstanceDetails);
    }

    onMoreActionSelected(action) {
        if (action === MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER) {
            this.actions.goToStep(LocaleKeys.WIZARD.STEPS.DECISIONS);
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./transition.offer.wizard.html'),
    controller: TransitionOfferWizardController
};
