import {GROUP_ORDER_HISTORY_LIST_STATE_NAME} from './components/customer/orderHistory/orderHistoryListWrapper/order.history.list.wrapper.config';
import {STATE_OR_NAME as NEW_CONNECT_PAGE} from './reducers/constants/new.connect.wizard.constants';
import {STATE_OR_NAME as EDIT_OFFERING_PAGE} from './reducers/constants/edit.offer.wizard.constants';
import {STATE_OR_NAME as ADD_OFFERING_PAGE} from './reducers/constants/add.offer.wizard.constants';
import {STATE_OR_NAME as CHANGE_OFFERING_PAGE} from './reducers/constants/transition.offer.wizard.constants';
import {DISCONNECT_CUSTOMER_DBSS_ROUTE, DISCONNECT_OFFERS_DBSS_ROUTE} from './components/customer/offers/disconnect/dbss/disconnect.offers.dbss.config';

const CUSTOMER = '/customer/:customerId';
const ORDERING = `${CUSTOMER}/ordering`;
export const HOME = '/';

const ADDRESSES_STATE_REDIRECT = 'index.customercare.customer.addresses';
const RATE_SHEET_STATE_REDIRECT = 'index.customercare.customer.rateSheet';
const ACCOUNT_HIERARCHY_STATE_REDIRECT = 'index.customercare.customer.accountHierarchyRedirect';
export const E_WALLET_STATE_REDIRECT = 'index.customercare.customer.eWalletRedirect';
export const CASES_LIST_STATE_REDIRECT = 'index.customercare.customer.casesRedirect';
const CASES_DETAILS_STATE_REDIRECT =
    'index.customercare.customer.cases.detailsRedirect';

const INVENTORY_LIST_STATE_REDIRECT = 'index.customercare.customer.inventory';
const INVENTORY_DETAILS_STATE_REDIRECT = 'index.customercare.customer.inventoryDetails';

const INTERACTIONS_LIST_STATE_REDIRECT =
    'index.customercare.customer.interactionsRedirect';
const INTERACTIONS_DETAILS_STATE_REDIRECT =
    'index.customercare.customer.interactions.detailRedirect';

const NOTIFICATIONS_LIST_STATE_REDIRECT = 'index.customercare.customer.notificationsRedirect';

export const PRICING_PLAN_EARLY_RENEWAL_REDIRECT = 'index.customercare.customer.pricingPlanEarlyRenewal';
export const DBSS_PRODUCT_DETAILS_PAGE_REDIRECT = 'index.customercare.customer.dbss-products.redirect';
export const SUSPEND_SUBSCRIBER_PRODUCTS_REDIRECT = 'index.customercare.customer.suspendSubscriberProducts.redirect';
export const RESUME_SUBSCRIBER_PRODUCTS_REDIRECT = 'index.customercare.customer.restoreSubscriberProducts.redirect';
export const SUSPEND_SERVICES_REDIRECT = 'index.customercare.customer.suspendServices.redirect';
export const RESUME_SERVICES_REDIRECT = 'index.customercare.customer.resumeServices.redirect';

export function getReactRoutes({isAddOfferingBetaEnabled,
    isChangeOfferingBetaEnabled,
    isEditOfferingBetaEnabled,
    isNewConnectBetaEnabled,
    isRemoveOfferingBetaEnabled,
    isDisconnectCustomerBetaEnabled}) {
    return {
        [ACCOUNT_HIERARCHY_STATE_REDIRECT]: {
            url: `${CUSTOMER}/account-hierarchy`
        },
        [ADDRESSES_STATE_REDIRECT]: {
            url: `${CUSTOMER}/addresses`
        },
        [CASES_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/cases`
        },
        [CASES_DETAILS_STATE_REDIRECT]: {
            url: `${CUSTOMER}/cases/:caseId`
        },
        [E_WALLET_STATE_REDIRECT]: {
            url: `${CUSTOMER}/e-wallet`
        },
        [INVENTORY_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/inventory`
        },
        [INVENTORY_DETAILS_STATE_REDIRECT]: {
            url: `${CUSTOMER}/inventory/:inventoryId`
        },
        [INTERACTIONS_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/interactions`
        },
        [INTERACTIONS_DETAILS_STATE_REDIRECT]: {
            url: `${CUSTOMER}/interactions/:interactionId`
        },
        ...(isNewConnectBetaEnabled
            ? {
                [NEW_CONNECT_PAGE]: {
                    url: `${HOME}offer-order/new`
                }
            }
            : {}),
        ...(isAddOfferingBetaEnabled
            ? {
                [ADD_OFFERING_PAGE]: {
                    url: `${CUSTOMER}/ordering/add-offering`
                }
            }
            : {}),
        ...(isEditOfferingBetaEnabled
            ? {
                [EDIT_OFFERING_PAGE]: {
                    url: `${CUSTOMER}/ordering/edit-offering/:offeringId/:offeringInstanceId`
                }
            }
            : {}),
        ...(isChangeOfferingBetaEnabled
            ? {
                [CHANGE_OFFERING_PAGE]: {
                    url: `${CUSTOMER}/ordering/change-offering/:offeringId/:offeringInstanceId`
                }
            }
            : {}),
        ...(isRemoveOfferingBetaEnabled
            ? {
                [DISCONNECT_OFFERS_DBSS_ROUTE]: {
                    url: `${CUSTOMER}/ordering/remove-offering/:offeringId/:offeringInstanceId`
                }
            }
            : {}),
        ...(isDisconnectCustomerBetaEnabled
            ? {
                [DISCONNECT_CUSTOMER_DBSS_ROUTE]: {
                    url: `${CUSTOMER}/ordering/disconnect`
                }
            } : {}),
        [NOTIFICATIONS_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/notifications`
        },
        [GROUP_ORDER_HISTORY_LIST_STATE_NAME]: {
            url: `${CUSTOMER}/order-history/group-orders`
        },
        [RATE_SHEET_STATE_REDIRECT]: {
            url: `${CUSTOMER}/rate-sheet`
        },
        [PRICING_PLAN_EARLY_RENEWAL_REDIRECT]: {
            url: `${ORDERING}/plan/early-renewal/:offeringInstanceId/:subscriberProductId?`
        },
        [DBSS_PRODUCT_DETAILS_PAGE_REDIRECT]: {
            url: `${CUSTOMER}/products/dbss-product/:productId`
        },
        [SUSPEND_SUBSCRIBER_PRODUCTS_REDIRECT]: {
            url: `${CUSTOMER}/ordering/suspend-subscriber-products/:offeringId/:offeringInstanceId`
        },
        [RESUME_SUBSCRIBER_PRODUCTS_REDIRECT]: {
            url: `${CUSTOMER}/ordering/restore-subscriber-products/:offeringId/:offeringInstanceId`
        },
        [SUSPEND_SERVICES_REDIRECT]: {
            url: `${CUSTOMER}/ordering/suspend-services/:serviceIdentifier?`
        },
        [RESUME_SERVICES_REDIRECT]: {
            url: `${CUSTOMER}/ordering/restore-services/:serviceIdentifier?`
        }
    };
};