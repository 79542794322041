import Immutable from 'seamless-immutable';
import {CUSTOMER_QUICK_ACTION_IDS} from 'invision-core/src/components/configurable/customercare';
import CustomerCareLocaleKeys from '../../locales/keys';
import {
    ORDER_ACCESS,
    ORDERING_ACCESS,
    RESET_SUBSCRIBER_PASSWORD_ACCESS,
    SUBSCRIBER_ADMIN_ACCESS,
    SUBSCRIBER_BYPASS_RESET_PASSWORD
} from '../../security.attributes';

/*
    Quick Action Data Structure:
        id - Required: This ID should be generated in invision-core to ensure it remains the same across the app
        securityAttributes - Optional: The security attributes are run through the PermissionsService.hasAccess function,
            if any of the attributes are not met, then the action is not added to the collection
        adminAttributes - Optional: The security attributes are run through PermissionsService.hasAdminAccess function
        lockMessage - Optional: If an action is lockable, the key will be translated and set onto the quick action
*/
export const CUSTOMER_WRAP_UP_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.CUSTOMER_WRAP_UP,
    securityAttributes: []
};

export const DISCONNECT_CUSTOMER_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.DISCONNECT_CUSTOMER,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS],
    lockMessage: CustomerCareLocaleKeys.PENDING_OFFERS_CAN_NOT_FULL_DISCONNECT
};

export const NEW_DBSS_ORDER_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.NEW_DBSS_ORDER,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS],
    lockMessage: CustomerCareLocaleKeys.CUSTOMER_DASHBOARD.CONVERGENT_BILLER_ERROR
};

export const NEW_PRODUCT_ORDER_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.NEW_PRODUCT_ORDER,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS],
    lockMessage: CustomerCareLocaleKeys.CUSTOMER_DASHBOARD.CONVERGENT_BILLER_ERROR
};

export const OPEN_CASE_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.OPEN_CASE,
    securityAttributes: []
};

export const REDEEM_GIFT_ORDER_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.REDEEM_GIFT_ORDER,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS]
};

export const RESET_DEVICE_PIN_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.RESET_DEVICE_PIN,
    securityAttributes: [SUBSCRIBER_ADMIN_ACCESS],
    adminAttributes: [SUBSCRIBER_ADMIN_ACCESS]
};

export const RESET_PASSWORD_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.RESET_PASSWORD,
    securityAttributes: [RESET_SUBSCRIBER_PASSWORD_ACCESS]
};

export const RESUME_ORDER_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.RESUME_ORDER,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS]
};

export const CLEAR_SAVED_CART_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.CLEAR_SAVED_CART,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS]
};

export const REVIEW_STORE_ORDER_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.REVIEW_STORE_ORDER,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS]
};

export const CANCEL_STORE_ORDER_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.CANCEL_STORE_ORDER,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS]
};

export const START_NEW_SUBSCRIPTION_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.START_NEW_SUBSCRIPTION,
    securityAttributes: [ORDER_ACCESS],
    adminAttributes: [ORDERING_ACCESS],
    lockMessage: CustomerCareLocaleKeys.CUSTOMER_DASHBOARD.CONVERGENT_BILLER_ERROR
};

export const UNREVOKE_LOGIN_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.UNREVOKE_LOGIN,
    securityAttributes: [SUBSCRIBER_BYPASS_RESET_PASSWORD]
};

export const VALIDATE_PIN_QUICK_ACTION = {
    id: CUSTOMER_QUICK_ACTION_IDS.VALIDATE_PIN
};

export const QUICK_ACTIONS_MAP = Immutable({
    [CANCEL_STORE_ORDER_QUICK_ACTION.id]: CANCEL_STORE_ORDER_QUICK_ACTION,
    [CLEAR_SAVED_CART_QUICK_ACTION.id]: CLEAR_SAVED_CART_QUICK_ACTION,
    [CUSTOMER_WRAP_UP_QUICK_ACTION.id]: CUSTOMER_WRAP_UP_QUICK_ACTION,
    [DISCONNECT_CUSTOMER_QUICK_ACTION.id]: DISCONNECT_CUSTOMER_QUICK_ACTION,
    [NEW_DBSS_ORDER_QUICK_ACTION.id]: NEW_DBSS_ORDER_QUICK_ACTION,
    [NEW_PRODUCT_ORDER_QUICK_ACTION.id]: NEW_PRODUCT_ORDER_QUICK_ACTION,
    [OPEN_CASE_QUICK_ACTION.id]: OPEN_CASE_QUICK_ACTION,
    [REDEEM_GIFT_ORDER_QUICK_ACTION.id]: REDEEM_GIFT_ORDER_QUICK_ACTION,
    [RESET_DEVICE_PIN_QUICK_ACTION.id]: RESET_DEVICE_PIN_QUICK_ACTION,
    [RESET_PASSWORD_QUICK_ACTION.id]: RESET_PASSWORD_QUICK_ACTION,
    [RESUME_ORDER_QUICK_ACTION.id]: RESUME_ORDER_QUICK_ACTION,
    [REVIEW_STORE_ORDER_QUICK_ACTION.id]: REVIEW_STORE_ORDER_QUICK_ACTION,
    [START_NEW_SUBSCRIPTION_ACTION.id]: START_NEW_SUBSCRIPTION_ACTION,
    [UNREVOKE_LOGIN_QUICK_ACTION.id]: UNREVOKE_LOGIN_QUICK_ACTION,
    [VALIDATE_PIN_QUICK_ACTION.id]: VALIDATE_PIN_QUICK_ACTION
});
